import { useEffect, useRef, useState } from 'react';
import { useFullscreen } from 'ahooks';
import { useCurrent } from '@maxtropy/components';
import GateWayPanel from './components/GatewayPanel';
import {
  ButtonListTypeEnum,
  getBasicManage,
  GetBasicManageResType,
  getBsaData,
  GetBsaDataResType,
  getButtonList,
  GetButtonListResType,
  getChargingData,
  GetChargingDataResType,
  getLeanManageData,
  GetLeanManageDataResType,
  getPvData,
  GetPvDataResType,
  PanelDataType,
  PlatformSourceTypeEnum,
  StaffSourceTypeEnum,
  getMajorData,
  GetHighLightDisplayData,
} from '@/api/energyGateway';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { formatNumber } from './initialState';
import initialState from './initialState';
import video from '../../assets/images/energyGateway/energyGateWay.mp4';
import styles from './index.module.scss';
import { apiV2PortalDashboardPortalStaffConfigPost } from '@maxtropy/dmes-apis-v2';

interface initialStateType {
  pvPanelData: PanelDataType;
  chargePanelData: PanelDataType;
  bsaPanelData: PanelDataType;
  leanManagePanelData: PanelDataType;
  basicManagePanelData: PanelDataType;
  importPanelData: PanelDataType;
}

const EnergyGateway = () => {
  const [state, setState] = useState<initialStateType>(initialState);
  const ref = useRef(null);
  const [isFullscreen, { toggleFullscreen }] = useFullscreen(ref);
  const staffId = useCurrent()?.staff?.id.toString();

  const updatePanelData = (panelName: keyof initialStateType, data: Partial<PanelDataType>) => {
    setState(prevState => ({
      ...prevState,
      [panelName]: {
        ...prevState[panelName],
        ...data,
      },
    }));
  };

  const updateImportPanelData = (
    majorData: GetHighLightDisplayData | null,
    buttonList: GetButtonListResType[],
    highlightDisplaySourceType: StaffSourceTypeEnum | null
  ) => {
    if (!majorData) return;

    const { greenEnergyUse, totalCarbonEmissionReduction, unitOutputValueEnergyCost, sourceType } = majorData;

    const formatStationNum = formatNumber(greenEnergyUse);
    const deviceNum = formatNumber(totalCarbonEmissionReduction);
    const totalCapacity = formatNumber(unitOutputValueEnergyCost);

    const stationNum: string = typeof formatStationNum === 'number' ? formatStationNum + '%' : '--';

    const panelData = {
      stationNum,
      deviceNum,
      totalCapacity,
      listData: buttonList.filter(item => item.type === ButtonListTypeEnum.IMPORT_BUTTON),
    };

    const shouldUpdatePanelData =
      highlightDisplaySourceType === StaffSourceTypeEnum.PLATFORM ||
      (highlightDisplaySourceType === null && sourceType !== PlatformSourceTypeEnum.NOT_CONFIG);

    if (shouldUpdatePanelData) {
      updatePanelData('importPanelData', panelData);
    }
  };

  const updatePvPanelData = (
    pvData: GetPvDataResType | null,
    buttonList: GetButtonListResType[],
    pvSourceType: StaffSourceTypeEnum | null
  ) => {
    if (!pvData) return;

    const { num, pvInverterNum, installedCapacity, sourceType } = pvData;

    const stationNum = typeof num === 'number' ? formatNumber(num) : '--';
    const deviceNum = typeof pvInverterNum === 'number' ? formatNumber(pvInverterNum) : '--';
    const totalCapacity = typeof installedCapacity === 'number' ? formatNumber(installedCapacity / 1000, 2) : '--';

    const panelData = {
      stationNum,
      deviceNum,
      totalCapacity,
      listData: buttonList.filter(item => item.type === ButtonListTypeEnum.PV_BUTTON),
    };

    const shouldUpdatePanelData =
      pvSourceType === StaffSourceTypeEnum.PLATFORM ||
      (pvSourceType === null && sourceType !== PlatformSourceTypeEnum.NOT_CONFIG);

    if (shouldUpdatePanelData) {
      updatePanelData('pvPanelData', panelData);
    }
  };

  const updateBsaPanelData = (
    bsaData: GetBsaDataResType | null,
    buttonList: GetButtonListResType[],
    storedEnergySourceType: StaffSourceTypeEnum | null
  ) => {
    if (!bsaData) return;

    const { bsaCellNum, num, ratedPower, installedCapacity, sourceType } = bsaData;
    const formatDeviceNum = formatNumber(bsaCellNum);

    const deviceNum =
      typeof formatDeviceNum === 'number'
        ? formatNumber(
            formatDeviceNum >= 100000000
              ? formatDeviceNum / 1000000
              : formatDeviceNum >= 100000
              ? formatDeviceNum / 10000
              : formatDeviceNum
          )
        : '--';

    const deviceName =
      typeof formatDeviceNum === 'number'
        ? formatDeviceNum >= 100000000
          ? '储能柜(百万)'
          : formatDeviceNum >= 100000
          ? '储能柜(万)'
          : '储能柜'
        : '储能柜';

    const panelData = {
      stationNum: formatNumber(num),
      deviceNum,
      deviceName,
      totalCapacity: `${formatNumber(ratedPower)}/${formatNumber(installedCapacity)}`,
      listData: buttonList.filter(item => item.type === ButtonListTypeEnum.BSA_BUTTON),
    };

    const shouldUpdatePanelData =
      storedEnergySourceType === StaffSourceTypeEnum.PLATFORM ||
      (storedEnergySourceType === null && sourceType !== PlatformSourceTypeEnum.NOT_CONFIG);

    if (shouldUpdatePanelData) {
      updatePanelData('bsaPanelData', panelData);
    }
  };

  const updateChargingPanelData = (
    chargingData: GetChargingDataResType | null,
    buttonList: GetButtonListResType[],
    chargeSourceType: StaffSourceTypeEnum | null
  ) => {
    if (!chargingData) return;

    const { num, chargingPileNum, installedCapacity, sourceType } = chargingData;

    const panelData = {
      stationNum: formatNumber(num),
      deviceNum: formatNumber(chargingPileNum),
      totalCapacity: formatNumber(installedCapacity),
      listData: buttonList.filter(item => item.type === ButtonListTypeEnum.CHARGING_BUTTON),
    };

    const shouldUpdatePanelData =
      chargeSourceType === StaffSourceTypeEnum.PLATFORM ||
      (chargeSourceType === null && sourceType !== PlatformSourceTypeEnum.NOT_CONFIG);

    if (shouldUpdatePanelData) {
      updatePanelData('chargePanelData', panelData);
    }
  };

  const updateLeanManagePanelData = (
    leanManageData: GetLeanManageDataResType | null,
    buttonList: GetButtonListResType[],
    leanSourceType: StaffSourceTypeEnum | null
  ) => {
    if (!leanManageData) return;

    const { dayEnergyConsumption, dayOutput, lineOn, lineTotal, type } = leanManageData;

    const formatStationNum = typeof dayEnergyConsumption === 'number' ? formatNumber(dayEnergyConsumption) : '--';
    const formatDeviceNum = typeof dayOutput === 'number' ? formatNumber(dayOutput) : '--';

    // 计算 stationNum 和 stationNameWrap
    const stationNum =
      typeof formatStationNum === 'number'
        ? formatNumber(formatStationNum >= 1000 ? formatStationNum / 1000 : formatStationNum)
        : '--';
    const stationNameWrap = typeof formatStationNum === 'number' && formatStationNum >= 1000 ? '(tce)' : '(kgce)';

    // 计算 deviceNum 和 deviceName
    const deviceNum =
      typeof formatDeviceNum === 'number'
        ? formatNumber(formatDeviceNum >= 10000 ? formatDeviceNum / 10000 : formatDeviceNum)
        : '--';
    const deviceName = typeof formatDeviceNum === 'number' && formatDeviceNum >= 10000 ? '日产量(万)' : '日产量';

    // 根据条件计算 lineOnStyle
    const lineOnStyle = lineOn && typeof lineOn === 'number' && lineOn > 0 ? { color: '#57fac1' } : {};

    // 构建面板数据对象
    const panelData = {
      stationNum,
      stationNameWrap,
      deviceNum,
      deviceName,
      totalCapacity: (
        <>
          <span style={lineOnStyle}>{formatNumber(lineOn)}</span>
          <span>/</span>
          <span>{formatNumber(lineTotal)}</span>
        </>
      ),
      listData: buttonList.filter(item => item.type === ButtonListTypeEnum.LEAN_MANAGE_BUTTON),
    };

    const shouldUpdatePanelData =
      leanSourceType === StaffSourceTypeEnum.PLATFORM ||
      (leanSourceType === null && type !== PlatformSourceTypeEnum.NOT_CONFIG);

    if (shouldUpdatePanelData) {
      updatePanelData('leanManagePanelData', panelData);
    }
  };

  const updateBasicManagePanelData = (
    basicManageData: GetBasicManageResType | null,
    buttonList: GetButtonListResType[],
    basicTubeSourceType: StaffSourceTypeEnum | null
  ) => {
    if (!basicManageData) return;

    const { circuitNum, waterMonitorNum, gasMonitorNum, type } = basicManageData;

    const stationNum = typeof circuitNum === 'number' ? formatNumber(circuitNum) : '--';
    const deviceNum = typeof waterMonitorNum === 'number' ? formatNumber(waterMonitorNum) : '--';
    const totalCapacity = typeof gasMonitorNum === 'number' ? formatNumber(gasMonitorNum) : '--';

    const panelData = {
      stationNum,
      deviceNum,
      totalCapacity,
      listData: buttonList.filter(item => item.type === ButtonListTypeEnum.BASE_MANAGE_BUTTON),
    };

    const isUpdatePanelData =
      basicTubeSourceType === StaffSourceTypeEnum.PLATFORM ||
      (basicTubeSourceType === null && type !== PlatformSourceTypeEnum.NOT_CONFIG);

    if (isUpdatePanelData) {
      updatePanelData('basicManagePanelData', panelData);
    }
  };

  const fetchData = async () => {
    if (!staffId) return;

    const {
      pvSourceType,
      storedEnergySourceType,
      chargeSourceType,
      leanSourceType,
      basicTubeSourceType,
      highlightDisplaySourceType,
    } = await apiV2PortalDashboardPortalStaffConfigPost({ id: staffId });

    const [
      pvDataResult,
      bsaDataResult,
      chargingDataResult,
      leanManageDataResult,
      basicManageDataResult,
      buttonListResult,
      MajorDataResult,
    ] = await Promise.allSettled([
      getPvData(),
      getBsaData(),
      getChargingData(),
      getLeanManageData(),
      getBasicManage(),
      getButtonList(),
      getMajorData(),
    ]);

    const getStatusValue = (result: any) => (result.status === 'fulfilled' ? result.value : null);

    const pvData: GetPvDataResType = getStatusValue(pvDataResult);
    const bsaData: GetBsaDataResType = getStatusValue(bsaDataResult);
    const chargingData: GetChargingDataResType = getStatusValue(chargingDataResult);
    const leanManageData: GetLeanManageDataResType = getStatusValue(leanManageDataResult);
    const basicManageData: GetBasicManageResType = getStatusValue(basicManageDataResult);
    const majorData: GetHighLightDisplayData = getStatusValue(MajorDataResult);
    const buttonList: GetButtonListResType[] = getStatusValue(buttonListResult) || [];

    updateImportPanelData(majorData, buttonList, highlightDisplaySourceType as StaffSourceTypeEnum);

    updatePvPanelData(pvData, buttonList, pvSourceType as StaffSourceTypeEnum);

    updateBsaPanelData(bsaData, buttonList, storedEnergySourceType as StaffSourceTypeEnum);

    updateChargingPanelData(chargingData, buttonList, chargeSourceType as StaffSourceTypeEnum);

    updateLeanManagePanelData(leanManageData, buttonList, leanSourceType as StaffSourceTypeEnum);

    updateBasicManagePanelData(basicManageData, buttonList, basicTubeSourceType as StaffSourceTypeEnum);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId]);

  const { pvPanelData, chargePanelData, bsaPanelData, leanManagePanelData, basicManagePanelData, importPanelData } =
    state;
  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.eneygyGateWaybox}>
        <video src={video} autoPlay loop muted preload="true" />

        <div className={styles.fullscreen} onClick={toggleFullscreen}>
          {isFullscreen ? (
            <FullscreenExitOutlined className={styles.icon}></FullscreenExitOutlined>
          ) : (
            <FullscreenOutlined className={styles.icon}></FullscreenOutlined>
          )}
          <span className={styles.text}>{isFullscreen ? '退出全屏' : '全屏'}</span>
        </div>

        {[pvPanelData, chargePanelData, bsaPanelData, leanManagePanelData, basicManagePanelData, importPanelData].map(
          panelData => (
            <GateWayPanel key={panelData.stationName} panelData={panelData} />
          )
        )}
      </div>
    </div>
  );
};

export default EnergyGateway;
