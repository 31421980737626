import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { V2EnergyConservationEnergyCostSavingPiePostResponse } from '@maxtropy/dmes-apis-v2';
import { Spin } from 'antd';
import { getPieChartOption } from './charts';

interface ICostEnergySavingPie {
  costSavingData?: V2EnergyConservationEnergyCostSavingPiePostResponse;
  loading?: boolean;
}

const CostEnergySavingPie: FC<ICostEnergySavingPie> = props => {
  const { costSavingData, loading } = props;

  // 饼图图表
  const chartPieOption = useMemo(() => getPieChartOption(costSavingData), [costSavingData]);

  return (
    <div className={styles.chart_box}>
      {loading ? (
        <Spin spinning={true} className={styles.spin_box} />
      ) : (
        <ReactEcharts style={{ height: 240, minWidth: 480 }} option={chartPieOption} notMerge lazyUpdate={false} />
      )}
    </div>
  );
};

export default CostEnergySavingPie;
