import dayjs from 'dayjs';
import { StatisticsPartition, StatisticsPartitionTime } from '@/api/circuit';
import { isNil } from 'lodash-es';
import { DataType } from '@/api/const';
import { ChartData } from '../..';
import { DatePickerType } from '../DateSwitch';
import circuitBtns from '../../config';

const Three = ['#0db6d9', '#ffd61a', '#63bc7f', '#F3E6A7', '#ce90d1', '#FD6A6A'];
const Two = ['#0db6d9', '#63bc7f', '#ce90d1'];

const Hispalette = {
  [DataType.EP]: Two,
  [DataType.PDMD]: Two,
  [DataType.LOAD_RATE]: Two,
  [DataType.COSQ]: Two,
  [DataType.P]: Two,
  [DataType.U]: Two,
  [DataType.LINE_VOLTAGE]: Two,
  [DataType.I]: Two,
  [DataType.T]: Two,
  [DataType.PE]: Three,
  [DataType.F]: Two,
  [DataType.UNBALANCED_V]: Two,
  [DataType.UNBALANCED_A]: Two,
  [DataType.RESIDUAL_A]: Two,
  [DataType.VOLTAGE_H]: Two,
  [DataType.CURRENT_H]: Two,
};

export const getHisChartOption = (
  chartData: {
    dataType: DataType;
    aggrby: StatisticsPartition;
    data: ChartData[];
    btnType: DatePickerType;
    tsStart: number;
    tsEnd: number;
  }[]
) => {
  const Item = circuitBtns.find(item => item.id === chartData[0].dataType);
  const aggrby = chartData[0].aggrby;
  const itemsOption = chartData.map((item, index) => {
    const itemOption = Item?.getChartOption({
      chartData: item.data,
      query: {
        btnType: aggrby === StatisticsPartition.MINUTE_15 ? DatePickerType.ORIGINAL : DatePickerType.DAY,
        dataType: chartData[0].dataType,
        aggrby,
        name: Item?.name!,
        tsStart: item.tsStart,
        tsEnd: item.tsEnd,
      },
      unit: Item?.unit,
    });
    return itemOption;
  });

  const seriesData = itemsOption
    .map(i => i.series)
    .flat()
    .map((item: any, index: number) => {
      const { ...rest } = item;
      const p = Hispalette as any;
      const color = p[Item?.id!][index];
      return {
        ...rest,
        data:
          Item?.id === DataType.P && aggrby === StatisticsPartition.DAY
            ? (rest?.data ?? []).map((c: any[], i: number) => [i, c[1], c[2], c[3], c[0]])
            : (rest?.data ?? []).map((c: any[], i: number) => [i, c[1], c[0]]),
        // yAxisIndex: index,
        color: color,
      };
    })
    .flat();

  const legendData = itemsOption.map(item => {
    return item.legend.data;
  });

  const formatOptions = {
    ...itemsOption[0],
    right: 80,
    xAxis: {
      type: 'category',
    },
    yAxis: { ...itemsOption[0].yAxis },
    legend: {
      ...itemsOption[0].legend,
      data: legendData.flat(),
      right: 50,
      top: 10,
    },
    series: seriesData,
    tooltip: {
      trigger: 'axis',
      formatter(items: FormatterHisItem[]) {
        // const { axisValue } = items[0];
        // let str: string = dayjs(axisValue, 'x').format(StatisticsPartitionTime[aggrby]);
        let str: string = '';
        items.forEach(item => {
          const { seriesName, data, marker, seriesIndex } = item;
          const unit = Item?.unit;
          const value = !isNil(data[1]) ? `${transValueByBtnType(aggrby, data[1])}${unit}` : '--';
          str += `${dayjs(data[2], 'x').format(StatisticsPartitionTime[aggrby])} ${marker}${seriesName}: ${value}<br>`;
        });
        return str;
      },
    },
  };
  return formatOptions;
};

const transValueByBtnType = (type: StatisticsPartition, value?: number | null) => {
  if (value) {
    value = Number(value); // value过来是string类型，导致toFixed方法出错，转成number类型
    switch (type) {
      case StatisticsPartition.MINUTE_1:
        return value.toFixed(3);
      case StatisticsPartition.MINUTE_15:
      case StatisticsPartition.MINUTE_30:
      case StatisticsPartition.DAY:
      case StatisticsPartition.MONTH:
        return value.toFixed(2);
      default:
        return value;
    }
  }
  return value;
};

export type FormatterHisItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null, number];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
};
