import React, { FC } from 'react';
import styles from './index.module.scss';
import { V2EnergyConservationEnergyCostSavingPiePostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoOrLine, keepTwoOrLinePercent } from '../../utils';

interface ICostEnergySavings {
  costSavingData?: V2EnergyConservationEnergyCostSavingPiePostResponse;
}

const CostEnergySavings: FC<ICostEnergySavings> = props => {
  const { costSavingData } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.left_box}>
        <div className={styles.left}>
          <div className={styles.label}>节能总成本(元)</div>
          <div className={styles.value} style={{ color: '#16DD8E' }}>
            {keepTwoOrLine(costSavingData?.energySavingTotalCost)}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.label}>环比</div>
          <div className={styles.value} style={{ color: '#E64242' }}>
            {keepTwoOrLinePercent(costSavingData?.rate)}
          </div>
        </div>
      </div>
      <div className={styles.right_box}>
        <div className={styles.left}>
          <div className={styles.label}>甲方节能成本(元)</div>
          <div className={styles.value} style={{ color: '#16DD8E' }}>
            {keepTwoOrLine(costSavingData?.benefitA)}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.label}>乙方节能成本(元)</div>
          <div className={styles.value} style={{ color: '#16DD8E' }}>
            {keepTwoOrLinePercent(costSavingData?.benefitB)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostEnergySavings;
