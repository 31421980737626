import { EnergyAssetAlarmInfoRes, getEnergyAssetAlarmInfo, OuList } from '@/api/energyAssetOverview';
import { Button } from '@maxtropy/components';
import { Col, Row, Spin } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import HeadTitle from '../HeadTitle/HeadTitle';
import styles from './index.module.scss';

export interface INotRecoveredAlarm {
  ouInfo: OuList;
  height: string | undefined;
}

const NotRecoveredAlarm: FC<INotRecoveredAlarm> = props => {
  const { ouInfo, height } = props;

  const [energyAssetAlarmInfo, setEnergyAssetAlarmInfo] = useState<EnergyAssetAlarmInfoRes>();
  const [dataLoading, setDataLoading] = useState(false);
  const urlRef = useRef<string>();

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 累计收益信息
      getEnergyAssetAlarmInfo(ouInfo.id).then(res => {
        setEnergyAssetAlarmInfo(res);
        let str = '?';
        res.assetCodes.forEach((i, index, arr) => {
          str += `assetCode=${i}&`;
          if (index + 1 === arr.length) {
            str += `assetCode=${i}`;
          }
        });
        urlRef.current = str;
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeadTitle title="未恢复报警信息" />
      <Spin spinning={dataLoading}>
        <div style={{ marginTop: height === '30vh' ? '12px' : '12px' }}>
          <Row gutter={8}>
            <Col span={8}>
              <div className={styles.Alarm} style={{ borderTop: '3px solid #FF4D4F' }}>
                <div className={styles.AlarmTitle}>最高级</div>
                <div className={styles.AlarmValue} style={{ color: '#FF4D4F' }}>
                  {energyAssetAlarmInfo?.highestCount ?? '--'}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.Alarm} style={{ borderTop: '3px solid #D89614' }}>
                <div className={styles.AlarmTitle}>高级</div>
                <div className={styles.AlarmValue} style={{ color: '#D89614' }}>
                  {energyAssetAlarmInfo?.advancedCount ?? '--'}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.Alarm} style={{ borderTop: '3px solid #177DDC' }}>
                <div className={styles.AlarmTitle}>中级</div>
                <div className={styles.AlarmValue} style={{ color: '#177DDC' }}>
                  {energyAssetAlarmInfo?.intermediateCount ?? '--'}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: height === '30vh' ? '8px' : '8px' }}>
          <Row gutter={8}>
            <Col span={8}>
              <div className={styles.Alarm} style={{ borderTop: '3px solid #52C41A' }}>
                <div className={styles.AlarmTitle}>低级</div>
                <div className={styles.AlarmValue} style={{ color: '#52C41A' }}>
                  {energyAssetAlarmInfo?.lowCount ?? '--'}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.Alarm} style={{ borderTop: '3px solid #ABD335' }}>
                <div className={styles.AlarmTitle}>最低级</div>
                <div className={styles.AlarmValue} style={{ color: 'ABD335' }}>
                  {energyAssetAlarmInfo?.lowestCount ?? '--'}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.Alarm} style={{ backgroundColor: 'transparent' }}>
                <Button
                  style={{ padding: 0, fontSize: 12 }}
                  type="link"
                  onClick={() => window.open(`/assets/alarm/overview${urlRef.current}`, '_blank')}
                >
                  查看报警详情{'>'}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default NotRecoveredAlarm;
