import React, { useState } from 'react';
import { Space, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';
import ImportErrorModal from './ImportErrorModal';
import { downloadModuleTemplate, ModuleImportError, uploadModuleExcel } from '@/api/pv-string';
import { Button, Modal } from '@maxtropy/components';
import { useRequest } from 'ahooks';

export interface ImportModalProps {
  pvId: number;
  onCancel: () => void;
  onOk: () => void;
}

const ImportModal: React.FC<ImportModalProps> = ({ onCancel, pvId, onOk }) => {
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errors, setErrors] = useState<ModuleImportError[]>([]);
  const { runAsync: upload, loading: isLoading } = useRequest((file: File) => uploadModuleExcel(pvId, file), {
    manual: true,
    onSuccess: res => {
      if (res.length) {
        setFileList([]);
        setErrors(res);
        setErrorModalVisible(true);
        return;
      }
      onOk();
      Modal.success({ title: '导入完成' });
    },
  });

  return (
    <>
      {errorModalVisible && <ImportErrorModal onCancel={() => setErrorModalVisible(false)} errors={errors} />}
      <Modal
        open
        title={`导入`}
        maskClosable={false}
        onCancel={onCancel}
        footer={
          <Space>
            <Button onClick={onCancel}>取消</Button>
            <Button
              loading={isLoading}
              type="primary"
              onClick={() => {
                if (!fileList.length) return;
                upload(fileList[0]);
              }}
            >
              确定
            </Button>
          </Space>
        }
      >
        <div style={{ padding: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Upload
              accept={'.xlsx'}
              name="uploadFile"
              maxCount={1}
              onRemove={() => {
                setFileList([]);
                return true;
              }}
              fileList={fileList}
              beforeUpload={file => {
                setFileList([file]);
                return false;
              }}
            >
              <Button type={'primary'} icon={<UploadOutlined />}>
                上传文件
              </Button>
            </Upload>
            <Button type={'link'} onClick={() => downloadModuleTemplate()}>
              下载模板
            </Button>
          </div>
          <div style={{ marginTop: 20, fontSize: 12, marginBottom: 80 }}>
            <span style={{ color: 'red' }}>*</span>仅支持xlxs格式文件
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImportModal;
