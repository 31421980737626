import { Button, EllipsisSpan, Modal, Paging, Table, Tag, usePaging } from '@maxtropy/components';
import {
  apiV2BsaRemoteControlCommandOperationPagePost,
  V2BsaRemoteControlCommandOperationPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Space } from 'antd';
import Search from 'antd/es/input/Search';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { formateOperationTypeColor, formateOperationTypeDisplay, isJSON, OperationType } from './const';
import styles from './index.module.scss';
interface Iprops {
  bsaId?: string;
}
const viewTableTitle = [
  {
    title: '字段',
    dataIndex: 'op',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原始数据',
    dataIndex: 'before',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '编辑后数据',
    dataIndex: 'after',
    render: (v: string, record: any) => (
      <EllipsisSpan
        value={<span className={v === record.before ? styles.normal : styles.highlight}>{v ?? '--'}</span>}
      />
    ),
  },
];

const columsTemplate = [
  {
    title: '指令名称',
    dataIndex: 'commandName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '按钮名称',
    dataIndex: 'buttonName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '应用设备',
    dataIndex: 'deviceName',
    render: (v: number) => <EllipsisSpan value={v} />,
  },

  {
    title: '目标数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标值',
    dataIndex: 'targetValue',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const tableColumns = [
  {
    title: '编辑时间',
    dataIndex: 'updateTime',
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '编辑人',
    dataIndex: 'editUser',
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作类型',
    dataIndex: 'operationType',
    ellipsis: { showTitle: true },
    render: (v: OperationType) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={formateOperationTypeColor[v]}>
            {formateOperationTypeDisplay[v]}
          </Tag>
        }
      />
    ),
  },
  {
    title: '应用设备',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '具体操作',
    dataIndex: 'operationType',
    ellipsis: { showTitle: true },
    render: (v: OperationType, record: any) => (
      <EllipsisSpan value={`${formateOperationTypeDisplay[v]}指令${record.commandName}`} />
    ),
  },
];
type RowInfo = Exclude<V2BsaRemoteControlCommandOperationPagePostResponse['list'], undefined>[number];
const OpTableList = ({ bsaId }: Iprops) => {
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [currentRow, setCurrentRow] = useState<RowInfo>();
  const [visible, setVisible] = useState<boolean>(false);

  const [deviceCodeObj, setDeviceCode] = useState<{ deviceCode?: string }>();
  const { data: list, loading: tableLoading } = useRequest(
    () =>
      apiV2BsaRemoteControlCommandOperationPagePost({
        page: pageOffset,
        size: pageSize,
        bsaId,
        deviceCode: deviceCodeObj?.deviceCode,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      }),
    {
      refreshDeps: [pageOffset, pageSize, bsaId, deviceCodeObj],
      ready: !!bsaId,
    }
  );

  const viewDataSource = useMemo(() => {
    if (!currentRow) return [];

    let { beforeOperationRecord, afterOperationRecord } = currentRow;
    let beforeDataOld = isJSON(beforeOperationRecord) ? JSON.parse(beforeOperationRecord!) : {};
    let afterDataOld = isJSON(afterOperationRecord) ? JSON.parse(afterOperationRecord!) : {};
    let beforeData = {
      ...beforeDataOld,
      ...(isJSON(beforeDataOld.parameter) ? JSON.parse(beforeDataOld.parameter) : {}),
    };
    let afterData = { ...afterDataOld, ...(isJSON(afterDataOld.parameter) ? JSON.parse(afterDataOld.parameter) : {}) };

    return columsTemplate.map(item => ({
      op: item.title,
      before: beforeData[item.dataIndex],
      after: afterData[item.dataIndex],
    }));
  }, [currentRow]);

  const mergeColunms = [
    {
      title: '序号',
      dataIndex: 'commandName',
      width: 60,
      ellipsis: { showTitle: true },
      render: (v: string, record: any, index: number) => (
        <EllipsisSpan value={index + 1 + (pageOffset - 1) * pageSize} />
      ),
    },
    ...tableColumns,
    {
      title: '操作内容',
      width: 110,
      fixed: 'right' as const,
      render: (v: string, record: RowInfo) => (
        <Space size={12}>
          <Button
            type="link"
            style={{ padding: '4px 0' }}
            onClick={() => {
              setCurrentRow(record);
              setVisible(true);
            }}
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];

  const searchBtn = (value: string) => {
    setDeviceCode({ deviceCode: value?.trim() });
    setPageOffset(1);
  };

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Space>
          <Search onSearch={searchBtn} style={{ width: 320 }} placeholder="请输入设备编号" />
        </Space>
      </div>
      <Table rowKey="updateTime" columns={[...mergeColunms]} loading={tableLoading} dataSource={list} />
      <Paging pagingInfo={pagingInfo} />
      {visible && (
        <Modal maskClosable={false} open title="详情" onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
          <Table rowKey="op" columns={viewTableTitle} dataSource={viewDataSource} />
        </Modal>
      )}
    </>
  );
};
export default OpTableList;
