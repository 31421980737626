import { Modal, Paging, usePaging } from '@maxtropy/components';
import {
  apiV2PvStationLogIncomeCalculateWayPost,
  apiV2PvStationLogOnlineCalculateWayPost,
  V2PvStationLogIncomeCalculateWayPostResponse,
  V2PvStationLogOnlineCalculateWayPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { List } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { CalculateWay, CalculateWayDisplay } from './ModeModal';
import { SourceType } from '.';
import { OnlineCalculateWay, OnlineCalculateWayDisplay } from './OnlineModal';

// 定义两种不同的日志类型
type IncomeLog = Exclude<V2PvStationLogIncomeCalculateWayPostResponse['list'], undefined>[number];

type OnlineLog = Exclude<V2PvStationLogOnlineCalculateWayPostResponse['list'], undefined>[number];

const LogModal = ({
  onCancel,
  onConfirm,
  pvStationId,
  type,
}: {
  onCancel?: () => void;
  onConfirm?: () => void;
  pvStationId?: number;
  type: SourceType;
}) => {
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const { data: list, loading } = useRequest<Array<IncomeLog | OnlineLog>, any>(
    async () => {
      const apiMethod =
        type === SourceType.INCOME ? apiV2PvStationLogIncomeCalculateWayPost : apiV2PvStationLogOnlineCalculateWayPost;

      const res = await apiMethod({
        pvStationId,
        page: pageOffset,
        pageSize,
      });

      setTotalCount(res.total ?? 0);
      return (res.list ?? []) as Array<IncomeLog | OnlineLog>; // 确保返回数组
    },
    {
      ready: !!pvStationId,
      refreshDeps: [pageOffset, pageSize, pvStationId, type],
    }
  );

  return (
    <Modal
      bodyScroll
      open
      size="big"
      title="并网点电量来源修改记录"
      onOk={() => onConfirm?.()}
      onCancel={() => onCancel?.()}
    >
      <div className={styles.log_box}>
        <List
          loading={loading}
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <span>【{item.staffName}】</span>
              在&nbsp;&nbsp;
              <span>{dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
              &nbsp;&nbsp;修改模式为：
              <span>
                {type === SourceType.INCOME
                  ? CalculateWayDisplay[(item as IncomeLog).incomeCalculateWay as CalculateWay]
                  : OnlineCalculateWayDisplay[(item as OnlineLog).onlineCalculateWay as OnlineCalculateWay]}
              </span>
            </List.Item>
          )}
        />
      </div>
      <div className={styles.pagination_box}>
        <Paging pagingInfo={pagingInfo} />
      </div>
    </Modal>
  );
};

export default LogModal;
