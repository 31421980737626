import AuthorizedPermission from '@/components/AuthorizedPermission';
import { CreateRoutersProps, DOMRouterOpts, RoutePathItem, addPermissionByLoop, getBase } from '@maxtropy/components';
import fullRoutes from './fullscreen';
import mainRoutes from './main';
import { createBrowserRouter, Outlet, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import ErrorBoundary from '@maxtropy/web-monitor/es/Boundary';

const RootWrapper = () => {
  const location = useLocation();

  // 在微前端环境下，路由跳转时触发popstate事件，使主应用监听到路由变化
  useEffect(() => {
    if (!window.__MICRO_APP_ENVIRONMENT__) return;
    window.rawWindow.dispatchEvent(new PopStateEvent('popstate', { state: null }));
  }, [location]);

  return <Outlet />;
};

const routes: RoutePathItem[] = [
  {
    errorElement: <ErrorBoundary />,
    element: <RootWrapper />,
    children: [...mainRoutes, ...fullRoutes],
  },
];

export const createRouters = (parameters: CreateRoutersProps, opt?: DOMRouterOpts) => {
  const { routes, render } = parameters;
  const pagesRoutes = addPermissionByLoop({ routes, render });
  return createBrowserRouter(pagesRoutes, opt);
};

const routers = createRouters(
  {
    routes,
    render: (pers, ele) => {
      return <AuthorizedPermission permissionKey={pers}>{ele}</AuthorizedPermission>;
    },
  },
  { basename: getBase() }
);

export default routers;
