import { CurrentMonIncomeInfoRes, getEnergyAssetCurrentMonIncomeInfo, OuList } from '@/api/energyAssetOverview';
import { Col, Row, Spin } from 'antd';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { StationHasCount } from '../..';
import { formateValuesToWanYuan } from '../../utils';
import HeadTitle from '../HeadTitle/HeadTitle';
import ValueLineTitle from '../ValueLineTitle';
import styles from './index.module.scss';

export interface ICurrentMonthIncome {
  ouInfo: OuList;
  height: string | undefined;
}

const CurrentMonthIncome: FC<ICurrentMonthIncome> = props => {
  const { ouInfo, height } = props;
  const stationHasCount = useContext(StationHasCount);
  const [currentMonIncomeInfo, setEnergyAssetCurrentMonIncomeInfo] = useState<CurrentMonIncomeInfoRes>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 查询本月收益情况
      getEnergyAssetCurrentMonIncomeInfo(ouInfo.id).then(res => {
        setEnergyAssetCurrentMonIncomeInfo(res);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  const dataCount = useMemo(() => {
    return [stationHasCount?.bsaCount, stationHasCount?.evCount, stationHasCount?.pvCount].filter(Boolean).length;
  }, [stationHasCount]);
  const colSpan = useMemo(() => {
    if (dataCount === 3) return 12;
    if (dataCount === 1) return 24;
    if (dataCount === 2) return 12;
  }, [dataCount]);

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeadTitle title="本月收益情况" />
      <Spin spinning={dataLoading}>
        <Row>
          <Col span={colSpan}>
            <ValueLineTitle
              value={formateValuesToWanYuan(currentMonIncomeInfo?.totalIncomeThisMonth)}
              title={'本月总收益(万元)'}
            />
          </Col>
          {stationHasCount?.bsaCount && (
            <Col span={colSpan}>
              <ValueLineTitle
                value={
                  stationHasCount?.bsaCount
                    ? formateValuesToWanYuan(currentMonIncomeInfo?.bsaTotalIncomeThisMonth)
                    : '无储能站'
                }
                title={'本月储能收益(万元)'}
              />
            </Col>
          )}
          {stationHasCount?.pvCount && (
            <Col span={colSpan}>
              <ValueLineTitle
                value={
                  stationHasCount?.pvCount
                    ? formateValuesToWanYuan(currentMonIncomeInfo?.pvTotalIncomeThisMonth)
                    : '无光伏站'
                }
                title={'本月光伏收益(万元)'}
              />
            </Col>
          )}
          {stationHasCount?.evCount && (
            <Col span={dataCount <= 2 ? 24 : 12}>
              <ValueLineTitle
                value={
                  stationHasCount?.evCount
                    ? formateValuesToWanYuan(currentMonIncomeInfo?.evTotalIncomeThisMonth)
                    : '无充电站'
                }
                title={'本月充电站收益(万元)'}
              />
            </Col>
          )}
        </Row>
      </Spin>
    </div>
  );
};

export default CurrentMonthIncome;
