import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Modal, Radio } from '@maxtropy/components';
import { apiV2PvStationUpsetOnlineCalculateWayPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Col, Row, Space } from 'antd';
import { useEffect } from 'react';
import styles from './index.module.scss';

export enum OnlineCalculateWay {
  ORIGIN = 1,
  TRANSFORM = 2,
}
export const OnlineCalculateWayDisplay = {
  [OnlineCalculateWay.ORIGIN]: '反向尖峰平谷数据',
  [OnlineCalculateWay.TRANSFORM]: '反向有功电能',
};

interface Iprops {
  onCancel?: () => void;
  onConfirm?: () => void;
  mode?: OnlineCalculateWay;
  pvStationId?: number;
}
const OnlineModal = ({ onCancel, onConfirm, mode, pvStationId }: Iprops) => {
  const [form] = Form.useForm();
  const onlineCalculateWay = Form.useWatch('onlineCalculateWay', form);
  const { runAsync, loading } = useRequest(params => apiV2PvStationUpsetOnlineCalculateWayPost(params), {
    manual: true,
  });
  const confirmBtn = () => {
    if (!pvStationId) return;
    runAsync({
      pvStationId,
      onlineCalculateWay,
    }).then(res => {
      onConfirm?.();
    });
  };

  useEffect(() => {
    if (mode) {
      form.setFieldsValue({
        onlineCalculateWay: mode,
      });
    }
  }, [mode]);
  return (
    <Modal
      open
      title="上网分时电量来源设置"
      contentClassName="modal-form-content"
      onOk={() => confirmBtn()}
      onCancel={() => onCancel?.()}
    >
      <Form
        form={form}
        initialValues={{
          onlineCalculateWay: OnlineCalculateWay.ORIGIN,
        }}
        isOrigin
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="onlineCalculateWay"
              extra={
                <div style={{ marginTop: 8 }}>
                  <Space align="start">
                    <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                    <span>
                      模式切换后未来收益将按新模式进行计算，历史收益将不做处理，请确认光伏站实际情况后谨慎操作！
                    </span>
                  </Space>
                </div>
              }
            >
              <Radio.Group>
                <Space direction="vertical" size={0}>
                  <Radio value={OnlineCalculateWay.ORIGIN}>反向尖峰平谷数据</Radio>
                  <div className={styles.modalTip}>选择后将采用原始反向尖峰平谷数据计算并网点电量和收益</div>
                  <Radio value={OnlineCalculateWay.TRANSFORM}>反向有功电能</Radio>
                  <div className={styles.modalTip}>
                    选择后将对原始反向有功电能进行分时电能转换，计算并网点电量和收益
                  </div>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default OnlineModal;
