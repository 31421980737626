import React, { useMemo, useState, useContext } from 'react';
import { App, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { useQuery } from 'react-query';
import { getBsa, getBsaBindingPcsList, isPack, isRack, Pack, Rack, Stack } from '@/api/bsa';
import {
  DeviceData,
  getDeviceDataInstantaneous,
  getDeviceDataInstantaneousFor1m,
  getDeviceDataInstantaneousForRaw,
} from '@/api/device';
import { isNil } from 'lodash-es';
import { useYaxisMarkArea } from './useYaxisMarkArea';
import { StorageContext } from '../StorageContext';
import { Checkbox, DatePicker, Empty, Radio, Select } from '@maxtropy/components';

const { RangePicker } = DatePicker;

export interface Iprops {
  bsaId?: number;
  stack_h_property_id: number;
  stack_l_property_id: number;
  rack_h_property_id: number;
  rack_l_property_id: number;
  pack_h_property_id: number;
  pack_l_property_id: number;
  unit: string;
  type: string;
}

enum TimeChoice {
  TODAY,
  LAST_24_HOURS,
  CUSTOM,
}

enum LevelChoice {
  STACK,
  RACK,
  PACK,
}

const MaxselectNums = 10;
const POWER_PROPERTY_ID = 10061;
const ALL_OPTION_VALUE = -1;
const TipsMsg = `最多选择${MaxselectNums}个`;
const SelectMsg = `全选（前${MaxselectNums}个）`;

// 颗粒度枚举
export enum GrainChoice {
  ORIGINAL = 'original',
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
}

export const timeGapMap = {
  [GrainChoice.ORIGINAL]: 1,
  [GrainChoice.MINUTE_1]: 3,
  [GrainChoice.MINUTE_15]: 30,
};

const BatteryTemper: React.FC<Iprops> = props => {
  const StorageContextValue = useContext(StorageContext);
  const { message } = App.useApp();
  // const { id } = useParams<{ id: string }>();
  // const bsaId = +id;
  const {
    bsaId,
    stack_h_property_id,
    stack_l_property_id,
    rack_h_property_id,
    rack_l_property_id,
    pack_h_property_id,
    pack_l_property_id,
    unit,
    type,
  } = props;
  const highPropertyIds = [stack_h_property_id, rack_h_property_id, pack_h_property_id];

  const disabledDate = (current: Dayjs) => {
    return current && current.valueOf() >= dayjs().endOf('day').valueOf();
  };

  const [from, to] = useMemo(() => {
    if (StorageContextValue?.timeChoice === TimeChoice.TODAY) {
      return [dayjs().startOf('day').valueOf(), dayjs().endOf('day').valueOf()];
    } else if (StorageContextValue?.timeChoice === TimeChoice.LAST_24_HOURS) {
      return [dayjs().subtract(24, 'hours').valueOf(), dayjs().valueOf()];
    } else if (StorageContextValue?.customDateRange) {
      return [
        StorageContextValue?.customDateRange[0].startOf('day').valueOf(),
        StorageContextValue?.customDateRange[1].endOf('day').valueOf(),
      ];
    }
    return [undefined, undefined];
  }, [StorageContextValue?.customDateRange, StorageContextValue?.timeChoice]);

  const { data: pcsList } = useQuery(['pcsList', bsaId], () => getBsaBindingPcsList(bsaId!), {
    enabled: !!bsaId,
  });

  const stacks = StorageContextValue?.stacks;
  const racks = StorageContextValue?.racks;
  const { data: bsaDetail } = useQuery(['bsaDetail', bsaId], () => getBsa(bsaId!), {
    enabled: !!bsaId,
  });
  // y轴区域
  const markAreaData = useYaxisMarkArea(type, bsaDetail);
  const [selectedPacks] = useState<number[]>([]);

  const pcsComparable =
    (StorageContextValue?.levelChoice === LevelChoice.STACK && StorageContextValue?.selectedStacks.length === 1) ||
    (StorageContextValue?.levelChoice === LevelChoice.RACK && StorageContextValue?.selectedRacks.length === 1) ||
    (StorageContextValue?.levelChoice === LevelChoice.PACK && selectedPacks.length === 1);

  // 设备ids
  const devicesToQueryIds = useMemo(() => {
    if (stacks && racks) {
      if (StorageContextValue?.levelChoice === LevelChoice.STACK) {
        return StorageContextValue?.selectedStacks.map(id => stacks.find(item => id === item.stackId)!);
      }
      return StorageContextValue?.selectedRacks.map(id => racks.find(item => id === item.rackId)!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StorageContextValue?.selectedStacks, StorageContextValue?.selectedRacks, stacks, racks]);

  const deviceIds = useMemo(() => {
    if (devicesToQueryIds && devicesToQueryIds.length !== 0) {
      if ((devicesToQueryIds as any).includes(undefined)) {
        return [];
      }
      return devicesToQueryIds?.map(item => item?.deviceId);
    } else {
      return [];
    }
  }, [devicesToQueryIds]);

  // 数据属性
  const propertyIds =
    StorageContextValue?.levelChoice === LevelChoice.STACK
      ? [stack_h_property_id, stack_l_property_id]
      : StorageContextValue?.levelChoice === LevelChoice.RACK
      ? [rack_h_property_id, rack_l_property_id]
      : [pack_h_property_id, pack_l_property_id];

  // 下拉选项
  const options =
    StorageContextValue?.levelChoice === LevelChoice.STACK
      ? stacks?.map(item => ({
          value: item.stackId,
          label: `电池堆${item.stackSequence + 1}`,
        }))
      : racks?.map(item => ({
          value: item.rackId,
          label:
            (stacks?.length && stacks.length > 1 ? `电池堆${item.stackSequence + 1}` : '') +
            `电池簇${item.rackSequence + 1}`,
        }));
  const selectOptions =
    !options || options.length === 0 ? [] : [{ value: ALL_OPTION_VALUE, label: SelectMsg }, ...options];

  const requestApi = useMemo(() => {
    return StorageContextValue?.grainChoice === GrainChoice.ORIGINAL
      ? getDeviceDataInstantaneousForRaw
      : StorageContextValue?.grainChoice === GrainChoice.MINUTE_1
      ? getDeviceDataInstantaneousFor1m
      : getDeviceDataInstantaneous;
  }, [StorageContextValue?.grainChoice]);

  const { data: chartData, isLoading: isDataLoading } = useQuery(
    ['chartData', deviceIds, from, to, StorageContextValue?.levelChoice, bsaId, StorageContextValue?.grainChoice, type],
    () =>
      requestApi({
        deviceIds: deviceIds!,
        propertyIds: propertyIds,
        startTime: from!,
        endTime: to!,
      }),
    {
      enabled: !!bsaId && !!from && !!to && !!deviceIds.length,
    }
  );

  const pcs = pcsList?.find(item => item.id === devicesToQueryIds?.[0]?.pcsId);
  const pcsDeviceId = pcs?.deviceId;
  const { data: pcsPowerData, isLoading: isPowerLoading } = useQuery(
    [
      'power',
      pcsDeviceId,
      from,
      to,
      StorageContextValue?.comparePcs,
      pcsComparable,
      StorageContextValue?.levelChoice,
      StorageContextValue?.grainChoice,
    ],
    () =>
      requestApi({
        deviceIds: [pcsDeviceId!],
        propertyIds: [POWER_PROPERTY_ID],
        startTime: from!,
        endTime: to!,
      }),
    {
      enabled: !!bsaId && pcsComparable && StorageContextValue?.comparePcs && !!from && !!to && !!pcsDeviceId,
    }
  );

  const isDataEmpty = useMemo(() => {
    return (
      !chartData?.some(item => item.values.some(v => !isNil(v.value))) &&
      !pcsPowerData?.some(item => item.values.some(v => !isNil(v.value)))
    );
  }, [pcsPowerData, chartData]);

  return (
    <div>
      <Space wrap size={20}>
        <Space size={20}>
          <Radio.Group
            buttonStyle={'solid'}
            value={StorageContextValue?.timeChoice}
            onChange={e => {
              StorageContextValue?.setTimeChoice(e.target.value);
            }}
          >
            <Radio.Button value={TimeChoice.TODAY}>当日</Radio.Button>
            <Radio.Button value={TimeChoice.LAST_24_HOURS}>最近24小时</Radio.Button>
            <Radio.Button value={TimeChoice.CUSTOM}>其他</Radio.Button>
          </Radio.Group>
          <Space>
            颗粒度:
            <Radio.Group
              buttonStyle={'solid'}
              value={StorageContextValue?.grainChoice}
              onChange={e => {
                StorageContextValue?.setGrainChoice(e.target.value);
              }}
            >
              <Radio.Button value={GrainChoice.ORIGINAL}>原始数据</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_1}>1分钟</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_15}>15分钟</Radio.Button>
            </Radio.Group>
          </Space>
          {StorageContextValue?.timeChoice === TimeChoice.CUSTOM && (
            <Space>
              选择日期:
              <RangePicker
                allowClear={false}
                value={StorageContextValue?.customDateRange}
                disabledDate={disabledDate}
                onChange={e => {
                  const value = e as [dayjs.Dayjs, dayjs.Dayjs];
                  const timeGap = value[1].endOf('day').diff(value[0].startOf('day'), 'days', true);
                  if (timeGap > timeGapMap[StorageContextValue?.grainChoice]) {
                    return message.error(`最大跨度为${timeGapMap[StorageContextValue?.grainChoice]}天`);
                  }
                  StorageContextValue?.setCustomDateRange(value);
                }}
              />
            </Space>
          )}
        </Space>
        <Space size={20}>
          <Radio.Group
            buttonStyle={'solid'}
            value={StorageContextValue?.levelChoice}
            onChange={e => {
              StorageContextValue?.setComparePcs(false);
              StorageContextValue?.setLevelChoice(e.target.value);
            }}
          >
            <Radio.Button value={LevelChoice.STACK}>电池堆</Radio.Button>
            <Radio.Button value={LevelChoice.RACK}>电池簇</Radio.Button>
            {/* <Radio.Button value={LevelChoice.PACK}>电池组曲线</Radio.Button> */}
          </Radio.Group>
          {StorageContextValue?.levelChoice === LevelChoice.STACK && (
            <Space>
              选择电池堆:
              <Select
                mode={'multiple'}
                allowClear
                placeholder={'请选择'}
                style={{ width: 300 }}
                maxTagCount={2}
                value={StorageContextValue?.selectedStacks}
                options={selectOptions}
                onChange={values => {
                  if (values.includes(ALL_OPTION_VALUE)) {
                    StorageContextValue?.setSelectedStacks(stacks!.map(item => item.stackId).slice(0, MaxselectNums));
                  } else {
                    if (values.length > MaxselectNums) {
                      return message.error(TipsMsg);
                    }
                    StorageContextValue?.setSelectedStacks(values);
                  }
                }}
              />
            </Space>
          )}
          {StorageContextValue?.levelChoice === LevelChoice.RACK && (
            <Space>
              选择电池簇:
              <Select
                mode={'multiple'}
                allowClear
                placeholder={'请选择'}
                style={{ width: 300 }}
                maxTagCount={2}
                options={selectOptions}
                value={StorageContextValue?.selectedRacks}
                onChange={values => {
                  if (values.includes(ALL_OPTION_VALUE)) {
                    StorageContextValue?.setSelectedRacks(racks!.map(item => item.rackId).slice(0, MaxselectNums));
                  } else {
                    if (values.length > MaxselectNums) {
                      return message.error(TipsMsg);
                    }
                    StorageContextValue?.setSelectedRacks(values);
                  }
                }}
              />
            </Space>
          )}
          <Checkbox
            disabled={!pcsComparable}
            checked={StorageContextValue?.comparePcs}
            onChange={e => {
              StorageContextValue?.setComparePcs(e.target.checked);
            }}
          >
            与PCS功率对比
          </Checkbox>
        </Space>
      </Space>

      <Spin spinning={isPowerLoading || isDataLoading}>
        <div style={{ marginTop: 30, height: 'calc(100vh - 303px)' }}>
          {isDataEmpty && !isPowerLoading && !isDataLoading ? (
            <Empty style={{ paddingTop: 120 }} />
          ) : (
            <ReactEcharts
              option={getOption(
                highPropertyIds,
                unit,
                markAreaData,
                from!,
                to!,
                !!(stacks?.length && stacks.length > 1),
                chartData,
                devicesToQueryIds,
                pcsPowerData,
                StorageContextValue?.grainChoice
              )}
              theme={'dark'}
              style={{ height: '100%' }}
              notMerge
              lazyUpdate={false}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

const getOption = (
  highPropertyIds: number[],
  unit: string,
  markAreaData: { yAxis: number | null | undefined }[][],
  from: number,
  to: number,
  showStackName: boolean,
  chartData?: DeviceData[],
  devicesToQueryIds?: (Stack | Rack | Pack)[],
  pcsPowerData?: DeviceData[],
  grainChoice?: GrainChoice
) => {
  let minVal: number, maxVal: number;
  let showMin: boolean = true,
    showMax: boolean = true;

  const chartDataSeries =
    chartData?.map((item, index) => {
      let name;
      let unitName = unit === 'V' ? '电压' : '温度';

      showMin = showMin && item.values.every(v => v.value > (unit === 'V' ? 2.0 : 0));
      showMax = showMax && item.values.every(v => v.value < (unit === 'V' ? 4.2 : 50));

      minVal = unit === 'V' ? 2.0 : 0;
      maxVal = unit === 'V' ? 4.2 : 50;

      let label = highPropertyIds.includes(item.propertyId) ? `最高${unitName}` : `最低${unitName}`;
      const device = devicesToQueryIds?.find(d => d.deviceId === item.deviceId)!;
      name = isPack(device)
        ? `${showStackName ? `电池堆${device.stackSequence + 1}` : ''}电池簇${device.rackSequence + 1}电池组${
            device.packSequence + 1
          }${label}`
        : isRack(device)
        ? `${showStackName ? `电池堆${device.stackSequence + 1}` : ''}电池簇${device.rackSequence + 1}${label}`
        : `电池堆${device.stackSequence + 1}${label}`;

      const data = item.values.map(v => [v.time, v.value]);
      return {
        connectNulls: true,
        name,
        type: 'line',
        data,
        markArea: index === 0 && { data: markAreaData },
        smooth: true,
        yAxisIndex: 0,
      };
    }) || [];

  const powerSeries =
    pcsPowerData?.map(item => {
      return {
        connectNulls: true,
        name: 'PCS功率',
        type: 'line',
        data: item.values.map(v => [v.time, v.value]),
        smooth: true,
        yAxisIndex: 1,
      };
    }) || [];
  const chartDataYAxis = {
    name: unit,
    type: 'value',
    nameTextStyle: { align: 'right', padding: [5, 10] },
    scale: true,
    min: showMin ? minVal! : null,
    max: showMax ? maxVal! : null,
    splitLine: {
      lineStyle: {
        type: 'dotted',
        color: 'rgba(255,255,255,0.35)',
      },
    },
  };

  const powerYAxis = {
    name: 'kW',
    type: 'value',
    nameTextStyle: { align: 'left', padding: [5, 10] },
    scale: true,
    splitLine: {
      lineStyle: {
        type: 'dotted',
        color: 'rgba(255,255,255,0.35)',
      },
    },
  };

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: { left: 80, right: 80 },
    legend: {
      show: true,
      right: 100,
      top: 0,
      icon: 'rect',
      textStyle: {
        color: '#AFBCC4',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any[]) => {
        return params.reduce(
          (acc, curr) =>
            acc +
            `<span >${curr.marker} ${curr.seriesName}：</span> ${
              (unit === 'V' ? curr.data[1]?.toFixed(3) : curr.data[1]?.toFixed(1)) || '--'
            }${curr.seriesName === 'PCS功率' ? 'kW' : unit}<br />`,
          `${params[0].axisValueLabel}<br />`
        );
      },
    },
    dataZoom: [
      {
        type: 'slider',
        start: grainChoice === GrainChoice.ORIGINAL ? 98 : grainChoice === GrainChoice.MINUTE_1 ? 90 : 0,
        end: 100,
        bottom: 15,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    xAxis: {
      // min: from,
      // max: to,
      // interval: 3600 * 1000,
      minInterval: 60 * 1000,
      type: 'time',
      axisLabel: {
        color: 'rgba(255,255,255,0.6)',
        fontSize: 12,
        formatter: (val: number) => dayjs(val, 'x').format('MM-DD[\n]HH:mm'),
        // dayjs(val).format('mm').includes('00') ? dayjs(val).format('H点') : dayjs(val).format('H点mm分'),
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: powerSeries.length ? [chartDataYAxis, powerYAxis] : chartDataYAxis,
    series: [...chartDataSeries, ...powerSeries],
  };
};

export default BatteryTemper;
