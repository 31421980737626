import { Checkbox, Modal, Select } from '@maxtropy/components';
import {
  apiV2NeoBsaDatapropertyConfigPost,
  apiV2NeoBsaGetAllDatapropertyConfigPost,
  apiV2NeoBsaGetDatapropertyConfigPost,
  apiV2NeoBsaTenantCustomerTreePost,
} from '@maxtropy/dmes-apis-v2';
import { useRequest, useSafeState } from 'ahooks';
import { CheckboxProps, Col, Row, Space, Spin } from 'antd';
import { Item } from 'rc-menu';
import { useContext, useEffect, useMemo, useState } from 'react';
import { isTemplateExpression } from 'typescript';
import { RowTypeDisplay } from '.';
import { ShareContext } from '../..';
import { BsaDeviceType, RealData } from '../../type';
import styles from './index.module.scss';
interface Iprops {
  modalType?: number; // 故障告警, 还是状态
  onCancel?: () => void;
  onConfirm?: () => void;
  realData?: RealData;
}
const CheckboxGroup = Checkbox.Group;

const SettingModal = ({ modalType, onCancel, onConfirm, realData }: Iprops) => {
  const { bsaId, bsaType } = useContext(ShareContext);
  const bsaDeviceType = realData?.[0]?.bsaDeviceType;
  const [selectBsaId, setSelectBsaId] = useState<string>(); // 下拉框选择的储能站
  const [loading, setLoading] = useState(false);

  // 物模型
  const physicalModelIds = useMemo(() => {
    let temp = realData?.filter(item => item.physicalModelId).map(item => item.physicalModelId!);
    return [...new Set(temp ?? [])];
  }, [realData]);
  // 设备类目
  const deviceTypes = useMemo(() => {
    let temp = realData?.filter(item => item.deviceType).map(item => item.deviceType!);
    return [...new Set(temp ?? [])];
  }, [realData]);

  const [checkedList, setCheckedList] = useState<any[]>([]);

  // 查询所有配置
  const { data: plainOptions } = useRequest(
    () => {
      return apiV2NeoBsaGetAllDatapropertyConfigPost({
        deviceTypeIds: deviceTypes,
        physicalModelIds: physicalModelIds,
      }).then(res => res.list ?? []);
    },
    {
      ready: !!physicalModelIds && !!deviceTypes && (deviceTypes.length > 0 || physicalModelIds.length > 0),
      refreshDeps: [physicalModelIds, deviceTypes],
    }
  );
  const allDatapropertyId = useMemo(() => {
    return plainOptions?.map(item => item.datapropertyId);
  }, [plainOptions]);

  // 查询已选择的配置
  useEffect(() => {
    if (bsaId && bsaDeviceType && modalType) {
      setLoading(true);
      apiV2NeoBsaGetDatapropertyConfigPost({ bsaId, bsaType, dataTab: bsaDeviceType, dataType: modalType })
        .then(res => {
          if (res.list) {
            setCheckedList(res.list.map(item => +item.datapropertyId!));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [bsaId, bsaType, bsaDeviceType, modalType]);

  const checkAll = plainOptions?.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < (plainOptions ?? []).length;

  const onChange = (list: any[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = e => {
    setCheckedList(e.target.checked ? (plainOptions ?? []).map(item => item.datapropertyId!) : []);
  };
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { data: options, loading: selectLoading } = useRequest(async () => {
    const res = await apiV2NeoBsaTenantCustomerTreePost({});

    const flat = res.list?.flatMap(item => item.bsaCustomerTree);
    return flat?.flatMap(item => item?.bsaTree ?? []);
  });
  // 根据其他储能站配置一键勾选, 取交集
  useEffect(() => {
    if (selectBsaId && bsaDeviceType && modalType) {
      let [currentSelectBsaId, currentSelectBsaType] = selectBsaId?.split('-');
      setLoading(true);
      apiV2NeoBsaGetDatapropertyConfigPost({
        bsaId: currentSelectBsaId,
        bsaType: currentSelectBsaType,
        dataTab: bsaDeviceType,
        dataType: modalType,
      })
        .then(res => {
          if (res.list) {
            let selects = res.list
              .filter(item => allDatapropertyId?.includes(item.datapropertyId))
              .map(item => item.datapropertyId!);
            setCheckedList(selects ?? []);
            return;
          }
          setCheckedList([]);
        })
        .finally(() => setLoading(false));
    }
  }, [selectBsaId, bsaType, bsaDeviceType, modalType, options]);

  const onConfirmBtn = () => {
    if (confirmLoading) return;
    setConfirmLoading(true);

    apiV2NeoBsaDatapropertyConfigPost({
      bsaId,
      bsaType,
      dataTab: bsaDeviceType,
      dataType: modalType,
      datapropertyIdList: checkedList as number[],
    })
      .then(res => {
        setCheckedList([]);
        onConfirm?.();
      })
      .finally(() => setConfirmLoading(false));
  };
  return (
    <>
      <Modal
        bodyScroll
        open
        size="big"
        confirmLoading={confirmLoading}
        title={RowTypeDisplay[modalType ?? 1]}
        onCancel={() => onCancel?.()}
        onOk={onConfirmBtn}
      >
        <Spin spinning={selectLoading || loading}>
          <Space>
            根据其他储能站配置一键勾选:
            <Select
              onChange={setSelectBsaId}
              style={{ width: 240 }}
              placeholder="请选择"
              options={options?.map(item => ({ label: item.bsaName, value: `${item.bsaId}-${item.bsaType}` }))}
            />
          </Space>
          <div>
            <div className={styles.select_all}>
              {plainOptions && plainOptions.length > 0 && (
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                  全选
                </Checkbox>
              )}
            </div>

            <div>
              <Checkbox.Group value={checkedList} onChange={onChange} style={{ width: '100%' }}>
                <Row gutter={[10, 10]}>
                  {plainOptions?.map(item => (
                    <Col key={item.datapropertyId} span={6}>
                      <Checkbox value={item.datapropertyId}>{item.datapropertyName}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default SettingModal;
