import React, { FC } from 'react';
import styles from './index.module.scss';
import { V2EnergyConservationEnergySavingPiePostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoOrLine, keepTwoOrLinePercent } from '../../utils';

interface ITotalEnergySavings {
  energySavingData?: V2EnergyConservationEnergySavingPiePostResponse;
}

const TotalEnergySavings: FC<ITotalEnergySavings> = props => {
  const { energySavingData } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.left_box}>
        <div className={styles.label}>节能总量(kWh)</div>
        <div className={styles.value} style={{ color: '#16DD8E' }}>
          {keepTwoOrLine(energySavingData?.energySavingTotalPower)}
        </div>
      </div>
      <div className={styles.right_box}>
        <div className={styles.label}>环比</div>
        <div className={styles.value} style={{ color: '#52C41A' }}>
          {keepTwoOrLinePercent(energySavingData?.rate)}
        </div>
      </div>
    </div>
  );
};

export default TotalEnergySavings;
