import { Form, Modal, Select } from '@maxtropy/components';
import { apiV2EnergyConservationCircuitListPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import React, { FC, useEffect } from 'react';

export interface ISelectCircuitModal {
  selectCircuitModalVisible: boolean;
  onCancel: () => void;
  onSubmit: (values: { idList: number[] }) => Promise<void>;
}

const SelectCircuitModal: FC<ISelectCircuitModal> = props => {
  const { selectCircuitModalVisible, onCancel, onSubmit } = props;
  const [form] = Form.useForm();

  // 获取员工下所有回路
  const { data: circuitList } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationCircuitListPost({});
      return res?.list;
    },
    { ready: selectCircuitModalVisible }
  );

  useEffect(() => {
    if (!circuitList) return;
    form.setFieldsValue({
      idList: circuitList.filter(item => !item.isOptional).map(i => i.id),
    });
  }, [circuitList]);

  const onOK = async () => {
    const values = await form.validateFields();
    await onSubmit({
      ...values,
    });
    form.resetFields();
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      size="normal"
      destroyOnClose
      open={selectCircuitModalVisible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={onOK}
      title={'选择回路'}
    >
      <Form form={form} labelAlign="left">
        <Form.Item name="idList" label="回路名称" rules={[{ required: true, message: '请选择回路名称' }]}>
          <Select
            mode="multiple"
            showSearch
            optionFilterProp="label"
            options={(circuitList ?? []).map(item => ({
              value: item.id,
              label: item.name,
              disabled: !item.isOptional,
            }))}
            placeholder="请选择"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectCircuitModal;
