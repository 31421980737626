import { V2EnergyConservationEnergySavingPiePostResponse } from '@maxtropy/dmes-apis-v2';
import React, { FC, useMemo } from 'react';
import { getPieChartOption } from './charts';
import { Spin } from 'antd';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';

interface IEnergySavingPie {
  energySavingData?: V2EnergyConservationEnergySavingPiePostResponse;
  loading?: boolean;
}

const EnergySavingPie: FC<IEnergySavingPie> = props => {
  const { energySavingData, loading } = props;

  // 饼图图表
  const chartPieOption = useMemo(() => getPieChartOption(energySavingData), [energySavingData]);

  return (
    <div className={styles.chart_box}>
      {loading ? (
        <Spin spinning={true} className={styles.spin_box} />
      ) : (
        <ReactEcharts style={{ height: 240, minWidth: 480 }} option={chartPieOption} notMerge lazyUpdate={false} />
      )}
    </div>
  );
};

export default EnergySavingPie;
