import { getEnergyAssetStationInfo, OuList, StationInfoRes } from '@/api/energyAssetOverview';
import { Col, Row, Spin } from 'antd';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { StationHasCount } from '../..';
import HeadTitle from '../HeadTitle/HeadTitle';
import ValueLineTitle from '../ValueLineTitle';
import styles from './index.module.scss';

export interface IStationEnergyInformation {
  ouInfo: OuList;
  height: string | undefined;
}

const StationEnergyInformation: FC<IStationEnergyInformation> = props => {
  const { ouInfo, height } = props;
  const stationHasCount = useContext(StationHasCount);
  const [energyAssetStationInfo, setEnergyAssetStationInfo] = useState<StationInfoRes>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 查询场站信息
      getEnergyAssetStationInfo(ouInfo.id).then(res => {
        setEnergyAssetStationInfo(res);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);
  const dataCount = useMemo(() => {
    return [stationHasCount?.evCount, stationHasCount?.pvCount].filter(Boolean).length;
  }, [stationHasCount]);
  const colSpan = useMemo(() => {
    if (dataCount === 1) return 24;
    if (dataCount === 2) return 12;
    return 24;
  }, [dataCount]);

  // 有且只有储能站
  const isOnlyBsa = useMemo(() => {
    return stationHasCount?.bsaCount && !stationHasCount?.pvCount && !stationHasCount?.evCount;
  }, [stationHasCount]);

  // 只有pv和ev
  const hasNoBsa = useMemo(() => {
    return !stationHasCount?.bsaCount && stationHasCount?.pvCount && stationHasCount?.evCount;
  }, [stationHasCount]);

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeadTitle title="今日场站能源信息" />
      <Spin spinning={dataLoading}>
        <Row>
          {stationHasCount?.bsaCount && (
            <>
              <Col span={isOnlyBsa ? 24 : 12}>
                <ValueLineTitle
                  value={stationHasCount?.bsaCount ? energyAssetStationInfo?.bsaBeChargeByDay : '无储能站'}
                  title={'储能站充电量(kWh)'}
                />
              </Col>

              <Col span={isOnlyBsa ? 24 : 12}>
                <ValueLineTitle
                  value={stationHasCount?.bsaCount ? energyAssetStationInfo?.bsaDisChargeByDay : '无储能站'}
                  title={'储能站放电量(kWh)'}
                />
              </Col>
            </>
          )}
          {stationHasCount?.pvCount && (
            <Col span={hasNoBsa ? 24 : colSpan}>
              <ValueLineTitle
                value={stationHasCount?.pvCount ? energyAssetStationInfo?.pvChargeByDay : '无光伏站'}
                title={'光伏站发电量(kWh)'}
              />
            </Col>
          )}
          {stationHasCount?.evCount && (
            <Col span={hasNoBsa ? 24 : colSpan}>
              <ValueLineTitle
                value={stationHasCount?.evCount ? energyAssetStationInfo?.evBeChargeByDay : '无充电站'}
                title={'充电站充电量(kWh)'}
              />
            </Col>
          )}
        </Row>
      </Spin>
    </div>
  );
};

export default StationEnergyInformation;
