import { useBreadcrumbRoutes, message, Wrapper, SubContent } from '@maxtropy/components';
import { StrategyForm, VersionType } from '@maxtropy/strategy';
import { useForm } from 'antd/es/form/Form';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import {
  createStrategyTemplate,
  fetchStrategyTemplateDetail,
  getTemplatePcsDeviceLink,
  TemplatePcsDeviceLinkRes,
  updateStrategyTemplate,
} from '../../../api/strategy';
import FormHeader from '../../../components/FormHeader';
import { FormStatus } from '../../../types';
// import StrategyForm from "../StrategyForm";
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';

interface StrategyTemplatePageProps {
  formStatus?: FormStatus;
}

const StrategyTemplatePage: React.FC<StrategyTemplatePageProps> = ({ formStatus = FormStatus.Create }) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { versionId, versionName, bsaId, bsaName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;
  const routes = [{ name: `${FormStatus.MAP[formStatus] + '储能策略模版'}` }];

  const routesContext = useBreadcrumbRoutes();
  const { mutateAsync, isLoading: isSubmitting } = useMutation(
    formStatus === FormStatus.Create || formStatus === FormStatus.Copy ? createStrategyTemplate : updateStrategyTemplate
  );

  const { data, isLoading } = useQuery(['strategyTemplate', id], () => fetchStrategyTemplateDetail(+id), {
    enabled: !!id,
  });

  const version = formStatus === FormStatus.Create ? versionId : data?.sysVersionId;

  const [form] = useForm();

  const onSubmit = () => {
    form.submit();
  };
  const [upstreamTransformerMeterParamList, setUpstreamTransformerMeterParamList] = useState<
    TemplatePcsDeviceLinkRes[]
  >([]);
  // CF版本策略查询储能策略上端变压器模板列表, 点击增加智能充放电时段用
  useEffect(() => {
    if (version && +version === VersionType.CF && bsaId) {
      getTemplatePcsDeviceLink(+bsaId).then(res => setUpstreamTransformerMeterParamList(res));
    }
  }, [formStatus, version, bsaId]);
  const strategyFormData = useMemo(() => {
    return formStatus === FormStatus.Copy
      ? { ...data, bsaName, bsaId, upstreamTransformerMeterParamList }
      : { version: versionName, bsaName, ...data, upstreamTransformerMeterParamList };
  }, [bsaId, bsaName, data, formStatus, versionName, upstreamTransformerMeterParamList]);
  const onFinish = (values: any) => {
    let tempBsaId = [FormStatus.Copy, FormStatus.Create].includes(formStatus) ? bsaId : data?.bsaId;
    mutateAsync({ ...values, sysVersionId: version, bsaId: tempBsaId, id: data?.id }).then(res => {
      if (res) {
        message.success('保存成功！');
        window.open('/dmes/energy-storage/strategy', '_self');
        // navigate('/dmes/energy-storage/strategy', { replace: true });
        // 此处navigate后，语言重置为英文
      }
    });
  };

  return (
    <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className="wrapper-padding" isLoading={isLoading}>
      {formStatus !== FormStatus.Display && (
        <FormHeader
          title={FormStatus.MAP[formStatus] + '储能策略模版'}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          showCancelButton={formStatus !== FormStatus.Update}
        />
      )}
      <SubContent>
        {version && (
          <StrategyForm
            version={+version as VersionType}
            state={Number(formStatus)}
            form={form}
            key={data?.bsaId}
            onFinish={onFinish}
            data={strategyFormData}
          />
        )}
      </SubContent>
    </Wrapper>
  );
};

export default StrategyTemplatePage;
