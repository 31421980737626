import { fetch } from '@maxtropy/components';
import qs from 'qs';
import {
  FieldStrategy,
  PowerType,
  ProtectType,
  StrategyRunningStatus,
  StrategySection,
  StrategyTemplate,
  StrategyTemplateStatus,
  TimePeriodDimension,
} from '../pages/Strategy/types';
import { PageRequest, PageResponse } from './page';
import { BSA, Customer, FCSDetail, Ou, UET } from '../types';
import { LocalCalendarStatus } from './type';
import { Key } from 'react';
import { BsaListProps } from './bsa';
import { PcsDevice } from './entity';

export interface StrategyTemplatePageQuery {
  code?: string;
  ifCancel?: boolean;
  status?: StrategyTemplateStatus;
  bsaId?: Key;
  name?: string; // 模板名称
  versionId?: number;
  version?: string;
}

export interface StrategyTemplateBody {
  id?: number;
  type: number;
  name: string;
  protectType: ProtectType;
  dodMax?: number;
  dodMin?: number;
  cellVoltageMax?: number;
  cellVoltageMin?: number;
  standbyPower: boolean;
  timeGranularity: TimePeriodDimension;
  powerType: PowerType;
  sysVersionId: number;
  sections: StrategySection[];
}

export interface Version {
  id: number;
  version: string;
  name: string;
}

export interface Organization {
  data: Customer;
  children: Organization[];
}

export interface FieldStrategyBody extends StrategyTemplateBody {
  version: number;
  templateId: number;
  executiveTimeFrom: string;
  executiveTimeTo: string;
  globalVersion: number;
}

export const fetchStrategyTemplatePage = (params: StrategyTemplatePageQuery & PageRequest) =>
  fetch<PageResponse<StrategyTemplate>>(
    `/api/v2/energy-storage-strategy/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const createStrategyTemplate = (data: StrategyTemplateBody) =>
  fetch<{ result: boolean }>(
    `/api/v2/energy-storage-strategy/insert`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  ).then(res => res.result);

export const updateStrategyTemplate = (data: StrategyTemplateBody) =>
  fetch<{ result: boolean }>(
    `/api/v2/energy-storage-strategy/update`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  ).then(res => res.result);

export const fetchStrategyTemplateDetail = (id: number) =>
  fetch<StrategyTemplate>(
    `/api/v2/energy-storage-strategy/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const updateStrategyTemplateStatus = (id: number, status: StrategyTemplateStatus) =>
  fetch<{ result: boolean }>(
    `/api/v2/energy-storage-strategy/update-status`,
    {
      method: 'POST',
      body: JSON.stringify({ id, status }),
    },
    true
  );

export const fetchVersionList = () =>
  fetch<{ list: Version[] }>(
    `/api/v2/energy-storage-strategy/list-version`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);

export const fetchCustomerList = () =>
  fetch<{ list: Customer[] }>(
    `/api/v2/energy-storage-strategy/list-customer`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);

export const fetchOuList = (value: string) =>
  fetch<{ list: Ou[] }>(
    `/api/v2/energy-storage-strategy/list-ou`,
    {
      method: 'POST',
      body: JSON.stringify({ value }),
    },
    true
  ).then(res => res.list ?? []);

export const fetchUETList = (id: number) =>
  fetch<{ list: UET[] }>(
    `/api/v2/energy-storage-strategy/list-uet`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);

export const fetchBSAList = (id: number) =>
  fetch<{ list: BSA[] }>(
    `/api/v2/energy-storage-strategy/list-bsa`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);
export interface FCSInfo {
  id: number;
  name: string;
  serialNumber?: string;
  gatewayId?: number;
  ouId?: number;
  remark?: string;
  updateByUserId?: string;
  softwareVersion?: string;
  softwareName: string;
  type?: number; // fcs类型
  linkType?: number; //  0表示主控，1表示从控，-1表示非主从结构
  selected?: boolean; // 自定义字段, 是否已被选择
}

export const fetchFieldStrategyList = (fcsId: number) =>
  fetch<FieldStrategy[]>(`/api/energy-storage-strategy/list-local/${fcsId}`).then(res => res);

export const updateFieldStrategy = (bsaId: number, data: FieldStrategyBody) =>
  fetch<{ responseDto: { result: boolean; message: string } }>(
    `/api/v2/energy-storage-strategy/local-update`,
    {
      method: 'POST',
      body: JSON.stringify({ bsaId, ...data }),
    },
    true
  ).then(res => res.responseDto);

export const fetchOrganizations = () =>
  fetch<Organization>(
    `/api/v2/energy-storage-strategy/organization-with-code`,
    {
      method: 'POST',
    },
    true
  );

export interface FieldStrategyListQuery {
  beOverdue: boolean;
}

export const fetchFieldStrategies = (fcsId: number, query: FieldStrategyListQuery) =>
  fetch<{ list: FieldStrategy[] }>(
    `/api/energy-storage-strategy/list-local/${fcsId}?${qs.stringify(query, { indices: false })}`
  ).then(res => res);

export interface LaunchStrategyBody {
  id: number;
  name: string;
  executiveTimes: {
    day: string;
    version: number;
  }[];
}

export const launchStrategy = (data: LaunchStrategyBody) =>
  fetch<{ responseDto: { result: boolean; message: string } }>(
    `/api/v2/energy-storage-strategy/deliver`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  );

export const fetchFieldStrategyDetail = (bsaId: number, id: number) =>
  fetch<FieldStrategy>(
    `/api/v2/energy-storage-strategy/local-details`,
    {
      method: 'POST',
      body: JSON.stringify({ bsaId, id }),
    },
    true
  );

export const fetchBSAInfo = (id: number) =>
  fetch<FCSDetail>(
    `/api/v2/energy-storage-strategy/bsa-info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const updateFieldStatus = (
  bsaId: number,
  params: { strategyIds: number[]; status: StrategyRunningStatus; version: number; globalVersion: number }
) =>
  fetch<{ result: boolean }>(
    `/api/v2/energy-storage-strategy/local-status-update`,
    { method: 'POST', body: JSON.stringify({ bsaId, ...params }) },
    true
  );

export interface LocalCalendarProps {
  id: number;
  day: string;
  name: string;
  code: string;
  globalVersion: number;
  status: LocalCalendarStatus;
  version: number;
  templateId: number;
}

export interface CalendarReq {
  bsaId: number;
  year: string;
  month: string;
}

export const fetchCalendar = (query: CalendarReq) =>
  fetch<LocalCalendarProps[]>(
    `/api/energy-storage-strategy/local-calendar/${query.bsaId}?${qs.stringify(query, { indices: false })}`
  );

export interface LocalDetailProps {
  bsaId: number;
  fcsId: number;
  globalVersion: number;
  id: number;
  mcid: string;
  name: string;
  ouId: number;
  protectType: number;
  sections: StrategySection[];
  timeGranularity: TimePeriodDimension;
  powerType: PowerType;
  uetId: number;
  version: number;
  sysVersionId: number;
}

export const fetchLocalDetail = (id: string, bsaId: Key) =>
  fetch<LocalDetailProps>(`/api/energy-storage-strategy/local-details/${bsaId}?id=${id}`);

export interface ChangeStatusReq {
  strategyIds: number[];
  status: LocalCalendarStatus;
  version: number;
  globalVersion: number;
}

export const changeStatus = (bsaId: number, query: ChangeStatusReq) =>
  fetch(
    `/api/v2/energy-storage-strategy/local-status-update`,
    {
      method: 'POST',
      body: JSON.stringify({ bsaId, ...query }),
    },
    true
  );

export const getBsaListBySystemVersion = (systemVersion?: string) => {
  return fetch<{ list: BsaListProps[] }>(
    `/api/v2/bsa/list/simple`,
    {
      method: 'POST',
      body: JSON.stringify(systemVersion),
    },
    true
  ).then(res => res.list ?? []);
};

export interface PutRecordResponse {
  id: number;
  userAccount: string;
  templateId: number;
  deliverTime: string;
  deliverDates: string[];
  bsaId: number;
  bsaName: string;
  tenantMcid: string;
}
// 根据策略模板id查询投放记录
export const getPutRecordList = (id?: Key) => {
  return fetch<{ list: PutRecordResponse[] }>(
    `/api/v2/energy-storage-strategy/deliver/log`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);
};
// 获取策略模板具体信息

export interface TemplateDetail {
  id: number;
  name: string;
  bsaId: number;
  bsaName: string;
  uetName: string;
  ouName: string;
  networkName: string;
  installedCapacity: number;
  fcsList: FCSInfo[];
  pcsList: PcsDevice[];
  protectType: number;
  timeGranularity: number;
  sysVersionId: number;
  additionalFields: AdditionalField;
  sections: Section[];
  status: number;
  code: string;
  version: string;
  netNo: string;
}
export interface AdditionalField {
  dodMax: number;
  dodMin: number;
  cellVoltageMax: number;
  cellVoltageMin: number;
}
export interface Section {
  beginTime: string;
  endTime: string;
  type: number;
  additionalFields: AdditionalField;
}

export const getTemplateDetail = (templateId?: Key) => {
  return fetch<TemplateDetail>(`/api/energy-storage-strategy/detail/${templateId}`);
};
// CF策略模板查询上端变压器模板列表
export interface TemplatePcsDeviceLinkRes {
  deviceName?: string;
  deviceId?: number;
}
export const getTemplatePcsDeviceLink = (bsaId: number) => {
  return fetch<{ list: TemplatePcsDeviceLinkRes[] }>(
    `/api/v2/energy-storage-strategy/pcs-device-link`,
    {
      method: 'POST',
      body: JSON.stringify({
        id: bsaId,
      }),
    },
    true
  ).then(res => res.list);
};

export enum StrategyVersion {
  v1 = 1,
  v2 = 2,
}
// fcs类型 0表示主控，1表示从控，-1表示非主从结构
export enum FCSType {
  NONE = -1,
  MAIN = 0,
  OTHER = 1,
}
