import { Button, EllipsisSpan, Modal, Table } from '@maxtropy/components';
import { V2EnergyConservationCircuitEnergyConfigLogPagePostResponse } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import React, { FC, useMemo } from 'react';
import { keepTwoOrLine } from '../utils';
import styles from './index.module.scss';

export type ItemProps = Exclude<V2EnergyConservationCircuitEnergyConfigLogPagePostResponse['list'], undefined>[number];

const columns = [
  {
    title: '',
    children: [
      {
        title: '时间',
        dataIndex: 'time',
        render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
      },
    ],
  },
  {
    title: '电价信息(元/kWh)',
    children: [
      {
        title: '修改前',
        dataIndex: 'elecPriceBefore',
        render: (_: number, record: ItemProps) => (
          <EllipsisSpan
            value={
              <>
                <span>尖({keepTwoOrLine(record.before?.sharpPrice)})</span>&nbsp;
                <span>峰({keepTwoOrLine(record.before?.peakPrice)})</span>&nbsp;
                <span>平({keepTwoOrLine(record.before?.flatPrice)})</span>&nbsp;
                <span>谷({keepTwoOrLine(record.before?.valleyPrice)})</span>
              </>
            }
          />
        ),
      },
      {
        title: '修改后',
        dataIndex: 'elecPriceAfter',
        render: (_: number, record: ItemProps) => (
          <EllipsisSpan
            value={
              <div className={styles.afterValue}>
                <span>尖({keepTwoOrLine(record.before?.sharpPrice)})</span>&nbsp;
                <span>峰({keepTwoOrLine(record.before?.peakPrice)})</span>&nbsp;
                <span>平({keepTwoOrLine(record.before?.flatPrice)})</span>&nbsp;
                <span>谷({keepTwoOrLine(record.before?.valleyPrice)})</span>
              </div>
            }
          />
        ),
      },
    ],
  },
  {
    title: '节能率',
    children: [
      {
        title: '修改前',
        dataIndex: 'energySavingBefore',
        render: (_: number, record: ItemProps) => (
          <EllipsisSpan value={keepTwoOrLine(record.before?.energySavingRate)} />
        ),
      },
      {
        title: '修改后',
        dataIndex: 'energySavingAfter',
        render: (_: number, record: ItemProps) => (
          <EllipsisSpan value={keepTwoOrLine(record.after?.energySavingRate)} />
        ),
      },
    ],
  },
  {
    title: '甲方节能效益占比',
    children: [
      {
        title: '修改前',
        dataIndex: 'RateABefore',
        render: (_: number, record: ItemProps) => <EllipsisSpan value={keepTwoOrLine(record.before?.benefitRatioA)} />,
      },
      {
        title: '修改后',
        dataIndex: 'RateAAfter',
        render: (_: number, record: ItemProps) => <EllipsisSpan value={keepTwoOrLine(record.after?.benefitRatioA)} />,
      },
    ],
  },
  {
    title: '乙方节能效益占比',
    children: [
      {
        title: '修改前',
        dataIndex: 'RateBBefore',
        render: (_: number, record: ItemProps) => <EllipsisSpan value={keepTwoOrLine(record.before?.benefitRatioB)} />,
      },
      {
        title: '修改后',
        dataIndex: 'RateBAfter',
        render: (_: number, record: ItemProps) => <EllipsisSpan value={keepTwoOrLine(record.after?.benefitRatioB)} />,
      },
    ],
  },
];

export interface IShowDetailModal {
  configLogDetail?: ItemProps;
  detailModalVisible: boolean;
  onCancel: () => void;
}

const ShowDetailModal: FC<IShowDetailModal> = props => {
  const { configLogDetail, detailModalVisible, onCancel } = props;

  const dataList: any[] = useMemo(() => {
    if (!configLogDetail) return [];
    return (configLogDetail.month ?? []).map(item => ({
      time: configLogDetail.year?.toString() + '-' + String(item).padStart(2, '0'),
      before: configLogDetail.before,
      after: configLogDetail.after,
    }));
  }, [configLogDetail]);

  return (
    <Modal
      contentClassName={styles.modalWrapper}
      size="big"
      destroyOnClose
      open={detailModalVisible}
      onCancel={onCancel}
      title={'查看'}
      bodyScroll
      footer={
        <Button type="primary" onClick={onCancel}>
          知道了
        </Button>
      }
    >
      <Table bordered rowKey="time" columns={columns} dataSource={dataList} />
    </Modal>
  );
};

export default ShowDetailModal;
