import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { keepTwoNull } from '../../utils';
import { V2EnergyConservationEnergySavingComparisonPostResponse } from '@maxtropy/dmes-apis-v2';

const areaColors = [
  new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    { offset: 0, color: '#FFCB4720' },
    { offset: 1, color: '#FFCB4710' },
  ]),
  new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    { offset: 0, color: '#4A90E220' },
    { offset: 1, color: '#4A90E210' },
  ]),
];

export const getChartOption = (data?: V2EnergyConservationEnergySavingComparisonPostResponse) => {
  // 改造前
  let actualData = (data?.list ?? []).map(item => [dayjs(item.ts).valueOf(), keepTwoNull(item.beforeValue)]);

  // 改造后
  let predictiveData = (data?.list ?? []).map(item => [dayjs(item.ts).valueOf(), keepTwoNull(item.afterValue)]);

  return {
    color: ['#FACC58', '#0DB6D9'],
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data, marker } = item;
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;">${marker}</span>${seriesName} : &nbsp;${
            data[1] || data[1] === 0 ? data[1] : '-'
          }${'kWh'}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 20,
      right: 10,
      top: 50,
      bottom: 40,
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 10,
        left: 25,
        right: 15,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
        labelFormatter: (value: string) => {
          return dayjs(value).format('YYYY-MM-DD');
        },
      },
    ],
    legend: [
      {
        right: 90,
        itemGap: 18,
        itemWidth: 12,
        itemHeight: 12,
        itemStyle: {
          borderWidth: 0, // 去掉边框
        },
        textStyle: {
          color: '#ffffff85',
        },
        inactiveColor: '#ffffff45',
        data: [
          {
            name: '改造前',
            icon: 'rect',
          },
        ],
        emphasis: {
          itemStyle: {
            borderWidth: 0, // 鼠标悬停时去除边框
          },
        },
        inactiveItemStyle: {
          borderWidth: 0, // 取消点击后的边框
        },
      },
      {
        right: 10,
        itemGap: 18,
        itemWidth: 12,
        itemHeight: 12,
        itemStyle: {
          borderWidth: 0, // 去掉边框
        },
        textStyle: {
          color: '#ffffff85',
        },
        inactiveColor: '#ffffff45',
        data: [
          {
            name: '改造后',
            icon: 'rect',
          },
        ],
        emphasis: {
          itemStyle: {
            borderWidth: 0, // 鼠标悬停时去除边框
          },
        },
        inactiveItemStyle: {
          borderWidth: 0, // 取消点击后的边框
        },
      },
    ],
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          formatter: '{MM}-{dd}',
          textStyle: {
            color: 'var(--mx-text-base-color)', //坐标的字体颜色
          },
          fontSize: 10,
        },
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          show: true, // 是否显示轴线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'kWh',
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'var(--mx-text-base-color)', //坐标的字体颜色
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    series: [
      {
        name: '改造前',
        type: 'line',
        data: actualData,
        connectNulls: true,
        smooth: true,
        symbol: 'circle',
        symbolSize: 6,
        itemStyle: {
          color: '#FACC58',
          // symbol样式
          normal: {
            borderColor: '#FACC58', // symbol边框颜色
            borderWidth: 2, // symbol边框宽度
          },
        },
        lineStyle: {
          color: '#FACC58',
          width: 2,
          type: 'solid', //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
        },
        areaStyle: {
          color: areaColors[0],
        },
      },
      {
        name: '改造后',
        type: 'line',
        data: predictiveData,
        connectNulls: true,
        smooth: true,
        symbol: 'circle',
        symbolSize: 6,
        // showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          color: '#0DB6D9',
          // symbol样式
          normal: {
            borderColor: '#0DB6D9', // symbol边框颜色
            borderWidth: 2, // symbol边框宽度
          },
        },
        lineStyle: {
          color: '#0DB6D9',
          width: 2,
          type: 'solid', //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
        },
        areaStyle: {
          color: areaColors[1],
        },
      },
    ],
  };
};
