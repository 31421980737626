import { getEnergyAssetStationCount, OuList, StationCountRes } from '@/api/energyAssetOverview';
import BorderWrapper from '@/components/BorderWrapper';
import DragResize from '@/components/DragResize';
import { FullscreenExitOutlined, FullscreenOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Empty, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Col, Layout, Row } from 'antd';
import { createContext, Key, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import CurrentMonthIncome from './components/CurrentMonthIncome';
import CurrentThirtyDaysBsa from './components/CurrentThirtyDaysBsa';
import CurrentThirtyDaysChargingStation from './components/CurrentThirtyDaysChargingStation';
import CurrentThirtyDaysIncome from './components/CurrentThirtyDaysIncome';
import CurrentThirtyDaysPV from './components/CurrentThirtyDaysPV';
import InvestmentRecovery from './components/InvestmentRecovery';
import NotRecoveredAlarm from './components/NotRecoveredAlarm';
import OrgOuTree from './components/OrgOuTree';
import ProjectInfo from './components/ProjectInfo';
import SiteRevenue from './components/SiteRevenue';
import StationEnergyInformation from './components/StationEnergyInformation';
import styles from './index.module.scss';
import { cancelFullscreen, goFullscreen } from './utils';

const { Content, Sider } = Layout;

export const StationHasCount = createContext({} as StationCountRes | undefined);

const EnergyAssetsOverview = () => {
  const routesContext = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [ouInfo, setOuInfo] = useState<OuList>(); // 树选择信息
  const outerRef = useRef<HTMLDivElement>();
  const [topHeight, setTopHeight] = useState<string>();
  const [middleHeight, setMiddleHeight] = useState<string>();
  const [bottomHeight, setBottomHeight] = useState<string>();
  const [stationHasCount, setStationHasCount] = useState<StationCountRes>();

  // 获取光储充的数量状态
  useEffect(() => {
    if (ouInfo && ouInfo.id) {
      getEnergyAssetStationCount(ouInfo.id)
        .onError(err => {
          console.log(err);
          throw err;
        })
        .then(res => {
          setStationHasCount(res);
        });
    }
  }, [ouInfo]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setTopHeight('22vh');
      setMiddleHeight('31vh');
      setBottomHeight('36vh');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 选择树回调
  const changeTreeSelect = (_: Key[], info: any) => {
    if (info) {
      setOuInfo(info);
    } else {
      setOuInfo(undefined);
    }
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };
  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  useEffect(() => {
    if (isFullScreen) {
      setTopHeight('22vh');
      setMiddleHeight('31vh');
      setBottomHeight('36vh');
    } else {
      setTopHeight(undefined);
      setMiddleHeight(undefined);
      setBottomHeight(undefined);
    }
  }, [isFullScreen]);

  const fullBtn = () => {
    if (outerRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(outerRef.current);
    }
  };
  const dataCount = useMemo(() => {
    return [stationHasCount?.bsaCount, stationHasCount?.evCount, stationHasCount?.pvCount].filter(Boolean).length ?? 1;
  }, [stationHasCount]);
  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <div
        ref={dom => {
          if (dom) {
            outerRef.current = dom;
          }
        }}
      >
        <Layout className={styles.layout}>
          <Sider
            theme="light"
            width={openSider ? siderWidth : 0}
            style={{
              display: isFullScreen ? 'none' : 'unset',
              transition: dragStatus ? 'none' : 'all 0.2s',
              background: '#232324',
            }}
          >
            <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
            <OrgOuTree style={{ width: '100%' }} changeTreeSelect={changeTreeSelect} />
          </Sider>
          <Content className={styles.content}>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>
            {ouInfo ? (
              <>
                <div className={styles.selectedOuTitle}>
                  <div>
                    当前选择：<span className={styles.ouNameSty}>{ouInfo.name}</span>
                  </div>
                  {isFullScreen ? (
                    <Button type="primary" onClick={fullBtn} icon={<FullscreenExitOutlined />}>
                      退出
                    </Button>
                  ) : (
                    <Button type="primary" onClick={fullBtn} icon={<FullscreenOutlined />}>
                      全屏
                    </Button>
                  )}
                </div>
                <StationHasCount.Provider value={stationHasCount}>
                  <div>
                    <Row gutter={[8, 8]} style={{ marginBottom: 6 }}>
                      <Col span={5} xl={8} lg={8} md={8} sm={8} xxl={5}>
                        <ProjectInfo height={topHeight ?? '22vh'} ouInfo={ouInfo} />
                      </Col>
                      <Col span={5} xl={8} lg={8} md={8} sm={8} xxl={5}>
                        <StationEnergyInformation height={topHeight ?? '22vh'} ouInfo={ouInfo} />
                      </Col>
                      <Col span={5} xl={8} lg={8} md={8} sm={8} xxl={5}>
                        <CurrentMonthIncome height={topHeight ?? '22vh'} ouInfo={ouInfo} />
                      </Col>
                      <Col span={9} xl={24} lg={24} md={24} sm={24} xxl={9}>
                        <SiteRevenue height={topHeight ?? '22vh'} ouInfo={ouInfo} />
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]} style={{ marginBottom: 6 }}>
                      <Col span={12} xl={24} lg={24} md={24} sm={24} xxl={12}>
                        <CurrentThirtyDaysIncome ouInfo={ouInfo} height={middleHeight ?? '29vh'} />
                      </Col>
                      <Col span={7} xl={24} lg={24} md={24} sm={24} xxl={7}>
                        <InvestmentRecovery ouInfo={ouInfo} height={middleHeight ?? '29vh'} />
                      </Col>
                      <Col span={5} xl={24} lg={24} md={24} sm={24} xxl={5}>
                        <NotRecoveredAlarm ouInfo={ouInfo} height={middleHeight ?? '29vh'} />
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]} style={{ marginBottom: 6 }}>
                      {stationHasCount?.pvCount && (
                        <Col span={24 / dataCount} xl={24} lg={24} md={24} sm={24} xxl={24 / dataCount}>
                          <CurrentThirtyDaysPV height={bottomHeight ?? '30vh'} ouInfo={ouInfo} />
                        </Col>
                      )}
                      {stationHasCount?.bsaCount && (
                        <Col span={24 / dataCount} xl={24} lg={24} md={24} sm={24} xxl={24 / dataCount}>
                          <CurrentThirtyDaysBsa height={bottomHeight ?? '30vh'} ouInfo={ouInfo} />
                        </Col>
                      )}
                      {stationHasCount?.evCount && (
                        <Col span={24 / dataCount} xl={24} lg={24} md={24} sm={24} xxl={24 / dataCount}>
                          <CurrentThirtyDaysChargingStation height={bottomHeight ?? '30vh'} ouInfo={ouInfo} />
                        </Col>
                      )}
                    </Row>
                  </div>
                </StationHasCount.Provider>
              </>
            ) : (
              <div style={{ position: 'relative', height: 'calc(100vh - 101px)', backgroundColor: '#232324' }}>
                <Empty description={'暂无数据'} className={styles.empty} />
              </div>
            )}
          </Content>
        </Layout>
      </div>
    </Wrapper>
  );
};

export default EnergyAssetsOverview;
