import { Empty, Input } from '@maxtropy/components';
import styles from './index.module.scss';
import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiV2EnergyConservationCircuitEnergyPagePost } from '@maxtropy/dmes-apis-v2';
import menuCircuitImg from '../../imgs/menuCircuit.png';

const { Search } = Input;

export interface ICircuitList {
  style?: CSSProperties;
  changeListSelect?: (key?: React.Key) => void;
  circuitId?: React.Key;
}

const CircuitList: FC<ICircuitList> = props => {
  const { style, changeListSelect, circuitId } = props;
  const [searchParams, setSearchParams] = useState<string>(); // 搜索

  // 回路列表
  const { data: circuitList, loading } = useRequest(async () => {
    const res = await apiV2EnergyConservationCircuitEnergyPagePost({});
    return res.list ?? [];
  });

  // 默认选择第一个回路
  useEffect(() => {
    if (!circuitList || circuitList.length === 0) return;
    changeListSelect?.(circuitList[0].circuitId);
  }, [circuitList]);

  const isSelectedSty = useCallback(
    (item: any) => {
      return circuitId === item?.circuitId ? styles.titleButtonChecked : styles.titleButtonUnChecked;
    },
    [circuitId]
  );

  // 搜索的circuitList文字标红色
  const circuitListData = useMemo(() => {
    if (circuitList && circuitList.length && searchParams) {
      return circuitList.filter(item => (item?.circuitName ?? '').indexOf(searchParams) > -1);
    } else {
      return circuitList;
    }
  }, [searchParams, circuitList]);

  return (
    <div className={styles.treeArea} style={style}>
      <div className={styles.searchArea}>
        <Search placeholder="请输入名称" allowClear onSearch={(v: string) => setSearchParams(v)} />
      </div>

      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {circuitListData && circuitListData.length ? (
            circuitListData?.map(item => (
              <div
                key={item?.circuitId}
                className={`${styles.btnSty} ${isSelectedSty(item)}`}
                onClick={() => {
                  changeListSelect?.(item?.circuitId);
                }}
              >
                <img className={styles.imgSty} src={menuCircuitImg} alt="" />
                <span>{item?.circuitName}</span>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default CircuitList;
