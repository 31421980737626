import React, { useEffect, useMemo, useRef, useState } from 'react';
import BorderWrapper from '@/components/BorderWrapper';
import qs from 'qs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  EllipsisSpan,
  Table,
  useBreadcrumbRoutes,
  useCurrent,
  useUpdate,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Tag,
  TenantType,
  Modal,
} from '@maxtropy/components';
import { Col, Row, Space, TableColumnsType } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { RangePickerProps } from 'antd/es/date-picker';
import {
  AbNormalReadStateColor,
  AbNormalReadStateDisplay,
  AbnormalDiagnosis,
  AlarmLevel,
  AlarmLevelColorDisplay,
  AlarmLevelDisplay,
  AlarmState,
  AlarmStateDisplay,
  AlarmStateTagColor,
  AssetsAlarmPageRes,
  AssetsAlarmParams,
  AssetsItems,
  ReadStateEnum,
  assetsItemsDisplay,
} from '@/api/alarm';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
import RecordPagination from '@/components/RecordPagination';
import CustomInfoBoxGroup from './CustomInfoBoxGroup';
import { getOuIdsByDeviceId } from '@/api/device';
import {
  apiV2AlarmFaultSignboardAlarmAssetPost,
  apiV2AlarmFaultSignboardAlarmPagePost,
  apiV2AlarmFaultSignboardAllAlarmPost,
  apiV2AlarmFaultSignboardGetTenantListPost,
  apiV2AlarmFaultSignboardReadAlarmPost,
  apiV2AlarmFaultSignboardRecoveryPost,
} from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash';

const { RangePicker } = DatePicker;
const objIsEmpty = (obj: object) => Reflect.ownKeys(obj).length === 0 && obj.constructor === Object;

export default function AssetsAlarmOverview() {
  const routesContext = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm<AssetsAlarmParams>();
  const [update, setUpdate] = useUpdate();
  // 带资产编号参数跳转, 清空时, 删除地址栏中的参数
  // &assetCode=121
  const [searchParams, setSearchParams] = useSearchParams();
  const staffId = useCurrent()?.staff?.id;

  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;

  const [isSortValue, setIsSortValue] = useState<boolean>(false); // 是否传sort value
  const [currentPage, setCurrentPage] = useState<number>(1); // 当前页数
  const [pageSize, setPageSize] = useState<number>(50); // 每页多少条
  const [sortValues, setSortValues] = useState<any[]>();
  const urlExpand = searchParams.get('expand');
  const [expand, setExpand] = useState<boolean>(urlExpand === 'true');
  const [list, setList] = useState<any[]>([]);
  const hasCreatePermission = useHasPermission(PermissionsType.P_ASSET_KANBAN_CREATE_ORDER);
  const hasSubPermission = useHasPermission(PermissionsType.B_SUBMIT_ORDER);
  const savedParams = JSON.parse(sessionStorage.getItem(`assetsAlarmParams${staffId}`) || '{}');
  const urlStartTime = Number(searchParams.get('startTime'));
  const urlEndTime = Number(searchParams.get('endTime'));

  const urlParams = {
    state: searchParams.getAll('state').map(item => +item),
    level: searchParams.getAll('level').map(item => +item),
    readStatus: searchParams.getAll('readStatus').map(item => +item),
    abnormalDiagnosis: searchParams.getAll('abnormalDiagnosis').map(item => +item),
    assetType: searchParams.getAll('assetType').map(item => +item),
    deviceCode: searchParams.get('deviceCode') || '',
    deviceName: searchParams.get('deviceName') || '',
    ruleCode: searchParams.get('ruleCode') || '',
    ruleName: searchParams.get('ruleName') || '',
    assetCode: searchParams.getAll('assetCode').filter(item => !!item),
    times: urlStartTime && urlEndTime ? [urlStartTime, urlEndTime] : [],
    tenantMcids: searchParams.getAll('tenantMcids').map(item => item),
  };

  //先定义两个空的数组，用于存放选中的id和数据
  const [checkedData, setCheckedData] = useState<any>([]); // 选中的数据
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);

  const getDefault = (key: keyof typeof urlParams, defaultValue: any) =>
    urlParams[key].length ? urlParams[key] : savedParams[key] || defaultValue;

  const defaultParams = useRef<AssetsAlarmParams>({
    state: getDefault('state', [AlarmState.UNRECOVERED]),
    readStatus: getDefault('readStatus', [ReadStateEnum.UNREAD]),
    abnormalDiagnosis: getDefault('abnormalDiagnosis', [AbnormalDiagnosis.ABNORMAL]).includes(NaN)
      ? undefined
      : getDefault('abnormalDiagnosis', [AbnormalDiagnosis.ABNORMAL]),
    assetType: getDefault('assetType', [AssetsItems.PV, AssetsItems.STORAGE, AssetsItems.POWERSTATION]),
    level: getDefault('level', [
      AlarmLevel.HIGHEST,
      AlarmLevel.HIGH,
      AlarmLevel.MIDDLE,
      AlarmLevel.LOW,
      AlarmLevel.LOWEST,
    ]),
    deviceCode: getDefault('deviceCode', ''),
    deviceName: getDefault('deviceName', ''),
    ruleCode: getDefault('ruleCode', ''),
    ruleName: getDefault('ruleName', ''),
    assetCode: getDefault('assetCode', []),
    times: getDefault('times', []).map((item: string | number) => dayjs(item)),
    tenantMcids: getDefault('tenantMcids', []),
  });

  console.log('mmm', defaultParams);

  const { data: alarmPageInfoData } = useRequest(apiV2AlarmFaultSignboardAllAlarmPost, {
    pollingInterval: 1000 * 60,
    refreshDeps: [currentPage, pageSize],
  });

  // 查询所属资产下拉列表
  const { data: assetsSelectList, loading } = useRequest(() =>
    apiV2AlarmFaultSignboardAlarmAssetPost({}).then(res => {
      return (res.list ?? []).map(item => ({ label: item.assetName, value: item.assetCode, id: item.assetCode }));
    })
  );

  // 渠道端租户列表
  const { data: tenantList } = useRequest(
    async () => {
      const res = await apiV2AlarmFaultSignboardGetTenantListPost();
      return res.list ?? [];
    },
    { ready: isChannel }
  );

  // 表单onchange后获取最新值
  const currentFormValues = useMemo(() => {
    let params = form.getFieldsValue();
    if (objIsEmpty(params)) {
      params = defaultParams.current;
    }
    if (params.times?.[0]) {
      params.alarmStartTime = dayjs(params.times[0]).startOf('day').valueOf();
    }
    if (params.times?.[1]) {
      params.alarmEndTime = dayjs(params.times[1]).endOf('day').valueOf();
    }

    sessionStorage.setItem(`assetsAlarmParams${staffId}`, JSON.stringify(params));
    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, form]);

  // 分页列表及报警数量
  const { data: pageAndAlarmInfo } = useRequest(
    async () => {
      console.log('999', currentFormValues);
      return apiV2AlarmFaultSignboardAlarmPagePost({
        ...currentFormValues,
        abnormalDiagnosis: isNil(currentFormValues.readStatus?.[0]) ? undefined : 4,
        sortValues: isSortValue ? sortValues : undefined,
        page: currentPage,
        size: pageSize,
      }).then(res => {
        if (res.useSearchAfter) {
          setSortValues(res.sortValues);
          setIsSortValue(true);
        } else {
          setSortValues(undefined);
          setIsSortValue(false);
          // res的list里面添加一个flag, 用于判断是否是搜索后的数据
          res.list = res.list?.map(item => ({ ...item, flag: undefined })) ?? [];
          setList(res.list);
        }
        return res;
      });
    },
    {
      pollingInterval: 1000 * 60,
      refreshDeps: [currentFormValues, currentPage, pageSize],
    }
  );

  const totalCount = useMemo(() => {
    return pageAndAlarmInfo?.total;
  }, [pageAndAlarmInfo]);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
  };

  // 当网页刷新的时候删除地址栏中的参数
  useEffect(() => {
    if (searchParams.toString()) {
      setSearchParams('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 点击发起工单时, 用设备id去查下ouId
  const goHref = (id: number, alarmId: number, sceneType?: number) => {
    getOuIdsByDeviceId(id).then(res => {
      let ids = res.map(item => item.id);
      window.open(
        `${window.ALARMWORKORDER}/workOrder/repairWorkOrder/workOrderList/workOrderAdd?${qs.stringify(
          {
            problemSource: 10,
            operationUnitCode: (ids ?? []).join(','),
            alarmId,
            alarmTypeCode: sceneType ?? -1,
          },
          { indices: false }
        )}`,
        '_self'
      );
    });
  };
  const columns: TableColumnsType<AssetsAlarmPageRes> = [
    {
      title: '所属资产',
      dataIndex: 'assetName',
      ellipsis: { showTitle: true },
      fixed: 'left' as const,
      render: (_v: string, record: AssetsAlarmPageRes) => (
        <EllipsisSpan value={record.deviceAssets.map(i => i.deviceAssetCode).join()} />
      ),
    },
    {
      title: '报警等级',
      dataIndex: 'level',
      ellipsis: { showTitle: true },
      render: (v: AlarmLevel) => (
        <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
          {AlarmLevelDisplay[v]}
        </Tag>
      ),
    },
    {
      title: '报警信息',
      dataIndex: 'alarmMsg',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报警状态',
      dataIndex: 'state',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: AlarmState, record: AssetsAlarmPageRes) => {
        if (record.sceneType === AbnormalDiagnosis.ABNORMAL) {
          return (
            <Tag border="solid" color={AbNormalReadStateColor[record.readState]}>
              {AbNormalReadStateDisplay[record.readState]}
            </Tag>
          );
        } else {
          return (
            <Tag border="solid" color={AlarmStateTagColor[v]}>
              {AlarmStateDisplay[v]}
            </Tag>
          );
        }
      },
    },
    {
      title: '规则编号',
      dataIndex: 'ruleCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '规则名称',
      dataIndex: 'ruleName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报警时间',
      dataIndex: 'alarmTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '恢复时间',
      dataIndex: 'recoveryTime',
      ellipsis: { showTitle: true },
      render: (v: string, record: AssetsAlarmPageRes) =>
        record.sceneType === AbnormalDiagnosis.ABNORMAL ? (
          '--'
        ) : (
          <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />
        ),
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: !isChannel ? 300 : 80,
      render: (_v: any, record) => {
        const isAbnormal = record.sceneType === AbnormalDiagnosis.ABNORMAL;

        return (
          <Space size={16}>
            {!isAbnormal && hasCreatePermission && !isChannel && (
              <Button
                type="link"
                onClick={() =>
                  (window.location.href = `${window.IOTPLATFORMORIGIN}/device/alarm/record/${record.alarmId}/create`)
                }
              >
                创建工单
              </Button>
            )}
            {hasSubPermission && !isChannel && (
              <Button type="link" onClick={() => goHref(record.deviceId, record.alarmId, record.sceneType)}>
                发起工单
              </Button>
            )}

            {!isChannel && (
              <Button
                type="link"
                onClick={() =>
                  (window.location.href = `${window.IOTPLATFORMORIGIN}/device/manage/device/${record.deviceId}/detail`)
                }
              >
                查看设备
              </Button>
            )}

            <Button
              type="link"
              onClick={() => {
                if (isAbnormal) {
                  navigate(`/assets/alarm/overview/detail/${record.alarmId}`);
                } else {
                  window.location.href = `${window.IOTPLATFORMORIGIN}/device/alarm/record/detail/${record.alarmId}`;
                }
              }}
            >
              查看
            </Button>

            {isAbnormal && !isChannel && (
              <Button
                type="link"
                disabled={(record as any).flag ?? record?.readState === ReadStateEnum.READ}
                onClick={() => {
                  apiV2AlarmFaultSignboardReadAlarmPost({ id: String(record?.alarmId) }).then(_res => {
                    const index = list.findIndex(item => item.alarmId === record.alarmId);
                    setList(list => {
                      const newList = [...list];
                      newList.splice(index, 1, {
                        ...list[index],
                        flag: true,
                      });
                      return newList;
                    });
                  });
                }}
              >
                {(record as any).flag ?? record?.readState === ReadStateEnum.READ ? '已解除' : '解除报警'}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const allColumns = !isChannel
    ? columns
    : [
        {
          title: '租户',
          dataIndex: 'tenantName',
          ellipsis: { showTitle: true },
          fixed: 'left' as const,
          render: (v: string) => <EllipsisSpan value={v} />,
        },
        ...columns,
      ];

  // 手动选择/取消选择某行
  const tableSelect = (record: any, selected: any) => {
    //如果选中某一行，把选中的这行数据及id分别存到checkedData、selectedKeys中
    if (selected) {
      const arr = checkedData.map((item: any) => item.alarmId);
      setSelectedKeys([...arr, record.alarmId]);
      setCheckedData([...checkedData, record]);
      //取消选中，则在checkedData、selectedKeys中过滤掉这条数据
    } else {
      const newData = checkedData.filter((item: any) => item.alarmId !== record.alarmId);
      setCheckedData(newData);
      const arr = newData.map((item: any) => {
        return item.alarmId;
      });
      setSelectedKeys([...arr]);
    }
  };
  // 表格全选/取消全选
  const tableSelectAll = (selected: boolean, selectedRows: any, changeRows: any) => {
    if (!selected) {
      changeRows.forEach((row: any) => {
        const newData = checkedData.filter((item: any) => item.alarmId !== row.alarmId);
        setCheckedData(newData);
      });
      const arr = changeRows.map((item: any) => item.alarmId);
      const newArr = selectedKeys.filter((item: any) => !arr.some((ele: any) => ele === item));
      setSelectedKeys([...newArr]);
    }
    //全选，把
    else {
      const arr = changeRows.map((item: any) => item.alarmId);
      setCheckedData([...checkedData, ...changeRows]);
      setSelectedKeys([...selectedKeys, ...arr]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys, //展示选中的数据
    onSelect: tableSelect, //单条数据取消/选中的回调
    onSelectAll: tableSelectAll, //全选/取消全选的回调
  };

  const adjustRestore = () => {
    Modal.confirm({
      title: `确定解除报警？`,
      onOk: () => {
        apiV2AlarmFaultSignboardRecoveryPost({
          alarmInfo: checkedData.map((m: any) => ({
            id: m.alarmId,
            sceneType: m.sceneType,
          })),
        }).then(_ => {
          setSelectedKeys([]);
          setCheckedData([]);
          setUpdate();
        });
      },
    });
  };

  return (
    <div className="page">
      <div className="page__header">
        <Breadcrumb routes={routesContext?.routes ?? []} />
      </div>
      <BorderWrapper>
        <CustomInfoBoxGroup alarmPageInfo={alarmPageInfoData} />
        <Form isOrigin form={form} initialValues={defaultParams.current} className={styles.form}>
          <div className="page__filter">
            <div className={styles.div_positon}>
              <Row style={{ paddingRight: 80 }} gutter={30}>
                <Col span={8}>
                  <Form.Item name="level" label="报警等级">
                    <Checkbox.Group onChange={setUpdate}>
                      {Object.entries(AlarmLevelDisplay).map(([k, v]) => (
                        <Checkbox key={k} value={+k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={8} className={styles.colspan}>
                  <Form.Item name="state" label="报警状态" style={{ marginRight: '16px' }}>
                    <Checkbox.Group onChange={setUpdate}>
                      <Checkbox value={AlarmState.UNRECOVERED}>{AlarmStateDisplay[AlarmState.UNRECOVERED]}</Checkbox>
                      <Checkbox value={AlarmState.RECOVER}>{AlarmStateDisplay[AlarmState.RECOVER]}</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item name="readStatus">
                    <Checkbox.Group onChange={setUpdate}>
                      <Checkbox value={ReadStateEnum.READ}>{AbNormalReadStateDisplay[ReadStateEnum.READ]}</Checkbox>
                      <Checkbox value={ReadStateEnum.UNREAD}>{AbNormalReadStateDisplay[ReadStateEnum.UNREAD]}</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="报警规则名称" name="ruleName">
                    <Input placeholder="请输入" onBlur={setUpdate} />
                  </Form.Item>
                </Col>
              </Row>

              <>
                <Row gutter={30} style={{ paddingRight: 80 }}>
                  <Col span={8} className={!expand ? styles.display_none : ''}>
                    <Form.Item label="报警信息" name="alarmMsg">
                      <Input placeholder="请输入" onBlur={setUpdate} />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={!expand ? styles.display_none : ''}>
                    <Form.Item label="报警规则编号" name="ruleCode">
                      <Input placeholder="请输入" onBlur={setUpdate} />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={!expand ? styles.display_none : ''}>
                    <Form.Item name="assetCode" label="所属资产">
                      <Select
                        onChange={(_value: string[] | null) => {
                          setUpdate();
                        }}
                        placeholder="请输入并选择"
                        mode="multiple"
                        allowClear
                        key="id"
                        optionFilterProp="label"
                        options={assetsSelectList}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} className={!expand ? styles.display_none : ''}>
                    <Form.Item name="assetType" label="资产类型">
                      <Checkbox.Group onChange={setUpdate}>
                        {Object.entries(assetsItemsDisplay)
                          .reverse()
                          .map(([k, v]) => (
                            <Checkbox key={k} value={+k}>
                              {v}
                            </Checkbox>
                          ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  <Col span={8} className={!expand ? styles.display_none : ''}>
                    <Form.Item name="deviceCode" label="设备编号" rules={[{ max: 30 }]}>
                      <Input placeholder="请输入" onBlur={setUpdate} />
                    </Form.Item>
                  </Col>
                  <Col span={8} className={!expand ? styles.display_none : ''}>
                    <Form.Item name="times" label="报警时间">
                      <RangePicker onChange={setUpdate} disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>

                  <Col span={8} className={!expand ? styles.display_none : ''}>
                    <Form.Item name="deviceName" label="设备名称" rules={[{ max: 30 }]}>
                      <Input placeholder="请输入" onBlur={setUpdate} />
                    </Form.Item>
                  </Col>

                  {isChannel && (
                    <Col span={8} className={!expand ? styles.display_none : ''}>
                      <Form.Item name="tenantMcids" label="所属租户">
                        <Select
                          mode="multiple"
                          options={(tenantList ?? []).map(item => {
                            return {
                              label: item.tenantName,
                              value: item.tenantMcid,
                            };
                          })}
                          onChange={setUpdate}
                        ></Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </>

              <div
                className={styles.expend}
                style={
                  expand
                    ? {
                        bottom: 24,
                      }
                    : { top: 0 }
                }
              >
                <Button
                  type="link"
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  {expand ? '收起' : '展开'}
                  <DownOutlined rotate={expand ? 180 : 360} />
                </Button>
              </div>
            </div>
          </div>
        </Form>
        <div className={`page__content ${styles.content}`}>
          <Button
            type="primary"
            wrapStyle={{ marginBottom: 10 }}
            disabled={!selectedKeys.length}
            onClick={adjustRestore}
          >
            解除报警
          </Button>
          <div className={styles.alarmTable}>
            <Table
              rowKey="alarmId"
              sticky
              scroll={{ x: 1300, y: 'calc(100vh - 450px)' }}
              loading={loading}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={allColumns}
              dataSource={list ?? []}
            />
            {/* <Paging pagingInfo={pagingInfo} /> */}
            {totalCount ? (
              <RecordPagination
                totalCount={totalCount}
                setPageSize={setPageSize}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setIsSortValue={setIsSortValue}
              />
            ) : null}
          </div>
        </div>
      </BorderWrapper>
    </div>
  );
}
