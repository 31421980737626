import { isNil } from 'lodash-es';

export const keepTwoOrLinePercent = (data?: number) => {
  if (!isNil(data)) {
    return (data * 100).toFixed(2) + '%';
  }
  return '--';
};

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return data.toFixed(2);
  }
  return '--';
};
export const keepTwoOrLineP = (data?: number) => {
  if (!isNil(data)) {
    return data.toFixed(2) + '%';
  }
  return '--';
};

export enum OperationType {
  NEW = 1,
  EDIT,
  DEL,
}

export const OperationTypeDisplay = {
  [OperationType.NEW]: '新增',
  [OperationType.EDIT]: '编辑',
  [OperationType.DEL]: '删除',
};

export const OperationTypeColorDisplay = {
  [OperationType.NEW]: '#52C41A',
  [OperationType.EDIT]: '#4A90E2',
  [OperationType.DEL]: '#FAAD14',
};
