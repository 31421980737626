import { fetch } from '@maxtropy/components';

export interface Ou {
  id: number;
  name: string;
  ouTypeId: number;
  ouTypeName: string;
  userType: number;
}

export const fetchOuList = (type: number) =>
  fetch<{ list: Ou[] }>(
    `/api/v2/ou/list`,
    {
      method: 'POST',
      body: JSON.stringify({ type }),
    },
    true
  ).then(res => res.list ?? []);

export interface OuList {
  id: number;
  key: string;
  name: string;
}

export const getOuId = () =>
  fetch<{ list: OuList[] }>(
    `/api/v2/ou/type/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);

export const getOuListByMcid = (mcid: string, type: number) =>
  fetch<{ list: Ou[] }>(
    `/api/v2/ou/list-by-mcid`,
    {
      method: 'POST',
      body: JSON.stringify({ type, mcid }),
    },
    true
  ).then(res => res.list ?? []);
