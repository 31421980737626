import { Button, DatePicker, Empty, FormTitle, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Collapse, CollapseProps, Layout } from 'antd';
import styles from './index.module.scss';
import React, { Key, useState } from 'react';
import DragResize from '@/components/DragResize';
import CircuitList from './components/CircuitList';
import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import TotalEnergySavings from './components/TotalEnergySavings';
import {
  apiV2EnergyConservationEnergyCostSavingPiePost,
  apiV2EnergyConservationEnergySavingPiePost,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import EnergySavingPie from './components/EnergySavingPie';
import CostEnergySavings from './components/CostEnergySavings';
import CostEnergySavingPie from './components/CostEnergySavingPie';
import BeforeAndAfterRenovation from './components/BeforeAndAfterRenovation';
import EnergySavingAmount from './components/EnergySavingAmount';
import qs from 'qs';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';

const { Content, Sider } = Layout;

const EnergySavingRate = () => {
  const routesContext = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [circuitId, setCircuitId] = useState<Key>(); // 选择的回路id
  const [date, setDate] = useState<Dayjs>(dayjs().subtract(1, 'month'));
  const hasBatchConfigBtn = useHasPermission(PermissionsType.B_ENERGYSAVINGBATCHCONFIGURATION);

  // 节能总量
  const { data: energySavingData, loading: energyLoading } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationEnergySavingPiePost({
        time: dayjs(date.format('YYYY-MM')).valueOf(),
        circuitId: circuitId,
      });
      return res;
    },
    {
      ready: !!circuitId && !!date,
      refreshDeps: [circuitId, date],
    }
  );

  // 节能成本
  const { data: costSavingData, loading: costLoading } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationEnergyCostSavingPiePost({
        time: dayjs(date.format('YYYY-MM')).valueOf(),
        circuitId: circuitId,
      });
      return res;
    },
    {
      ready: !!circuitId && !!date,
      refreshDeps: [circuitId, date],
    }
  );

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const onDateChange: DatePickerProps['onChange'] = date => {
    if (date) {
      setDate(date);
    }
  };

  const savingTotalItems: CollapseProps['items'] = [
    {
      key: '1',
      label: <TotalEnergySavings energySavingData={energySavingData} />,
      children: <EnergySavingPie energySavingData={energySavingData} loading={energyLoading} />,
    },
  ];

  const savingCostItems: CollapseProps['items'] = [
    {
      key: '1',
      label: <CostEnergySavings costSavingData={costSavingData} />,
      children: <CostEnergySavingPie costSavingData={costSavingData} loading={costLoading} />,
    },
  ];

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: 'var(--mx-module-bg-color)' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <CircuitList
            style={{ width: '100%' }}
            changeListSelect={(key?: Key) => setCircuitId(key)}
            circuitId={circuitId}
          />
        </Sider>

        <Content className={styles.content}>
          {true ? (
            <>
              <div
                className={styles.toggleBtn}
                style={{ left: openSider ? -30 : 0 }}
                onClick={() => setOpenSider(!openSider)}
              >
                {openSider ? <LeftOutlined /> : <RightOutlined />}
              </div>
              <FormTitle
                title={
                  <>
                    <span style={{ marginRight: 16 }}>基本信息</span>
                    {hasBatchConfigBtn && (
                      <Button type="primary" onClick={() => window.open('/energySaving/batch/configList')}>
                        批量配置
                      </Button>
                    )}
                  </>
                }
                extraContent={
                  <div>
                    <DatePicker
                      style={{ marginRight: 16, width: 160 }}
                      allowClear={false}
                      disabledDate={disabledDate}
                      onChange={onDateChange}
                      picker="month"
                      value={date}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        const query = {
                          circuitId,
                          time: dayjs(date.format('YYYY-MM')).valueOf(),
                        };
                        window.open(`/api/v2/energy-conservation/export?${qs.stringify(query)}`, '_blank');
                      }}
                    >
                      导出
                    </Button>
                  </div>
                }
              />
              <div className={styles.top_box}>
                <Collapse
                  style={{ flex: 41 }}
                  items={savingTotalItems}
                  collapsible="icon"
                  className={styles.CollapseStyle}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <span className={styles.coll_text_sty}>
                        收起
                        <UpOutlined style={{ marginLeft: 4 }} />
                      </span>
                    ) : (
                      <span className={styles.coll_text_sty}>
                        展开
                        <DownOutlined style={{ marginLeft: 4 }} />
                      </span>
                    )
                  }
                />
                <Collapse
                  style={{ flex: 63 }}
                  collapsible="icon"
                  className={styles.CollapseStyle}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <span className={styles.coll_text_sty}>
                        收起
                        <UpOutlined style={{ marginLeft: 4 }} />
                      </span>
                    ) : (
                      <span className={styles.coll_text_sty}>
                        展开
                        <DownOutlined style={{ marginLeft: 4 }} />
                      </span>
                    )
                  }
                  items={savingCostItems}
                />
              </div>
            </>
          ) : (
            <Empty description={'暂无数据'} className={styles.empty} />
          )}
          {/* 改造前后line */}
          <BeforeAndAfterRenovation circuitId={circuitId} time={date} />
          {/* 节能量bar */}
          <EnergySavingAmount circuitId={circuitId} time={date} />
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default EnergySavingRate;
