import { DatePickerTypeDisplay, StatisticsPartition } from '@/api/circuit';
import { DataNameType, DataType } from '@/api/const';
import { Radio } from '@maxtropy/components';

// 电量
// export const getHisEPChart = ({ chartData = [], query, unit, ePriceTimes }: ParamsOption) => {
//   const option = getChartOption({
//     unit,
//     query,
//     ePriceTimes,
//   });

//   const { name } = query;
//   const colors = palette[query.dataType];

//   // 判断是否有尖峰平谷，还是选择的日期内有没有尖峰平谷的，也有有尖峰平谷的
//   let hasNoneRes: boolean = judgeEPHasNone(chartData, query, ePriceTimes);

//   return {
//     ...option,
//     legend: getLegend({
//       show: !ePriceTimes,
//       name,
//       colors,
//       chartData,
//     }),
//     visualMap: ePriceTimes
//       ? {
//           show: true,
//           right: 20,
//           top: 50,
//           orient: 'horizontal',
//           itemSymbol: 'rect',
//           textStyle: {
//             color: '#AFBCC4',
//           },
//           formatter: (value: EPricePeakTypeEP | EPricePeakType) => {
//             console.log(value);
//             return hasNoneRes
//               ? `${E_PRICE_PEAK_TYPE_LABEL_MAP_EP[value]}`
//               : `${E_PRICE_PEAK_TYPE_LABEL_MAP[value as EPricePeakType]}电量`;
//           },
//           categories: hasNoneRes
//             ? Object.keys(E_PRICE_PEAK_TYPE_COLORS_MAP_EP)
//             : Object.keys(E_PRICE_PEAK_TYPE_COLORS_MAP),
//           inRange: {
//             color: hasNoneRes
//               ? Object.values(E_PRICE_PEAK_TYPE_COLORS_MAP_EP)
//               : Object.values(E_PRICE_PEAK_TYPE_COLORS_MAP),
//           },
//         }
//       : undefined,
//     series: [
//       ...chartData.map((item, index) => ({
//         type: 'bar',
//         color: colors[index],
//         name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
//         data: item.data.map((a, index) => {
//           const type = ePriceTimes?.find(
//             e =>
//               index >= (query.btnType === DatePickerType.ONEMINUTE ? e.intervalStart * 30 : e.intervalStart * 2) &&
//               index <= (query.btnType === DatePickerType.ONEMINUTE ? e.intervalEnd * 30 + 29 : e.intervalEnd * 2 + 1)
//           )?.type;
//           return [a.time, a.value, type ?? EPricePeakTypeEP.NONE];
//         }),
//         markArea: {
//           data: ePriceTimes ? getMarkAreaData(ePriceTimes, query) : [],
//         },
//       })),
//     ],
//   };
// };

// 根据不同指标渲染不同颗粒度
export const circuitBtnsByHistory = [
  {
    id: DataType.EP,
    name: DataNameType.EP,
    btnName: '电量',
    unit: 'kWh',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
        <Radio value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio>
      </Radio.Group>
    ),
    // getHisChartOption: getHisEPChart,
  },
  {
    id: DataType.PDMD,
    name: DataNameType.PDMD,
    btnName: '最大需量',
    unit: 'kW',
    defaultTimeResolution: StatisticsPartition.DAY,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.LOAD_RATE,
    name: DataNameType.LOAD_RATE,
    btnName: '负载率',
    unit: '%',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.COSQ,
    name: DataNameType.COSQ,
    btnName: '功率因数',
    unit: '',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
        <Radio value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.P,
    name: DataNameType.P,
    btnName: '有功功率',
    unit: 'kW',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
        <Radio value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.U,
    name: [DataNameType.UA, DataNameType.UB, DataNameType.UC],
    btnName: '相电压',
    unit: 'V',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },

  {
    id: DataType.LINE_VOLTAGE,
    name: [DataNameType.UAB, DataNameType.UBC, DataNameType.UCA],
    btnName: '线电压',
    unit: 'V',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.I,
    name: [DataNameType.IA, DataNameType.IB, DataNameType.IC],
    btnName: '电流',
    unit: 'A',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.T,
    name: [DataNameType.TA, DataNameType.TB, DataNameType.TC],
    btnName: '线缆温度',
    unit: '℃',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.PE,
    name: [DataNameType.FPE, DataNameType.BPE],
    btnName: '有功电能',
    unit: 'kWh',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
        <Radio value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.F,
    btnName: '频率',
    name: DataNameType.F,
    unit: 'Hz',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.UNBALANCED_V,
    name: DataNameType.UNBALANCED_V,
    btnName: '三相电压不平衡度',
    unit: '%',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.UNBALANCED_A,
    name: DataNameType.UNBALANCED_A,
    btnName: '三相电流不平衡度',
    unit: '%',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.RESIDUAL_A,
    name: DataNameType.RESIDUAL_A,
    btnName: '剩余电流',
    unit: 'A',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.CURRENT_H,
    name: [DataNameType.CH_A, DataNameType.CH_B, DataNameType.CH_C],
    btnName: '电流谐波',
    unit: '%',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
  {
    id: DataType.VOLTAGE_H,
    name: [DataNameType.VH_A, DataNameType.VH_B, DataNameType.VH_C],
    btnName: '电压谐波',
    unit: '%',
    defaultTimeResolution: StatisticsPartition.MINUTE_15,
    render: (
      <Radio.Group>
        <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
      </Radio.Group>
    ),
  },
];
