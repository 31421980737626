import React, { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from '../index.module.scss';
import {
  CheckOutlined,
  SearchOutlined,
  StopOutlined,
  AppstoreOutlined,
  DisconnectOutlined,
  EditOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { App, Badge, Col, Modal, Row, Space, Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { fetchLocalDetail, LocalCalendarProps, LocalDetailProps, changeStatus } from '../../../api/strategy';
import { LocalCalendarStatus, localCalendarStatusColor, localCalendarStatusFormat } from '../../../api/type';
import { StrategyOverview, VersionType } from '@maxtropy/strategy';
import { Link } from 'react-router-dom';
import qs from 'qs';

interface DateCellRenderProps {
  val: Dayjs;
  dataSource: LocalCalendarProps[];
  selectDate: string[];
  onSelectDate: (v: string[]) => void;
  versionColorDisc?: { [key: number]: string };
  setExistingStrategyNumber?: (v: number) => void;
  isForm?: boolean;
  bsaId?: number;
  forceUpdate: () => void;
  selectMonth: Dayjs;
}

const DateCellRender: React.FC<DateCellRenderProps> = ({
  val,
  dataSource,
  selectDate,
  onSelectDate,
  versionColorDisc,
  setExistingStrategyNumber,
  isForm,
  bsaId,
  forceUpdate,
  selectMonth,
}) => {
  const date = dayjs(val).format('YYYY-MM-DD');
  const [detail, setDetail] = useState<LocalDetailProps>();
  const [invalidVisible, setInvalidVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const query: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const copyDate = query.date;
  const { modal } = App.useApp();

  const list: LocalCalendarProps | undefined = useMemo(() => {
    return dataSource.find(i => dayjs(i.day).isSame(val, 'day'));
  }, [val, dataSource]);
  const disabled =
    (isForm
      ? dayjs(val).startOf('d') < dayjs().startOf('d')
      : dayjs(val).startOf('d') < dayjs().startOf('d') || !list) ||
    dayjs(val).format('YYYY-MM') !== dayjs(selectMonth).format('YYYY-MM') ||
    date === copyDate;

  const isActive = selectDate.includes(date);

  const onClickAction = () => {
    if (!disabled) {
      if (selectDate.includes(date)) {
        onSelectDate(selectDate.filter(i => i !== date));
        if (list?.name && setExistingStrategyNumber) setExistingStrategyNumber(-1);
      } else {
        onSelectDate([...selectDate, date]);
        if (list?.name && setExistingStrategyNumber) setExistingStrategyNumber(1);
      }
    }
  };

  const strategyOverview = (e: any) => {
    e.preventDefault();
    fetchLocalDetail(list!.id!.toString(), bsaId!.toString()).then(res => {
      setDetail(res);
    });
  };

  const changeStatusAction = (status: LocalCalendarStatus, e: any) => {
    e.preventDefault();
    setLoading(true);
    changeStatus(bsaId!, {
      strategyIds: [list!.id],
      status: status,
      version: list!.version,
      globalVersion: list!.globalVersion,
    })
      .onError(err => {
        const { errorMessage } = err?.cause;
        modal.error({
          title: errorMessage,
        });
        throw err;
      })
      .then(res => {
        if (res) forceUpdate();
      })
      .finally(() => {
        setLoading(false);
        setInvalidVisible(false);
      });
  };

  return (
    <div
      className={classNames(isActive ? styles.active : '', styles.box, disabled ? styles.disabled : '')}
      style={
        list
          ? { borderTop: `3px solid ${versionColorDisc![list.globalVersion]}`, background: 'rgba(0, 0, 0, 0.3)' }
          : undefined
      }
      onClick={onClickAction}
    >
      <Row>
        <Col span={18}>
          {list && versionColorDisc && (
            <>
              <Badge color={versionColorDisc[list.globalVersion]} />
              <span style={{ color: versionColorDisc[list.globalVersion] }} className={styles.tit}>
                {list.name}
              </span>
              <p style={{ height: 20 }}>{list.code}</p>
              <p>
                全局版本号: {list.globalVersion}
                {typeof list?.status === 'number' && (
                  <Tag color={localCalendarStatusColor[list.status]} style={{ marginLeft: 10 }}>
                    {localCalendarStatusFormat[list.status]}
                  </Tag>
                )}
              </p>
            </>
          )}
        </Col>
        <Col span={2} offset={1}>
          <p className={styles.date}>{dayjs(val).format('DD')}</p>
        </Col>
        <Col span={2} offset={1}>
          {isActive && <CheckOutlined className={styles.icon} />}
        </Col>
      </Row>
      <>
        {!disabled && (
          <>
            <Space className={styles.btns} style={{ right: 22 }} size={2}>
              {!isForm && (
                <>
                  <Link to={`/dmes/energy-storage/strategy/field/${list?.id}/edit?bsaId=${bsaId}`}>
                    <Tooltip title="编辑">
                      <EditOutlined />
                    </Tooltip>
                  </Link>
                  {list?.status !== LocalCalendarStatus.OUTAGE && (
                    <Tooltip title="停运">
                      <StopOutlined onClick={e => changeStatusAction(LocalCalendarStatus.OUTAGE, e)} />
                    </Tooltip>
                  )}
                  {list?.status === LocalCalendarStatus.OUTAGE && (
                    <Tooltip title="投运">
                      <SendOutlined onClick={e => changeStatusAction(LocalCalendarStatus.COMMISSIONING, e)} />
                    </Tooltip>
                  )}
                  {list?.status !== LocalCalendarStatus.INVALID && (
                    <Tooltip title="解除">
                      <DisconnectOutlined onClick={() => setInvalidVisible(true)} />
                    </Tooltip>
                  )}
                </>
              )}
              {list && (
                <Tooltip title="概览">
                  <AppstoreOutlined onClick={e => strategyOverview(e)} />
                </Tooltip>
              )}
            </Space>
          </>
        )}
        {list && (
          <div className={styles.btns}>
            <Link
              to={`/dmes/energy-storage/strategy/field/${list?.id}?bsaId=${bsaId}${disabled ? '&expired=true' : ''}`}
              target="_blank"
            >
              <Tooltip title="查看">
                <SearchOutlined />
              </Tooltip>
            </Link>
          </div>
        )}
      </>
      <Modal
        open={invalidVisible}
        title="解除当日设置的策略"
        okText="确定"
        cancelText="我再检查一下"
        confirmLoading={loading}
        onCancel={() => setInvalidVisible(false)}
        onOk={e => changeStatusAction(LocalCalendarStatus.INVALID, e)}
      >
        <p>
          将策略名<span>{list?.name}</span>，从{date}解除设置，确定吗？
        </p>
      </Modal>
      {detail && (
        <Modal open={!!detail} title="设定预览" width={1050} footer={null} onCancel={() => setDetail(undefined)}>
          <StrategyOverview version={detail.sysVersionId as VersionType} sectionsData={detail.sections} />
        </Modal>
      )}
    </div>
  );
};

export default DateCellRender;
