import ShowInput from '@/components/ShowInput';
import { Button, Form, FormTitle, SubContent, Wrapper, useBreadcrumbRoutes, useUpdate } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useRequest } from 'ahooks';
import { apiV2PvStationDetailPost } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import StartingTimeModal from '@/pages/SiteAccess/StartingTimeModal';
import styles from './index.module.scss';
import ModeModal, { CalculateWay, CalculateWayDisplay } from './ModeModal';
import LogModal from './LogModal';
import OnlineModal, { OnlineCalculateWay, OnlineCalculateWayDisplay } from './OnlineModal';

export const TIME_FORMAT = 'YYYY-MM-DD';

export enum StationType {
  // 1. 储能 2.光伏 4.充电站
  BSA = 1,
  PV_STATION = 2,
  CHARGING_STATION = 4,
}

export enum SourceType {
  INCOME,
  ONLINE,
}

const routes = [{ name: '站点数据处理设置' }];

interface Props {}
const SiteAccess: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const [state, forceUpdate] = useUpdate();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modeVisible, setModeVisible] = useState(false);

  const [onlineVisible, setOnlineVisible] = useState<boolean>(false);
  const [sourceType, setSourceType] = useState<SourceType>(SourceType.INCOME);

  const [logVisible, setLogVisible] = useState(false);
  const [isShowWarningInfo, setIsShowWarningInfo] = useState<boolean>(false);

  const { data: details } = useRequest(
    () => {
      return apiV2PvStationDetailPost({
        id: +query?.id!,
      });
    },
    {
      ready: !!query?.id,
      refreshDeps: [query?.id, state],
    }
  );

  useEffect(() => {
    if (details?.startCalculateTime) {
      form.setFieldsValue({
        time: dayjs(details.startCalculateTime).format(TIME_FORMAT),
      });
      setIsShowWarningInfo(false);
    } else {
      setIsShowWarningInfo(true);
    }
  }, [details]);

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperPadding}>
        <FormTitle title="站点数据处理设置" />
        <Form form={form}>
          <SubContent title="站点起算时间设置" style={{ marginBottom: 8 }}>
            <Row>
              <Col>
                <Form.Item
                  name="time"
                  label="起算日期"
                  extra={
                    isShowWarningInfo && (
                      <p
                        style={{
                          color: 'var(--error-color)',
                        }}
                      >
                        请先配置站点基础信息
                      </p>
                    )
                  }
                >
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col>
                <Button type="link" disabled={isShowWarningInfo} onClick={() => setModalVisible(true)}>
                  设置时间
                </Button>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="并网点电量来源设置" style={{ marginBottom: 8 }}>
            <Row>
              <Col>
                <Form.Item label="当前模式">
                  {details?.incomeCalculateWay ? CalculateWayDisplay[details.incomeCalculateWay as CalculateWay] : '--'}
                </Form.Item>
              </Col>
              <Col>
                <Space size={8}>
                  <Button style={{ padding: '4px 0 4px 15px' }} type="link" onClick={() => setModeVisible(true)}>
                    切换模式
                  </Button>
                  <Button
                    style={{ padding: '4px 0' }}
                    type="link"
                    onClick={() => {
                      setSourceType(SourceType.INCOME);
                      setLogVisible(true);
                    }}
                  >
                    修改记录
                  </Button>
                </Space>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="上网分时电量来源设置" style={{ marginBottom: 8 }}>
            <Row>
              <Col>
                <Form.Item label="当前模式">
                  {details?.onlineCalculateWay
                    ? OnlineCalculateWayDisplay[details.onlineCalculateWay as OnlineCalculateWay]
                    : '--'}
                </Form.Item>
              </Col>
              <Col>
                <Space size={8}>
                  <Button
                    style={{ padding: '4px 0 4px 15px' }}
                    type="link"
                    onClick={() => {
                      setOnlineVisible(true);
                    }}
                  >
                    切换模式
                  </Button>
                  <Button
                    style={{ padding: '4px 0' }}
                    type="link"
                    onClick={() => {
                      setSourceType(SourceType.ONLINE);
                      setLogVisible(true);
                    }}
                  >
                    修改记录
                  </Button>
                </Space>
              </Col>
            </Row>
          </SubContent>
        </Form>
        <SubContent>
          <Space className="sticky-footer-left">
            <Button
              onClick={() => {
                window.history.go(-1);
              }}
            >
              返回
            </Button>
          </Space>
        </SubContent>
      </Wrapper>

      <StartingTimeModal
        id={+query?.id!}
        type={+query?.type! as StationType}
        open={modalVisible}
        cancel={() => setModalVisible(false)}
        startCalculateTime={details?.startCalculateTime}
        forceUpdate={() => forceUpdate()}
      />
      {onlineVisible && (
        <OnlineModal
          pvStationId={+query?.id!}
          mode={details?.onlineCalculateWay}
          onConfirm={() => {
            forceUpdate();
            setOnlineVisible(false);
          }}
          onCancel={() => setOnlineVisible(false)}
        />
      )}
      {modeVisible && (
        <ModeModal
          pvStationId={+query?.id!}
          mode={details?.incomeCalculateWay}
          onConfirm={() => {
            forceUpdate();
            setModeVisible(false);
          }}
          onCancel={() => setModeVisible(false)}
        />
      )}
      {logVisible && (
        <LogModal
          pvStationId={+query?.id!}
          type={sourceType}
          onConfirm={() => {
            forceUpdate();
            setLogVisible(false);
          }}
          onCancel={() => setLogVisible(false)}
        />
      )}
    </>
  );
};

export default SiteAccess;
