import {
  Button,
  EllipsisSpan,
  FormTitle,
  Paging,
  Table,
  Tag,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
} from '@maxtropy/components';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import {
  apiV2EnergyConservationCircuitEnergyConfigLogPagePost,
  V2EnergyConservationCircuitEnergyConfigLogPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { OperationType, OperationTypeColorDisplay, OperationTypeDisplay } from '../utils';
import dayjs from 'dayjs';
import { Space } from 'antd';
import ShowDetailModal from './ShowDetailModal';

export type ItemProps = Exclude<V2EnergyConservationCircuitEnergyConfigLogPagePostResponse['list'], undefined>[number];

const columns = [
  {
    title: '操作类型',
    dataIndex: 'operationType',
    ellipsis: { showTitle: true },
    render: (v: OperationType) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={OperationTypeColorDisplay[v]}>
            {OperationTypeDisplay[v]}
          </Tag>
        }
      />
    ),
  },
  {
    title: '操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人	',
    dataIndex: 'operator',
    ellipsis: { showTitle: true },
    render: (v?: number) => <EllipsisSpan value={v} />,
  },
];

const ModifyLog = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize } = pagingInfo;
  const routesContext = useBreadcrumbRoutes();
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [showId, setShowId] = useState<number>();

  const routes = [{ name: '修改日志' }];

  // 节能回路配置日志分页
  const { data: configLogList, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationCircuitEnergyConfigLogPagePost({
        energyCircuitId: id,
        page: pageOffset,
        size: pageSize,
      });
      return res?.list;
    },
    {
      ready: !!id && !!pageOffset && !!pageSize,
      refreshDeps: [id, pageOffset, pageSize],
    }
  );

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (record: ItemProps) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              setShowId(record.id);
              setDetailModalVisible(true);
            }}
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={'修改日志'} />
      <div className={styles.table_wrapper}>
        <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={configLogList} />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <ShowDetailModal
        configLogDetail={configLogList?.find(item => item.id === showId)}
        detailModalVisible={detailModalVisible}
        onCancel={() => setDetailModalVisible(false)}
      />
      <Space className="sticky-footer-left">
        <Button onClick={() => navigate(`/energySaving/batch/configList`)}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default ModifyLog;
