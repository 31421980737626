import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import {
  Button,
  EllipsisSpan,
  FormTitle,
  Paging,
  PopConfirm,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import EditConfigModal from './EditConfigModal';
import { useRequest } from 'ahooks';
import {
  apiV2EnergyConservationCircuitEnergyConfigDeletePost,
  apiV2EnergyConservationCircuitEnergyConfigEditPost,
  apiV2EnergyConservationCircuitEnergyConfigPagePost,
  V2EnergyConservationCircuitEnergyConfigPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import { keepTwoOrLineP, keepTwoOrLinePercent, OperationType } from '../utils';
import BatchEditModal from './BatchEditModal';

export type ItemProps = Exclude<V2EnergyConservationCircuitEnergyConfigPagePostResponse['list'], undefined>[number];

const columns = [
  {
    title: '时间',
    dataIndex: 'time',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM') : '--'} />,
  },
  {
    title: '电价信息(元/kWh)',
    ellipsis: { showTitle: true },
    render: (_: string, record: ItemProps) => {
      return (
        <EllipsisSpan
          value={
            <>
              <span className={styles.sharpPrice}>尖({record.sharpPrice?.toFixed(2)})</span>&nbsp;
              <span className={styles.peakPrice}>峰({record.peakPrice?.toFixed(2)})</span>&nbsp;
              <span className={styles.flatPrice}>平({record.flatPrice?.toFixed(2)})</span>&nbsp;
              <span className={styles.valleyPrice}>谷({record.valleyPrice?.toFixed(2)})</span>
            </>
          }
        />
      );
    },
  },
  {
    title: '节能率',
    dataIndex: 'energySavingRate',
    ellipsis: { showTitle: true },
    render: (v?: number) => <EllipsisSpan value={keepTwoOrLineP(v)} />,
  },
  {
    title: '甲方节能效益占比',
    dataIndex: 'benefitRatioA',
    ellipsis: { showTitle: true },
    render: (v?: number) => <EllipsisSpan value={keepTwoOrLineP(v)} />,
  },
  {
    title: '乙方节能效益占比',
    dataIndex: 'benefitRatioB',
    ellipsis: { showTitle: true },
    render: (v?: number) => <EllipsisSpan value={keepTwoOrLineP(v)} />,
  },
];

const Config = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const routesContext = useBreadcrumbRoutes();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize } = pagingInfo;
  const [updateState, updateFn] = useUpdate();
  const [editConfigModalVisible, setEditConfigModalVisible] = useState<boolean>(false); // 编辑
  const [batchEditModalVisible, setBatchEditModalVisible] = useState<boolean>(false); // 批量编辑

  const [editId, setEditId] = useState<number>();
  const isModify = useRef<boolean>(false);

  const routes = [{ name: '配置' }];

  // 节能回路配置分页
  const { data: configList, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationCircuitEnergyConfigPagePost({
        energyCircuitId: id,
        page: pageOffset,
        size: pageSize,
      });
      return res?.list;
    },
    {
      ready: !!id && !!pageOffset && !!pageSize,
      refreshDeps: [id, updateState, pageOffset, pageSize],
    }
  );

  // 删除
  const onDelete = (id?: number) => {
    apiV2EnergyConservationCircuitEnergyConfigDeletePost({ id }).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (record: ItemProps) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              isModify.current = true;
              setEditId(record.id);
              setEditConfigModalVisible(true);
            }}
          >
            编辑
          </Button>
          <PopConfirm title="是否删除该月配置信息？删除后无法恢复。" onConfirm={() => onDelete(record.id)}>
            <Button type="link" danger>
              删除
            </Button>
          </PopConfirm>
        </Space>
      ),
    },
  ];

  const onSubmit = async (values: any) => {
    const params = {
      operationType: isModify.current ? OperationType.EDIT : OperationType.NEW,
      energyCircuitId: id,
      year: dayjs(values.time).format('YYYY'),
      month: editId ? [Number(dayjs(values.time).format('MM'))] : values.month,
      sharpPrice: values.sharpPrice,
      peakPrice: values.peakPrice,
      flatPrice: values.flatPrice,
      valleyPrice: values.valleyPrice,
      energySavingRate: values.energySavingRate,
      benefitRatioA: values.benefitRatioA,
      benefitRatioB: values.benefitRatioB,
    };
    apiV2EnergyConservationCircuitEnergyConfigEditPost({ ...params }).then(_ => {
      setBatchEditModalVisible(false);
      setEditConfigModalVisible(false);
      updateFn();
    });
  };

  return (
    <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={'配置'} />
      <div className={styles.btn_box}>
        <Space size={16}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              isModify.current = false;
              setBatchEditModalVisible(true);
            }}
          >
            新增配置
          </Button>
          <Button
            type="primary"
            onClick={() => {
              isModify.current = true;
              setBatchEditModalVisible(true);
            }}
          >
            批量修改
          </Button>
        </Space>
      </div>
      <div className={styles.table_wrapper}>
        <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={configList} />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <EditConfigModal
        editData={configList?.find(item => item.id === editId)}
        editConfigModalVisible={editConfigModalVisible}
        onCancel={() => setEditConfigModalVisible(false)}
        onSubmit={onSubmit}
      />
      <BatchEditModal
        isModify={isModify.current}
        id={id}
        batchEditModalVisible={batchEditModalVisible}
        onCancel={() => setBatchEditModalVisible(false)}
        onSubmit={onSubmit}
      />
      <Space className="sticky-footer-left">
        <Button onClick={() => navigate(`/energySaving/batch/configList`)}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default Config;
