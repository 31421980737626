import { FC, useEffect, useMemo, useState } from 'react';

import {
  Breadcrumb,
  Button,
  combineURL,
  EllipsisSpan,
  Modal,
  Select,
  Table,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import BorderWrapper from '../../../../components/BorderWrapper';

import BsaSteps from '../BsaSteps';
import BsaPageHeader from '../BsaPageHeader';

import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { BsaComponentsProps, getNext, getPrevious } from '../../factory';
import { BsaType } from '../../../../api/constans';
import {
  bindStack,
  getBsaBindingPcsList,
  getBsaBindingStackList,
  getDraft,
  getStackList,
  saveDraft,
  StackListDevice,
} from '../../../../api/bsa';
import { StackDevice } from '../../../../api/entity';
import { SubmitButton } from '../UtilsButton';
import { BSAStatus, DeviceStatus, DeviceStatusDisplay } from '../../../../api/type';

const BsaStack: FC<BsaComponentsProps> = ({
  disabled = false,
  typeList,
  foucsType,
  currentType,
  isDraft,
  status,
  changeType,
  updateFn,
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const routesContext = useBreadcrumbRoutes();
  const routes = [{ name: `电池堆设置` }];

  const isComplete = useMemo(() => {
    return (typeList ?? []).includes(BsaType.STACK_INIT);
  }, [typeList]);

  const isRackCreated = useMemo(() => {
    return (typeList ?? []).includes(BsaType.RACK_DEVICE_CREATED) || currentType === BsaType.RACK_DEVICE_CREATED;
  }, [typeList, currentType]);

  const [stackList, setStackList] = useState<StackListDevice[]>([]);

  const [data, setData] = useState<Partial<StackDevice>[]>([]);

  useEffect(() => {
    if (id) {
      getStackList(id).then(res => setStackList(res.list ?? []));
      if (!isComplete) {
        getBsaBindingPcsList(id).then(res => {
          setData(res.map(i => ({ pcsId: i.id, pcsName: i.deviceName })));
        });
      } else {
        getBsaBindingStackList(id).then(res => {
          setData(res.list ?? []);
        });
      }
    }
  }, [id, isComplete]);

  useEffect(() => {
    if (isComplete && data.length > 0) {
      const filterData = data.map(
        i =>
          ({
            ...i,
            pcsId: undefined,
            pcsName: undefined,
          } as StackListDevice)
      );
      const copy = [...stackList];
      filterData.forEach(i => {
        if (stackList.findIndex(v => v.deviceId === i.deviceId) === -1) {
          copy.push(i);
        }
      });
      setStackList([...copy].filter(item => item.deviceId));
    }
  }, [data, isComplete, stackList]);

  const selectStackList = useMemo(() => {
    if (data.length > 0 && stackList.length > 0) {
      const selectedStackIds = data.filter(i => i.deviceId).map(i => i.deviceId);
      return stackList.filter(i => !selectedStackIds.includes(i.deviceId));
    }
  }, [data, stackList]);

  const [loadDraft, setLoadDraft] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      if (!isComplete && data.length > 0) {
        if (!loadDraft) {
          // 未完成，查看是否有草稿
          getDraft(id).then(draft => {
            setLoadDraft(true);
            const content: Partial<StackDevice>[] = draft.content ? JSON.parse(draft.content) : undefined;
            if (Array.isArray(content)) {
              const newData = data.map(i => {
                const find = content.find(j => j.pcsId === i.pcsId);
                return find
                  ? {
                      ...i,
                      ...find,
                    }
                  : { ...i };
              });
              setData([...newData]);
            }
          });
        }
      }
    }
  }, [id, isComplete, data, loadDraft]);

  const onSeLectStack = (index: number, deviceId?: number) => {
    if (deviceId) {
      const selectStack = selectStackList?.find(i => i.deviceId === deviceId);
      if (selectStack) {
        setData([
          ...data.slice(0, index),
          {
            ...data[index],
            deviceId: selectStack.deviceId,
            model: selectStack.model,
            // 型号名称
            modelName: selectStack.modelName,
            // 名称
            deviceName: selectStack.deviceName,
            // device编号
            code: selectStack.code,
            // 所属类目
            typeName: selectStack.typeName,
            // 能量
            energy: selectStack.energy ?? undefined,
            // 单位
            energyUnit: selectStack.energyUnit ?? undefined,
            // 组内电池蔟个数
            rackNum: selectStack.rackNum,
            deviceStatus: selectStack.deviceStatus,
          },
          ...data.slice(index + 1),
        ]);
      }
    } else {
      const record = data[index];
      setData([
        ...data.slice(0, index),
        {
          pcsName: record.pcsName,
          pcsId: record.pcsId,
        },
        ...data.slice(index + 1),
      ]);
    }
  };

  const columns = [
    {
      title: '对应PCS',
      dataIndex: 'pcsName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '电池堆',
      dataIndex: 'deviceName',
      key: 'name1',
      width: 200,
      render: (v: string, record: Partial<StackDevice>, index: number) => {
        return disabled || isRackCreated || currentType === BsaType.BSA_COMPLETED || currentType === null ? (
          <EllipsisSpan value={v} />
        ) : (
          <div className={styles.require}>
            <Select
              style={{ width: 150 }}
              value={record.deviceId}
              allowClear
              onChange={v => {
                onSeLectStack(index, v);
              }}
            >
              {(stackList ?? [])?.map(i => (
                <Select.Option
                  disabled={!(selectStackList ?? []).map(i => i.deviceId).includes(i.deviceId)}
                  key={i.deviceId}
                  value={i.deviceId}
                >{`${i.deviceName} ${i.typeName} ${i.modelName}`}</Select.Option>
              ))}
            </Select>
          </div>
        );
      },
    },
    {
      title: '所属类目',
      dataIndex: 'typeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '型号',
      dataIndex: 'modelName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '簇数',
      dataIndex: 'rackNum',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '电池堆能量',
      dataIndex: 'energy',
      ellipsis: { showTitle: true },
      render: (v: string, record: Partial<StackDevice>) => (
        <EllipsisSpan value={v ? `${v ?? ''} ${record.energyUnit ?? ''}` : undefined} />
      ),
    },
    {
      title: '设备编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备状态',
      dataIndex: 'deviceStatus',
      ellipsis: { showTitle: true },
      render: (value: DeviceStatus) => {
        return <EllipsisSpan value={DeviceStatusDisplay[value]} />;
      },
    },
    {
      title: '操作',
      width: 180,
      fixed: 'right' as const,
      render: (v: undefined, record: Partial<StackDevice>) => {
        return record.deviceId ? (
          <a
            target="_blank"
            href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/${record.deviceId}/detail`)}
            rel="noreferrer"
          >
            查看详情
          </a>
        ) : null;
      },
    },
  ];

  const onPrevious = () => {
    if (Array.isArray(typeList) && foucsType) {
      const type = getPrevious(foucsType, typeList);
      if (type) {
        changeType?.(type);
      }
    }
  };

  const onNext = () => {
    const type = getNext(foucsType!, typeList ?? [], currentType);
    if (type) {
      changeType?.(type);
    }
  };

  const onSaveDraft = () => {
    saveDraft({
      bsaId: Number(id),
      content: JSON.stringify(data),
    }).then(() => {
      Modal.success({
        content: '保存成功',
      });
    });
  };

  const onFinish = () => {
    if (id) {
      if (disabled) {
        onNext();
      } else {
        if (isRackCreated) {
          onNext();
        } else {
          if (data.length === data.filter(i => i.deviceId).length) {
            bindStack({
              bsaId: Number(id),
              stackList: data.map((i, index) => ({
                deviceId: i.deviceId!,
                pcsId: i.pcsId!,
                sequence: index!,
              })),
            }).then(() => {
              if (isComplete) {
                onNext();
              } else {
                updateFn?.();
              }
            });
          } else {
            Modal.warning({
              title: '请先选择电池堆',
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content" style={{ minHeight: 'calc(100vh - 128px)' }}>
            <BsaPageHeader
              title="电池堆设置"
              extraContent={
                <>
                  <Button type="primary" onClick={onPrevious}>
                    上一步
                  </Button>
                  {!typeList?.includes(BsaType.RACK_DEVICE_CREATE) &&
                  (currentType === BsaType.BSA_COMPLETED || currentType === null) ? (
                    disabled || !isDraft ? null : (
                      <SubmitButton
                        onOk={() => {
                          navigate('/energy-storage/basic/bsa');
                        }}
                      />
                    )
                  ) : (
                    <Button type="primary" onClick={onFinish}>
                      下一步
                    </Button>
                  )}
                  {!disabled && !isComplete && status !== BSAStatus.INVALID && (
                    <Button type="primary" onClick={onSaveDraft}>
                      存草稿
                    </Button>
                  )}
                </>
              }
            >
              <BsaSteps typeList={typeList} foucsType={foucsType} />
            </BsaPageHeader>
            {!disabled && !isRackCreated && (
              <div className={styles.add}>
                <Button type="primary">
                  <a
                    href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/create`)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    前往创建电池堆
                  </a>
                </Button>
              </div>
            )}
            <Table
              rowKey="deviceId"
              columns={columns}
              dataSource={data}
              rowClassName={(record: Partial<StackDevice>) => {
                return record.deviceStatus === DeviceStatus.INVALID ? 'device-invalid' : '';
              }}
            />
          </div>
        </BorderWrapper>
      </div>
    </>
  );
};

export default BsaStack;
