import React, { CSSProperties, FC, Key, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { Empty, Input, Tree } from '@maxtropy/components';
import { Spin } from 'antd';
import {
  V2BsaBatteryGetBsaBatteryTreePostResponse,
  apiV2BsaBatteryGetBsaBatteryTreePost,
} from '@maxtropy/dmes-apis-v2';
import { DataNode } from 'antd/es/tree';
import energyStorageIcon from '../../imgs/energyStorage.png';
import clusterIcon from '../../imgs/cluster.png';
import stackIcon from '../../imgs/stack.png';
import { flattenArray, flattenMap, getPathByNodeId, selectDefaltKey } from '../../utils';

const { Search } = Input;

export enum BsaType {
  TENANT = 0, // 租户
  ORGANIZATION = 1, // 组织
  ENERGY_STORAGE = 2, // 储能站
  STACK = 3, // 电池堆
  CLUSTER = 4, // 电池簇
}

export interface BsaTreeProps {
  style?: CSSProperties;
  changeTreeSelect?: (key?: React.Key, info?: any) => void;
}

const BsaTree: FC<BsaTreeProps> = props => {
  const { style, changeTreeSelect } = props;
  const [searchParams, setSearchParams] = useState<string>(); // 搜索
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataNode[]>();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedKey, setSelectedKey] = useState<Key[]>([]);
  const flattenTreeData = useRef<any>();

  // 查询树形结构数据(非渠道端)
  useEffect(() => {
    setLoading(true);
    apiV2BsaBatteryGetBsaBatteryTreePost({})
      .then(res => {
        if (res) {
          const treeData = transformTree(res.list, res.list);
          setData(treeData);
          flattenTreeData.current = flattenMap(treeData);
          setExpandedKeys(Array.from(flattenTreeData.current.keys()) as string[]);
          if (!treeData.length) {
            changeTreeSelect?.(undefined, undefined);
            setSelectedKey([]);
          } else {
            const stackKey = selectDefaltKey(treeData);
            if (stackKey) {
              changeTreeSelect?.(stackKey, flattenTreeData.current.get(stackKey)?.info);
              setSelectedKey([stackKey]);
            } else {
              changeTreeSelect?.(undefined, undefined);
              setSelectedKey([]);
            }
          }
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 找parentKey
  useEffect(() => {
    if (data && data.length) {
      if (searchParams) {
        const dataflatArr = flattenArray(data);
        const newExpandedKeys = dataflatArr
          ?.map(item => {
            if (item.title.includes(searchParams)) {
              return getPathByNodeId(data, item.key);
            }
            return null;
          })
          .filter(i => !!i);

        newExpandedKeys.forEach(i => i?.pop());
        setExpandedKeys(Array.from(new Set(newExpandedKeys.flat())) as string[]);
      } else {
        const flattenTreeData = flattenMap(data);
        setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, data]);

  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    if (data && data.length && searchParams) {
      const loop = (data: (DataNode & { info?: any })[]): DataNode[] =>
        data.map(item => {
          const strTitle = item.info.name as string;
          const searchName = searchParams ?? undefined;
          const index = strTitle.indexOf(searchName);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchName.length);
          const title =
            index > -1 ? (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  {beforeStr}
                  <span className={styles.filter_text}>{searchName}</span>
                  {afterStr}
                </span>
              </>
            ) : (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  <span>{strTitle}</span>
                </span>
              </>
            );
          if (item.children) {
            return { ...item, title, children: loop(item.children) };
          }
          return {
            ...item,
            title,
          };
        });
      return loop(data);
    } else {
      return data;
    }
  }, [searchParams, data]);

  // 搜索树的选择框
  const onSelect = (selectedKeys: Key[], e: any) => {
    setSelectedKey(selectedKeys);
    changeTreeSelect?.(selectedKeys[0], e.node.info);
  };

  return (
    <div className={styles.treeArea} style={style}>
      <div className={styles.searchArea}>
        <Search placeholder="请输入名称" allowClear onSearch={(v: string) => setSearchParams(v)} />
      </div>

      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data && data.length ? (
            <Tree
              showIcon
              blockNode
              treeData={treeData}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKey}
              onExpand={(expandedKeys: any) => {
                setExpandedKeys(expandedKeys as string[]);
              }}
              onSelect={onSelect}
            />
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default BsaTree;

const renderIcon = (type: BsaType) => {
  if (type === BsaType.ENERGY_STORAGE) {
    return <img src={energyStorageIcon} className={styles.iconSty} title="储能站" alt="" />;
  } else if (type === BsaType.CLUSTER) {
    return <img src={clusterIcon} className={styles.iconSty} title="电池簇" alt="" />;
  } else if (type === BsaType.STACK) {
    return <img src={stackIcon} className={styles.iconSty} title="电池堆" alt="" />;
  } else {
    return undefined;
  }
};

// 防止key重复
export const uniqKey = (type: BsaType) => {
  if (type === BsaType.ENERGY_STORAGE) {
    return 'energyStorage';
  } else if (type === BsaType.CLUSTER) {
    return 'cluster';
  } else if (type === BsaType.STACK) {
    return 'stack';
  } else if (type === BsaType.ORGANIZATION) {
    return 'organization';
  } else {
    return 'tenant';
  }
};

// 使得返回的数据变成DataNode格式
export const transformTree = (
  tree?: V2BsaBatteryGetBsaBatteryTreePostResponse['list'],
  originTree?: V2BsaBatteryGetBsaBatteryTreePostResponse['list']
): DataNode[] => {
  if (!tree) return [];
  return tree.map(item => {
    return {
      icon: renderIcon(item.type as BsaType),
      key: item.id + uniqKey(item.type as BsaType),
      title: item.name ? (
        <span style={{ marginRight: '5px' }} id={`${item.id}`}>
          {item.name}
        </span>
      ) : (
        '--'
      ),
      selectable: (item.type as BsaType) === BsaType.CLUSTER ? true : false,
      info: {
        name: item?.name,
        id: item?.id,
        type: item?.type,
        bsaType: item?.bsaType,
        deviceClusterId: (item.type as BsaType) === BsaType.CLUSTER ? item?.deviceId : undefined,
        deviceStackId:
          (item.type as BsaType) === BsaType.CLUSTER
            ? loopTreeTofindParent(item.id, item.type, originTree)?.deviceId
            : undefined,
      },
      children: transformTree(item.children, originTree),
    };
  });
};

export type V2BsaBatteryGetBsaBatteryTreePostResponseItem = Exclude<
  V2BsaBatteryGetBsaBatteryTreePostResponse['list'],
  undefined
>[number];

// 查找父级
export function loopTreeTofindParent(
  currentItemId?: Key,
  currentItemType?: BsaType,
  tree?: V2BsaBatteryGetBsaBatteryTreePostResponse['list']
): V2BsaBatteryGetBsaBatteryTreePostResponseItem | null {
  let res = null;
  function loop(
    currentItemId?: Key,
    currentItemType?: BsaType,
    array?: V2BsaBatteryGetBsaBatteryTreePostResponse['list']
  ) {
    (array ?? []).forEach(item => {
      if (item.children && item.children.find(i => i.id === currentItemId && i.type === currentItemType)) {
        res = item;
      } else {
        loop(currentItemId, currentItemType, item.children);
      }
    });
  }
  loop(currentItemId, currentItemType, tree);
  return res;
}
