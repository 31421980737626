import { PermissionsType } from '@/common/permissionsConst';
import EnergySavingBatchConfig from '.';
import Config from './Config';
import ModifyLog from './ModifyLog';

const routes = [
  {
    path: '/energySaving/batch/configList',
    permission: PermissionsType.P_LPSKENERGYSAVINGCONFIGURATION,
    element: <EnergySavingBatchConfig />,
  },
  {
    path: '/energySaving/batch/configList/config/:id',
    permission: PermissionsType.P_LPSKENERGYSAVINGCONFIGURATION,
    element: <Config />,
  },
  {
    path: '/energySaving/batch/configList/modifyLog/:id',
    permission: PermissionsType.P_LPSKENERGYSAVINGCONFIGURATION,
    element: <ModifyLog />,
  },
];
export default routes;
