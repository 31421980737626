import { useTenantPermissions } from '@maxtropy/components';
import { useEffect, useState, useCallback, useRef } from 'react';
import dayjs from 'dayjs';

export function useHasPermission(key: string) {
  const [status, setStatus] = useState<boolean>();
  const permissions = useTenantPermissions();
  useEffect(() => {
    setStatus((permissions ?? []).findIndex(i => i === key) !== -1);
  }, [permissions, key]);
  return status;
}

export function useDefaultRangeTime(type = 'day') {
  return useCallback(() => {
    let currentTap = dayjs().startOf('s').valueOf();
    let beforeTap: number;
    switch (type) {
      case 'day':
        beforeTap = dayjs().startOf('s').subtract(1, 'day').valueOf();
        break;
      case 'week':
        beforeTap = dayjs().startOf('s').subtract(6, 'day').valueOf();
        break;
      case 'month':
        beforeTap = dayjs().startOf('s').subtract(30, 'day').valueOf();
        break;
      default:
        beforeTap = dayjs().startOf('day').valueOf();
        break;
    }
    return {
      startTime: beforeTap,
      endTime: currentTap,
    };
  }, [type])();
}
export function usePrevious<T>(values: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = values;
  }, [values]);

  return ref.current;
}

export function useControlledState<T, R = T>(
  defaultStateValue: T | (() => T),
  option?: {
    defaultValue?: T | (() => T);
    value?: T;
    onChange?: (value: T, prevValue?: T) => void;
  }
): [R, (value: T) => void] {
  const { defaultValue, value, onChange } = option || {};
  const [innerValue, setInnerValue] = useState<T | undefined>(() => {
    if (value !== undefined) {
      return value;
    }
    if (defaultValue !== undefined) {
      return typeof defaultValue === 'function' ? (defaultValue as any)() : defaultValue;
    }
    return typeof defaultStateValue === 'function' ? (defaultStateValue as any)() : defaultStateValue;
  });

  let mergedValue = value !== undefined ? value : innerValue;

  function triggerChange(newValue: T) {
    setInnerValue(newValue);
    if (mergedValue !== newValue && onChange) {
      onChange(newValue);
    }
  }

  // Effect of reset value to `undefined`
  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    if (value === undefined) {
      setInnerValue(value);
    }
  }, [value]);

  return [mergedValue as unknown as R, triggerChange];
}
