import { PvListItem, updatePvStationStatus } from '@/api/pv';
import { PVStatus } from '@/api/type';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
import { MenuProps, Space } from 'antd';
import { Button, Dropdown, message, Modal, PopConfirm } from '@maxtropy/components';
import { useMemo, useState } from 'react';
import EditCostModal from '../CostModal/Edit';
import ViewCostModal from '../CostModal/View';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { StationType } from '@/pages/SiteAccess';

interface Iprops extends PvListItem {
  update?: () => void;
}

const ListActionBtns: React.FC<Iprops> = ({ id, status, address, update, stage, ou }) => {
  const hasEnablePermission = useHasPermission(PermissionsType.B_PV_STATION_START);
  const hasStopPermission = useHasPermission(PermissionsType.B_PV_STATION_STOP);
  const navigate = useNavigate();
  /**
   * 草稿状态，此时拥有启用、查看、编辑按钮。都允许点击。
   * 启用时，拥有停用、查看、编辑按钮，此时编辑按钮不允许点击。
   * 停用时，拥有启用、查看、编辑按钮。
   */
  const showEdit = useMemo(() => status !== PVStatus.INVALID, [status]);
  const showView = useMemo(() => status !== PVStatus.INVALID, [status]);
  const showEnable = useMemo(
    () => [PVStatus.DRAFT, PVStatus.DISABLE].includes(status!) && hasEnablePermission,
    [status, hasEnablePermission]
  );
  const showStop = useMemo(() => status === PVStatus.ENABLE && hasStopPermission, [status, hasStopPermission]);
  // 启用
  const updateStatus = (status: PVStatus, title: string) => {
    // 启用时如果未填写基础信息,弹框未完善光伏站基础信息
    if (status === PVStatus.ENABLE && !address) {
      Modal.error({
        title: '未完善光伏站基础信息',
        okText: '确认',
      });
      return;
    }
    updatePvStationStatus(id!, status).then(res => {
      if (res.result) {
        message.success(title + '成功');
        update?.();
      } else {
        message.error(title + '失败');
      }
    });
  };
  return (
    <>
      <Space size={16}>
        {showEnable && (
          <PopConfirm
            title="启用后相关页面将显示该光伏站但无法编辑该光伏站，是否启用？"
            onConfirm={() => updateStatus(PVStatus.ENABLE, '启用')}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link">启用</Button>
          </PopConfirm>
        )}
        {showStop && (
          <PopConfirm
            title="停用后可编辑该光伏站但相关页面不显示该光伏站，是否停用？"
            onConfirm={() => updateStatus(PVStatus.DISABLE, '停用')}
            okText="确定"
            cancelText="取消"
          >
            <Button danger type="link">
              停用
            </Button>
          </PopConfirm>
        )}
        <Space size={16}>
          {showView && <ViewBtns op="view" id={id} ouId={ou?.id} status={status} stage={stage} />}
          {showEdit && <ViewBtns op="edit" id={id} ouId={ou?.id} status={status} stage={stage} update={update} />}
          <Button
            type="link"
            onClick={() =>
              navigate(`/pv-storage/basic/manage/siteDataProcessing?id=${id}&type=${StationType.PV_STATION}`)
            }
          >
            站点数据处理设置
          </Button>
        </Space>
      </Space>
    </>
  );
};
const ViewBtns: React.FC<{
  op: string;
  id?: number;
  stage?: string;
  status?: PVStatus;
  ouId?: number;
  update?: () => void;
}> = ({ op = 'view', id, status = '', stage, ouId, update }) => {
  const title = op === 'view' ? '查看' : '编辑';
  const hasBasicEditPermission = useHasPermission(PermissionsType.B_PV_BASIC_EDIT);
  const hasArrayEditPermission = useHasPermission(PermissionsType.B_PV_ARRAY_EDIT);
  const hasHighEditPermission = useHasPermission(PermissionsType.B_PV_HIGH_VOLTAGE_EDIT);
  const hasUpdateCostPermission = useHasPermission(PermissionsType.B_PV_COSTINFO_EDIT);
  const hasViewCostPermission = useHasPermission(PermissionsType.B_PV_COSTINFO_VIEW);
  const editRoutes = [
    {
      key: '1',
      isShow: hasBasicEditPermission,
      route: `/pv-storage/basic/manage/baseInfo/edit?id=${id}&status=${status}`,
      title: '基础信息',
    },
    {
      key: '2',
      isShow: hasArrayEditPermission,
      route: `/pv-storage/basic/manage/arrayInfo/edit?id=${id}&status=${status}&ouId=${ouId}`,
      title: '阵列信息',
    },
    {
      key: '3',
      isShow: hasHighEditPermission,
      route: `/pv-storage/basic/manage/${id}/edit/cabinet?status=${status}&ouId=${ouId}`,
      title: '配套设备',
    },
    // {
    //   key: '4',
    //   isShow: hasUpdateCostPermission,
    //   func: () => setEditCostModalOpen(true),
    //   title: '成本信息',
    // },
  ].filter(v => v.isShow);

  const viewRoutes = [
    {
      key: '1',
      route: `/pv-storage/basic/manage/baseInfo/view?id=${id}&status=${status}`,
      title: '基础信息',
      isShow: true,
    },
    {
      key: '2',
      route: `/pv-storage/basic/manage/arrayInfo/view?id=${id}&status=${status}&ouId=${ouId}`,
      title: '阵列信息',
      isShow: true,
    },
    {
      key: '3',
      route: `/pv-storage/basic/manage/${id}/cabinet`,
      title: '配套设备',
      isShow: true,
    },
    // {
    //   key: '4',
    //   func: () => setViewCostModalOpen(true),
    //   title: '成本信息',
    //   isShow: hasViewCostPermission,
    // },
  ].filter(v => v.isShow);
  const itemSubs = op === 'view' ? viewRoutes : editRoutes;
  // 编辑按钮禁用
  const editDisable = useMemo(() => {
    return status === PVStatus.ENABLE && op === 'edit';
  }, [status, op]);
  // 页面跳转
  const routeTo = (url: string) => {
    if (url.includes('manage/arrayInfo')) {
      sessionStorage.setItem('arrayStep', stage || '0');
    }
    window.open(url, '_blank');
  };

  const [editCostModalOpen, setEditCostModalOpen] = useState(false);
  const [viewCostModalOpen, setViewCostModalOpen] = useState(false);
  const items: MenuProps['items'] = itemSubs.map(item => ({
    key: item.key,
    label: (
      <span
        onClick={() => {
          if (item.route) {
            routeTo(item.route);
            return;
          }
          // item.func?.();
        }}
        className={styles.menuItem}
      >
        {title + item.title}
      </span>
    ),
  }));
  return (
    <>
      <Dropdown type="link" disabled={editDisable} menu={{ items }}>
        <a href="/" onClick={e => e.preventDefault()}>
          <span>{title}</span>
        </a>
      </Dropdown>
      {editCostModalOpen && (
        <EditCostModal
          source="pv"
          id={id}
          cancel={() => {
            setEditCostModalOpen(false);
          }}
          confirm={() => {
            update?.();
            setEditCostModalOpen(false);
          }}
        />
      )}
      {viewCostModalOpen && (
        <ViewCostModal
          source="pv"
          id={id}
          cancel={() => {
            setViewCostModalOpen(false);
          }}
        />
      )}
    </>
  );
};
export default ListActionBtns;
