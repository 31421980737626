import {
  createPointTemplate,
  getDraft,
  getPointTemplatesByAllStackIds,
  getStackListByBsaId,
  PointTemplateRes,
  StackListItemProps,
  updatePointTemplate,
} from '@/api/bsa';
import { BsaType } from '@/api/constans';
import { pointStatusCheck } from '@/api/edgeDevice';
import { BsaDeviceTypeEnum, ProcessStatusEnum } from '@/api/type';
import { Space } from 'antd';
import { forwardRef, Key, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { BsaComponentsProps } from '../../factory';
import CollectionPoint, { CollectionPointRef } from '../CollectionPoint';
import { Tabs, Button } from '@maxtropy/components';
interface Iprops extends BsaComponentsProps {
  bsaId: Key;
  editable?: boolean;
  bsaDeviceType: BsaDeviceTypeEnum;
  bsaTypeCreate: BsaType; // eg: RACK_DAQ_POINT_CREATE
  bsaTypeCreated: BsaType; // eg: RACK_DAQ_POINT_CREATED
}
export interface StackTabsRef {
  updateTemplateState?: ProcessStatusEnum;
  getAllPointsOnStack?: () => Promise<PointTemplateRes[]>;
}
const StackTabs = forwardRef<StackTabsRef, Iprops>((props, ref) => {
  const { bsaId, disabled, typeList, currentType, updateFn, editable, bsaDeviceType, bsaTypeCreate, bsaTypeCreated } =
    props;
  const [stackList, setStackList] = useState<StackListItemProps[]>([]);
  const stackListRef = useRef<StackListItemProps[]>([]);
  const [defaultKey, setDefaultKey] = useState<string>();
  const pointsRef = useRef<(CollectionPointRef | null)[]>([]);
  // 获取stack列表
  useEffect(() => {
    if (bsaId) {
      getStackListByBsaId(bsaId).then(res => {
        if (res.list && res.list?.[0]) {
          setDefaultKey(res.list?.[0].id.toString());
        }
        setStackList(res.list ?? []);
        stackListRef.current = res.list ?? [];
      });
    }
  }, [bsaId]);

  // bug修改，如有状态为false，则为update而不是create
  useEffect(() => {
    if (bsaId) {
      if (typeList && currentType) {
        if (typeList[typeList.length - 1] > currentType) {
          setUpdateOrCreate(true);
        }
      }
    }
  }, [bsaId, typeList, currentType]);

  const [updateTemplateState, setUpdateTemplateState] = useState<ProcessStatusEnum>();
  const [updateTemplateClickState, setUpdateTemplateClickState] = useState<boolean>(false);
  const [updateOrCreate, setUpdateOrCreate] = useState<boolean>(false);

  // 获取所有stack下面的采集点
  const getAllPointsOnStack = async () => {
    let res = await Promise.all(
      pointsRef.current.map(item => {
        if (item) return item.getPointTemplates();
        return Promise.resolve([]);
      })
    );
    let tempStackList = stackListRef.current;
    let params = res.map((item, index) => ({
      templates: item,
      bsaId: Number(bsaId),
      type: bsaDeviceType,
      stackId: tempStackList?.[index]?.id,
    }));
    return params;
  };
  const isInit = useMemo(() => {
    return (typeList ?? []).includes(bsaTypeCreate);
  }, [typeList, bsaTypeCreate]);

  const submit = () => {
    if (bsaId) {
      getAllPointsOnStack().then(res => {
        createPointTemplate(res, bsaId, bsaDeviceType).then(() => {
          updateFn?.();
        });
      });
    }
  };
  const onRefresh = () => {
    if (bsaId) {
      pointStatusCheck(bsaId, bsaDeviceType).then(res => {
        setUpdateTemplateState(res.status);
        if (res.status !== ProcessStatusEnum.GENERATING) {
          if (editable) {
            if (res.status === ProcessStatusEnum.FAIL) {
              setUpdateTemplateClickState(false);
              setUpdateTemplateState(ProcessStatusEnum.GENERATING);
            }
          } else {
            updateFn?.();
          }
        }
      });
    }
  };
  const updateTemplate = () => {
    if (bsaId) {
      getAllPointsOnStack().then(res => {
        setUpdateTemplateClickState(true);
        updatePointTemplate(res, bsaId, bsaDeviceType).then(() => {
          setUpdateTemplateState(ProcessStatusEnum.GENERATING);
          if (!editable) {
            updateFn?.();
          }
        });
      });
    }
  };
  const shouldGetTemplate = useMemo(() => {
    return typeList?.some(sequence => sequence > bsaTypeCreate);
  }, [typeList, bsaTypeCreate]);
  const [templatesData, setTemplateData] = useState<PointTemplateRes[]>([]);

  // 应该根据stackId获取对应的采集点, 经沟通后端获取草稿api改动太大, 先请求全部
  useEffect(() => {
    if (bsaId) {
      if (Array.isArray(typeList)) {
        if (!shouldGetTemplate) {
          // 未完成，查看是否有草稿
          getDraft(bsaId).then(draft => {
            const content: PointTemplateRes[] = draft.content ? JSON.parse(draft.content) : undefined;
            if (content) {
              setTemplateData(content);
            }
          });
        } else {
          getPointTemplatesByAllStackIds({
            bsaId: Number(bsaId),
            type: bsaDeviceType,
          }).then(res => {
            setTemplateData(res);
          });
        }
      }
    }
  }, [bsaId, shouldGetTemplate, typeList, bsaDeviceType]);
  const items = useMemo(() => {
    return stackList.map((item, index) => {
      let num = String(index + 1);
      if (+num < 10) {
        num = '0' + num;
      }
      let pointsInfo = templatesData.find(data => data.stackId === item.id);
      return {
        label: `电池堆${num}`,
        key: item.id.toString(),
        forceRender: true,
        children: (
          <CollectionPoint
            key={item.id}
            stackId={item.id}
            disabled={disabled}
            typeList={typeList}
            templateData={pointsInfo?.templates}
            currentType={currentType}
            bsaDeviceType={bsaDeviceType}
            updateFn={updateFn}
            editable={editable}
            updateTemplateState={updateTemplateState}
            updateTemplateClickState={updateTemplateClickState}
            ref={ref => (pointsRef.current[index] = ref)}
          />
        ),
      };
    });
  }, [
    stackList,
    currentType,
    disabled,
    typeList,
    editable,
    bsaDeviceType,
    updateFn,
    updateTemplateState,
    updateTemplateClickState,
    templatesData,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      updateTemplateState,
      getAllPointsOnStack,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateTemplateState]
  );
  return (
    <div style={{ marginLeft: 15, marginBottom: 15 }}>
      <Space style={{ marginBottom: 15 }}>
        {!editable && !updateOrCreate ? (
          !isInit ? (
            <>
              <Button type="primary" onClick={submit}>
                批量创建采集点
              </Button>
            </>
          ) : (
            <>
              {currentType === bsaTypeCreated && (
                <Button type="primary" onClick={onRefresh}>
                  刷新
                </Button>
              )}
            </>
          )
        ) : (
          <>
            {updateTemplateClickState ? (
              updateTemplateState === ProcessStatusEnum.GENERATING && (
                <Button type="primary" onClick={onRefresh}>
                  刷新
                </Button>
              )
            ) : (
              <Button type="primary" onClick={updateTemplate}>
                批量更新采集点
              </Button>
            )}
          </>
        )}
      </Space>
      <Tabs defaultActiveKey={defaultKey} items={items} />
    </div>
  );
});

export default StackTabs;
