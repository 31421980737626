import React from 'react';
import { App } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import dayjs, { Dayjs } from 'dayjs';
import { StatisticsPartition } from '../../../../api/circuit';
import { DateBtn } from '../../config';
import { getDefaultTime, getTs } from '../../utils';
import { Query } from '../../index';
import styles from './index.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import type { DatePickerProps } from 'antd';
import { Button, DatePicker, Radio } from '@maxtropy/components';

const { RangePicker } = DatePicker;

export enum DatePickerType {
  ONEMINUTE = 'oneMinute',
  THIRTYMINUTES = 'thirtyMinutes',
  ORIGINAL = 'original',
  DAY = 'date',
  MONTH = 'month',
}

const datePickerTypeDisplay = {
  [DatePickerType.ONEMINUTE]: '1分钟',
  [DatePickerType.ORIGINAL]: '15分钟',
  [DatePickerType.THIRTYMINUTES]: '30分钟',
  [DatePickerType.DAY]: '按日',
  [DatePickerType.MONTH]: '按月',
};

export interface DateSwitchProps {
  btnGroup?: DateBtn[];
  selectBtn: DatePickerType;
  rangeDays?: number;
  aggrby: StatisticsPartition;
  value: any;
  style?: React.CSSProperties;
  onQueryChange: (value: Omit<Query, 'dataType' | 'name'>) => void;
}

const DateSwitch: React.FC<DateSwitchProps> = props => {
  const { btnGroup = [], selectBtn, aggrby, rangeDays, value, onQueryChange, style } = props;
  const onRadioChange = (e: RadioChangeEvent) => {
    const target = btnGroup.find(item => item.btnType === e.target.value);
    if (target) {
      onQueryChange({
        aggrby: target.aggrby,
        btnType: target.btnType,
        ...getDefaultTime(target.aggrby, dayjs()),
      });
    }
  };
  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      // if (
      //   selectBtn === DatePickerType.DAY ||
      //   selectBtn === DatePickerType.ORIGINAL ||
      //   selectBtn === DatePickerType.ONEMINUTE ||
      //   selectBtn === DatePickerType.THIRTYMINUTES
      // ) {
      //   const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
      //   const mostDays = rangeDays
      //     ? rangeDays
      //     : selectBtn === DatePickerType.DAY
      //     ? 31
      //     : selectBtn === DatePickerType.ONEMINUTE
      //     ? 3
      //     : 7;
      //   if (diff >= mostDays) {
      //     return message.warning(`最大上限为${mostDays}天`);
      //   }
      // }
      // if (selectBtn === DatePickerType.MONTH) {
      //   const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
      //   if (diff >= 36) {
      //     return message.warning('最大上限为36个月');
      //   }
      // }
      onQueryChange({
        aggrby: aggrby,
        btnType: selectBtn,
        ...getTs(aggrby, dayjs(beginDate), dayjs(endDate)),
      });
    }
  };

  /**
   * 1. 上面onChangeRangePicker方法校验时弹框提示会触发2次, 具体原因待排查
   * 2. 先采用下面校验方式, 直接在日期面板上做校验体验会更好
   *
   * */
  const disabledDatefix: DatePickerProps['disabledDate'] = (current: Dayjs, { from }: { from?: Dayjs }) => {
    const mostDays = rangeDays
      ? rangeDays
      : selectBtn === DatePickerType.DAY
      ? 31
      : selectBtn === DatePickerType.ONEMINUTE
      ? 3
      : 7;
    let timeType: 'days' | 'M' = 'days';
    if (
      selectBtn === DatePickerType.DAY ||
      selectBtn === DatePickerType.ORIGINAL ||
      selectBtn === DatePickerType.ONEMINUTE ||
      selectBtn === DatePickerType.THIRTYMINUTES
    ) {
      timeType = 'days';
    }
    if (selectBtn === DatePickerType.MONTH) {
      timeType = 'M' as const;
    }
    let rangeGap = timeType === 'days' ? mostDays : 36;
    if (current > dayjs().endOf('day')) return true;
    if (from) {
      return Math.abs(current.diff(from, timeType)) >= rangeGap;
    }

    return false;
  };

  const diffDays = dayjs(value[1]).diff(dayjs(value[0]), 'd');
  const diffMonths = dayjs(value[1]).diff(dayjs(value[0]), 'months');

  return (
    <>
      <div className={styles.dateSwitch} style={style}>
        <div className={styles.switchBtn}>
          <Radio.Group onChange={onRadioChange} value={selectBtn} buttonStyle="solid">
            {btnGroup?.map(btn => (
              <Radio.Button key={btn.btnType} value={btn.btnType}>
                {datePickerTypeDisplay[btn.btnType as DatePickerType]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>

        <div className={styles.datePickerArea}>
          {/* <span className={styles.label}>日期</span> */}
          {(selectBtn === DatePickerType.ORIGINAL ||
            selectBtn === DatePickerType.ONEMINUTE ||
            selectBtn === DatePickerType.THIRTYMINUTES) && (
            <>
              <RangePicker
                value={value}
                onChange={onChangeRangePicker}
                disabledDate={disabledDatefix}
                allowClear={false}
                // getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
              />
              <Button
                icon={<LeftOutlined />}
                className={styles.hidden}
                style={{ margin: '0 10px' }}
                onClick={() => {
                  onQueryChange({
                    aggrby: aggrby,
                    btnType: selectBtn,
                    ...getTs(aggrby, dayjs(value[0]).subtract(diffDays + 1, 'd'), dayjs(value[0]).subtract(1, 'd')),
                  });
                }}
              />
              <Button
                className={styles.hidden}
                icon={<RightOutlined />}
                disabled={
                  dayjs(value[1])
                    .add(diffDays + 1, 'd')
                    .startOf('d') > dayjs().startOf('d')
                }
                onClick={() => {
                  onQueryChange({
                    aggrby: aggrby,
                    btnType: selectBtn,
                    ...getTs(aggrby, dayjs(value[1]).add(1, 'd'), dayjs(value[1]).add(diffDays + 1, 'd')),
                  });
                }}
              />
            </>
          )}
          {(selectBtn === DatePickerType.DAY || selectBtn === DatePickerType.MONTH) && (
            <>
              <RangePicker
                value={value}
                disabledDate={disabledDatefix}
                onChange={onChangeRangePicker}
                picker={selectBtn === DatePickerType.DAY ? 'date' : 'month'}
                allowClear={false}
                // getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
              />
              <Button
                icon={<LeftOutlined />}
                style={{ margin: '0 10px' }}
                onClick={() => {
                  const ts =
                    selectBtn === DatePickerType.MONTH
                      ? getTs(
                          aggrby,
                          dayjs(value[0]).subtract(diffMonths + 1, 'months'),
                          dayjs(value[0]).subtract(1, 'months')
                        )
                      : getTs(aggrby, dayjs(value[0]).subtract(diffDays + 1, 'd'), dayjs(value[0]).subtract(1, 'd'));
                  onQueryChange({
                    aggrby: aggrby,
                    btnType: selectBtn,
                    ...ts,
                  });
                }}
              />
              <Button
                icon={<RightOutlined />}
                disabled={
                  selectBtn === DatePickerType.MONTH
                    ? dayjs(value[1])
                        .add(diffMonths + 1, 'months')
                        .endOf('m') > dayjs().endOf('months')
                    : dayjs(value[1])
                        .add(diffDays + 1, 'd')
                        .startOf('d') > dayjs().startOf('d')
                }
                onClick={() => {
                  const ts =
                    selectBtn === DatePickerType.MONTH
                      ? getTs(aggrby, dayjs(value[1]).add(1, 'months'), dayjs(value[1]).add(diffMonths + 1, 'months'))
                      : getTs(aggrby, dayjs(value[1]).add(1, 'd'), dayjs(value[1]).add(diffDays + 1, 'd'));
                  onQueryChange({
                    aggrby: aggrby,
                    btnType: selectBtn,
                    ...ts,
                  });
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DateSwitch;
