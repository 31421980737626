import React, { CSSProperties, Key, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { DataNode } from 'antd/lib/tree';
import classNames from 'classnames';
import { useUpdateEffect } from 'ahooks';
import { CircuitType, getCircuitTree4 } from '../../../../api/circuit';
import { CircuitTreeData2 } from '../../../../api/const';
import circuitIcon from '../../../../assets/images/circuit.svg';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
import { Input, Tree } from '@maxtropy/components';

export interface expandCircuitTreeLeafInfo2 extends CircuitTreeLeafInfo2 {
  children: expandCircuitTreeLeafInfo2[];
}

const { Search } = Input;

export interface CircuitTreeLeafInfo {
  capacity?: number | null;
  circuitName: string;
  circuitId: number;
  cabinetId: number;
  measurementId: CircuitType;
  hasLineLoss: boolean;
}
export interface CircuitTreeLeafInfo2 {
  capacity?: number | null;
  name: string;
  circuitId: number;
  type: CircuitType;
  hasLineLoss: boolean;
  id?: number;
  hasOu?: boolean;
}

export interface CircuitTreeProps {
  className?: string;
  style?: CSSProperties;
  changeTreeSelect?: (value: React.Key[], info: expandCircuitTreeLeafInfo2) => void;
}

const flattenMap = (data: DataNode[], map: Map<Key, DataNode & { info?: any }> = new Map()) => {
  data.forEach(i => {
    map.set(i.key, i);
    if (i.children) {
      flattenMap(i.children, map);
    }
  });
  return map;
};

const filterTree = (tree: CircuitTreeData2[], searchText?: string): CircuitTreeData2[] => {
  if (!searchText) return tree;
  const checkContainSearchText = (treeItem: CircuitTreeData2): boolean => {
    if (treeItem.name.includes(searchText)) return true;
    return (
      treeItem.children?.some(childItem => {
        return checkContainSearchText(childItem);
      }) || false
    );
  };
  return tree.filter(item => {
    return checkContainSearchText(item);
  });
};

const transformTree = (tree?: CircuitTreeData2[], searchText?: string): (DataNode & { info: any })[] => {
  if (!tree) return [];
  return tree.map(item => {
    searchText = searchText ?? '';
    const name = item.name;
    const index = name.indexOf(searchText);
    const beforeStr = name.substring(0, index);
    const afterStr = name.slice(index + searchText.length);
    const title =
      index > -1 ? (
        <span>
          {beforeStr}
          <span className={styles.filterTxt}>{searchText}</span>
          {afterStr}
        </span>
      ) : (
        <span>{name}</span>
      );

    return {
      children: transformTree(item.children, searchText),
      icon: <img src={circuitIcon} alt="" />,
      className: 'circuit-title',
      key: item.id,
      title: title,
      selectable: true,
      info: {
        capacity: item.capacity,
        name: item.name,
        circuitId: item.id,
        hasLineLoss: item.hasLineLoss,
        hasOu: item.hasOu,
        type: item.type,
        cabinetId: item.cabinetId,
        children: item.children,
      },
    };
  });
};

const CircuitTree: React.FC<CircuitTreeProps> = props => {
  const { className, changeTreeSelect, style } = props;

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_circuitPhysicalQuantityName = urlSearchParams.get('circuitPhysicalQuantityName') || undefined;
  const url_circuitId = urlSearchParams.get('circuitId') || undefined;

  const [treeRes, setTreeRes] = useState<CircuitTreeData2[]>([]);
  const [data, setData] = useState<(DataNode & { info: any })[]>();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<{ name: string }>();
  const [searchValue, setSearchValue] = useState<string>();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectedKey, setSelectedKey] = useState<Key[]>([]);

  useEffect(() => {
    setLoading(true);
    getCircuitTree4()
      .then(res => {
        setTreeRes(res.list ?? []);
        const filteredTree = filterTree(res.list, url_circuitPhysicalQuantityName);
        const treeData = transformTree(filteredTree, url_circuitPhysicalQuantityName);
        setData(treeData);
        const flattenTreeData = flattenMap(treeData);
        if (url_circuitPhysicalQuantityName) {
          setAutoExpandParent(true);
          const newExpandedKeys: Key[] = [];
          flattenTreeData.forEach((value, key) => {
            if (value.info.name.includes(url_circuitPhysicalQuantityName)) {
              newExpandedKeys.push(key);
            }
          });
          setExpandedKeys(newExpandedKeys);
        }
        if (url_circuitId) {
          changeTreeSelect?.([Number(url_circuitId)], flattenTreeData.get(Number(url_circuitId))!.info);
        }
        if (!url_circuitPhysicalQuantityName && !url_circuitId) {
          // 默认选中第一个
          const firstKey = flattenTreeData.keys().next().value;
          setSelectedKey([firstKey]);
          changeTreeSelect?.([firstKey], flattenTreeData.get(firstKey)!.info);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setAutoExpandParent(true);
    const filteredTreeRes = filterTree(treeRes, searchParams?.name);
    const treeData = transformTree(filteredTreeRes, searchParams?.name);
    setData(treeData);
    if (searchParams?.name) {
      const flattenTreeData = flattenMap(treeData);
      const newExpandedKeys: Key[] = [];
      flattenTreeData.forEach((value, key) => {
        if (value.info.name.includes(searchParams.name)) {
          newExpandedKeys.push(key);
        }
      });
      setExpandedKeys(newExpandedKeys);
    } else {
      setExpandedKeys([]);
    }
  }, [searchParams]);

  // 回路名称搜索, 滚动到可视区域
  let timer: any = useRef<any>();
  useUpdateEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const dom = document.querySelector(`.${styles.filterTxt}`);
      let parentDom = dom?.parentNode as HTMLDivElement;
      parentDom?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
    return () => {
      clearTimeout(timer.current);
    };
  }, [searchParams]);

  const onSelect = (selectedKeys: React.Key[], e: any) => {
    // 寻找根节点
    const flattenTreeData = flattenMap(data!);
    const findParentNode = (id: number): (DataNode & { info?: any }) | undefined => {
      let parent;
      flattenTreeData.forEach(value => {
        if (
          value.info.children.some((item: any) => {
            return item.id === id;
          })
        ) {
          parent = value;
        }
      });
      return parent;
    };

    let currentNode = e.node;
    let rootNode = e.node;
    while (currentNode) {
      currentNode = findParentNode(currentNode.key);
      if (currentNode) {
        rootNode = currentNode;
      }
    }

    changeTreeSelect?.(selectedKeys, rootNode.info);
    setSelectedKey(selectedKeys);
  };

  const [height, setHeight] = useState<number>(0);
  const treeContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (treeContainer.current) {
        setHeight(treeContainer.current.clientHeight);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classNames(className, styles.treeArea)} style={style}>
      <div className={styles.searchArea} style={{ marginTop: '20px' }}>
        <Search
          placeholder="请输入回路名称"
          allowClear
          value={searchValue ?? url_circuitPhysicalQuantityName}
          onChange={e => setSearchValue(e.target.value)}
          onSearch={v => setSearchParams({ ...searchParams, name: v })}
        />
      </div>
      <Spin spinning={loading}>
        <div ref={treeContainer}>
          {data ? (
            <Tree
              style={{ height: 'calc(100vh - 216px)', overflow: 'auto' }}
              showIcon
              treeData={data}
              autoExpandParent={autoExpandParent}
              expandedKeys={expandedKeys}
              defaultExpandAll
              defaultSelectedKeys={[Number(url_circuitId)]}
              selectedKeys={selectedKey}
              onSelect={onSelect}
              onExpand={expandedKeys => {
                setExpandedKeys(expandedKeys as number[]);
                setAutoExpandParent(false);
              }}
              height={height}
            />
          ) : (
            <></>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default CircuitTree;
