import {
  CurrentThirtyDaysIncomeInfoRes,
  getEnergyAssetCurrentThirtyDaysIncomeInfo,
  OuList,
} from '@/api/energyAssetOverview';
import { Spin } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import styles from './index.module.scss';
import HeadTitle from '../HeadTitle/HeadTitle';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { StationHasCount } from '../..';
import { Empty, Button } from '@maxtropy/components';
import { Link } from 'react-router-dom';

export interface ICurrentThirtyDaysIncome {
  ouInfo: OuList;
  height: string | undefined;
}

const CurrentThirtyDaysIncome: FC<ICurrentThirtyDaysIncome> = props => {
  const { ouInfo, height } = props;
  const stationHasCount = useContext(StationHasCount);
  const [energyAssetCurrentThirtyDaysIncomeInfo, setEnergyAssetCurrentThirtyDaysIncomeInfo] =
    useState<CurrentThirtyDaysIncomeInfoRes[]>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 近30日收益信息
      getEnergyAssetCurrentThirtyDaysIncomeInfo(ouInfo.id).then(res => {
        setEnergyAssetCurrentThirtyDaysIncomeInfo(res.list ?? []);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: function (params: any) {
          let str =
            `<span style='display:inline-block;border-radius:10px;'>${dayjs(params[0]?.data[0])?.format(
              'YYYY-MM-DD'
            )}</span>` +
            '<br>' +
            `总收益：<span style='display:inline-block;border-radius:10px;color:#00ADFF;'>${
              !isNil(params[0]?.data[3])
                ? Number(params[0]?.data[3])?.toLocaleString('zh', {
                    maximumFractionDigits: 2,
                  })
                : '--'
            }元</span>`;
          params.forEach((item: any) => {
            const { seriesName, marker } = item;
            // const value = !isNil(data[1]) ? `${data[1].toFixed(2)}` : '--';
            str += `<br> ${marker}${seriesName}: <span style='display:inline-block;border-radius:10px;width:148px'>${
              !isNil(item?.data[1])
                ? Number(item?.data[1])?.toLocaleString('zh', {
                    maximumFractionDigits: 2,
                  }) + '元'
                : '--'
            }</span>环比：${
              item.data[2] && item.data[2] > 0
                ? `<span style='display:inline-block;border-radius:10px;color:#FF4D4F'>${
                    !isNil(item?.data[2]) ? '+' + (Number(item.data[2]) * 100).toFixed(2) + '%' : '--'
                  }</span>`
                : `<span style='display:inline-block;border-radius:10px;color:#59D744'>${
                    !isNil(item?.data[2]) ? +(Number(item.data[2]) * 100).toFixed(2) + '%' : '--'
                  }</span>`
            }`;
          });
          return str;
        },
      },
      legend: {
        top: '2%',
        right: '3%',
        y: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 12, //字体大小
          color: 'rgba(255,255,255,0.65)', //字体颜色
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'time',
          minInterval: 60 * 1000 * 60 * 24,
          axisLabel: {
            formatter: function (e: number) {
              return dayjs(e, 'x').format('MM-DD');
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '元',
          nameTextStyle: {
            fontSize: 14,
            align: 'right',
          },
          nameGap: 30,
          splitLine: {
            lineStyle: {
              color: '#FFFFFF30',
            },
          },
        },
      ],
      series: [
        stationHasCount?.bsaCount
          ? {
              name: '储能站',
              type: 'bar',
              stack: 'Ad',
              color: '#2D8DFF',
              emphasis: {
                focus: 'series',
              },
              data: energyAssetCurrentThirtyDaysIncomeInfo?.map(i => [
                i.incomeDate,
                i.bsaIncome.income,
                i.bsaIncome.incomeRate,
                i.incomeCount,
              ]),
            }
          : undefined,
        stationHasCount?.pvCount
          ? {
              name: '光伏站',
              type: 'bar',
              stack: 'Ad',
              color: '#16DD8E',
              emphasis: {
                focus: 'series',
              },
              data: energyAssetCurrentThirtyDaysIncomeInfo?.map(i => [
                i.incomeDate,
                i.pvIncome.income,
                i.pvIncome.incomeRate,
                i.incomeCount,
              ]),
            }
          : undefined,

        stationHasCount?.evCount
          ? {
              name: '充电站',
              type: 'bar',
              stack: 'Ad',
              color: '#52E7FF',
              emphasis: {
                focus: 'series',
              },
              data: energyAssetCurrentThirtyDaysIncomeInfo?.map(i => [
                i.incomeDate,
                i.evIncome.income,
                i.evIncome.incomeRate,
                i.incomeCount,
              ]),
            }
          : undefined,
      ],
    };
  };

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
      }}
    >
      <div className={styles.titleSty}>
        <HeadTitle title="近30日收益" />
        <Button style={{ padding: 0, marginRight: 22 }} type="link">
          <Link to={`/energy/asset/projectIncome?ouId=${ouInfo.id}`} target="_blank">
            查看更多
          </Link>
        </Button>
      </div>
      <Spin spinning={dataLoading}>
        {!stationHasCount?.pvCount && !stationHasCount?.evCount && !stationHasCount?.bsaCount ? (
          <Empty
            style={{ height: height === 'unset' ? 220 : `calc(${height} - 40px)`, paddingTop: '6%' }}
            description={'无光伏站，储能站，充电站'}
          />
        ) : (
          <ReactEcharts
            option={getOption()}
            notMerge
            lazyUpdate={false}
            style={{ height: height === 'unset' ? 220 : `calc(${height} - 40px)` }} // 样式的设置width与height
          />
        )}
      </Spin>
    </div>
  );
};

export default CurrentThirtyDaysIncome;
