import { PermissionsType } from '@/common/permissionsConst';
import EnergySavingRate from '.';

const routes = [
  {
    path: '/energy/saving/rate',
    permission: PermissionsType.P_LPSKENERGYSAVINGMANAGEMENT,
    element: <EnergySavingRate />,
  },
];
export default routes;
