import { apiV2EnergyConservationEnergySavingQuantityPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import React, { FC, Key, useMemo } from 'react';
import { getChartOption } from './charts';
import styles from './index.module.scss';
import { Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import dayjs, { Dayjs } from 'dayjs';
import { Empty } from '@maxtropy/components';

export interface IEnergySavingAmount {
  circuitId?: Key;
  time?: Dayjs;
}

const EnergySavingAmount: FC<IEnergySavingAmount> = props => {
  const { circuitId, time } = props;

  // 节能量柱状图
  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationEnergySavingQuantityPost({
        time: dayjs(time?.format('YYYY-MM')).valueOf(),
        circuitId: circuitId,
      });
      return res;
    },
    {
      ready: !!circuitId && !!time,
      refreshDeps: [circuitId, time],
    }
  );

  const isShowEmpty = useMemo(() => {
    if (data) {
      return false;
    }
    return true;
  }, [data]);

  // 图表
  const chartOption = useMemo(() => getChartOption(data), [data]);

  return (
    <div className={styles.chart_box}>
      {loading ? (
        <Spin spinning={true} className={styles.spin_box} />
      ) : isShowEmpty ? (
        <Empty className={styles.empty_box} />
      ) : (
        <ReactEcharts style={{ width: '100%' }} option={chartOption} notMerge lazyUpdate={false} />
      )}
    </div>
  );
};

export default EnergySavingAmount;
