import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { BsaDeviceTypeEnum, DataProperty } from './type';

export function getRackDataPropertyList(id: Key) {
  return fetch<{ list: DataProperty[] }>(
    `/api/v2/data-property/list/rack`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);
}

export function getPackDataPropertyList(id: Key) {
  return fetch<{ list: DataProperty[] }>(
    `/api/v2/data-property/list/pack`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);
}

export function getCellDataPropertyList(id: Key) {
  return fetch<{ list: DataProperty[] }>(
    `/api/v2/data-property/list/cell`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);
}

export const getDataPropertyList = (bsaId: Key, type: BsaDeviceTypeEnum) => {
  return type === BsaDeviceTypeEnum.PACK
    ? getPackDataPropertyList(bsaId)
    : type === BsaDeviceTypeEnum.CELL
    ? getCellDataPropertyList(bsaId)
    : getRackDataPropertyList(bsaId);
};

export const queryDataPropertyList = (id: number) =>
  fetch<{ list: DataProperty[] }>(
    `/api/v2/data-property/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
