import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  PopConfirm,
  Select,
  Table,
  usePaging,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useRequest } from 'ahooks';

import { useMemo, useState, useEffect } from 'react';
import styles from './index.module.scss';
import {
  apiV2BsaRemoteControlCommandAddPost,
  apiV2BsaRemoteControlCommandCheckCommandNamePost,
  apiV2BsaRemoteControlCommandDeletePost,
  apiV2BsaRemoteControlCommandEditPost,
  apiV2BsaRemoteControlCommandGetDevicePost,
  apiV2BsaRemoteControlCommandGetPost,
  apiV2BsaRemoteControlCommandPagePost,
  V2BsaRemoteControlCommandPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { BSAStatus, DataPropertyType } from '@/api/type';

import SendValueDom from './components/SendValueDom';
import { getLastTypeName } from '../StorageRemoteControl';
const columns = [
  {
    title: '指令名称',
    dataIndex: 'commandName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '按钮名称',
    dataIndex: 'buttonName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '应用设备',
    dataIndex: 'deviceName',
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备物模型',
    dataIndex: 'physicalModelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标值',
    dataIndex: 'targetValue',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
interface Iprops {
  bsaId?: string;
  status?: BSAStatus;
}
type RowInfo = Exclude<V2BsaRemoteControlCommandPagePostResponse['list'], undefined>[number];
const OrderTableList = ({ bsaId, status }: Iprops) => {
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [visible, setVisible] = useState<boolean>(false);
  const [opType, setOpType] = useState('add');
  const [currentRow, setCurrentRow] = useState<RowInfo>();
  const [form] = Form.useForm();
  const deviceId = Form.useWatch('deviceId', form);
  const dataPropertyId = Form.useWatch('dataPropertyId', form);
  const targetValue = Form.useWatch('targetValue', form);
  const commandName = Form.useWatch('commandName', form);
  const {
    data: list,
    refresh: refreshList,
    loading: tableLoading,
  } = useRequest(
    () =>
      apiV2BsaRemoteControlCommandPagePost({ page: pageOffset, size: pageSize, bsaId }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      }),
    {
      refreshDeps: [pageOffset, pageSize, bsaId],
      ready: !!bsaId,
    }
  );
  const { runAsync: deleteApi } = useRequest((id: number) => apiV2BsaRemoteControlCommandDeletePost({ id }), {
    manual: true,
  });

  const { data: deviceList } = useRequest(
    () => apiV2BsaRemoteControlCommandGetDevicePost({ id: bsaId }).then(res => res.list),
    {
      ready: !!bsaId && visible,
    }
  );
  const { runAsync: addOrEditApi, loading: submitLoading } = useRequest(
    params =>
      opType === 'add' ? apiV2BsaRemoteControlCommandAddPost(params) : apiV2BsaRemoteControlCommandEditPost(params),
    {
      manual: true,
      refreshDeps: [opType],
    }
  );
  const { runAsync: checkNameApi } = useRequest(
    () =>
      apiV2BsaRemoteControlCommandCheckCommandNamePost({
        commandName,
        bsaId,
        commandId: currentRow?.commandId,
      }).then(res => res.result),
    {
      manual: true,
      refreshDeps: [currentRow?.commandId, bsaId, commandName],
    }
  );

  const propertyList = useMemo(() => {
    let data = deviceList?.find(item => item.deviceId === deviceId);
    return data?.deviceDataPropertyPointList;
  }, [deviceId, deviceList]);

  const { k, b, dataPropertyType, enumValueMap, enumNameMap } = useMemo(() => {
    let data = propertyList?.find(item => item.dataPropertyId === dataPropertyId) ?? {};
    let { k, b, dataPropertyType, enumValueMap, enumNameMap } = data;
    return { k, b, dataPropertyType, enumValueMap, enumNameMap };
  }, [dataPropertyId, propertyList]);

  // 目标值下拉列表
  const targetValueList = useMemo(() => {
    return Object.entries(enumNameMap ?? {}).map(([key, value]) => ({
      label: `${value}-${key}`,
      value: +key,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enumValueMap, enumNameMap]);

  // 下发值 =（targetValue-b）/k
  const sendValue = useMemo(() => {
    let formateValueMap = enumValueMap as Record<string, any>;
    if (dataPropertyType === DataPropertyType.ENUM) {
      return formateValueMap?.[targetValue?.toFixed(1)]; // 0 ==> '0.0'
    }
    if (k && !isNaN(targetValue)) {
      if (!targetValue) return null; // 排除空字符串
      return (targetValue - (b ?? 0)) / k;
    }
    return null;
  }, [k, b, targetValue, enumValueMap, dataPropertyType]);

  const controlBtn = () => {
    setVisible(true);
    setOpType('add');
  };

  const isCanEdit = useMemo(() => {
    return status === BSAStatus.ENABLE || status === BSAStatus.DISABLE;
  }, [status]);

  const modalTile = useMemo(() => {
    return opType === 'add' ? '添加' : '编辑';
  }, [opType]);

  useEffect(() => {
    if (opType === 'edit' && visible && currentRow) {
      form.setFieldsValue({ ...currentRow });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow, opType]);
  const deleteBtn = (record: RowInfo) => {
    deleteApi(record.commandId!).then(_ => {
      setPageOffset(1);
      refreshList();
    });
  };

  const confirmBtn = async () => {
    let requiredValid = await form
      .validateFields()
      .then(() => true)
      .catch(() => false);
    if (!requiredValid) return;
    let nameIsUnique = await checkNameApi();
    if (!nameIsUnique) {
      form.setFields([{ name: 'commandName', errors: ['命令名称重复, 请重新输入'] }]);
      return;
    }
    let formRes = form.getFieldsValue();
    addOrEditApi({
      ...formRes,
      commandId: currentRow?.commandId,
      k,
      b,
      enumValueMap,
      enumNameMap,
      dataPropertyType,
      bsaId,
    }).then(() => {
      setPageOffset(1);
      refreshList();
      setVisible(false);
    });
  };

  const onEditBtn = (record: RowInfo) => {
    // 编辑时, 要确定设备或相关的数据属性有没有解绑
    apiV2BsaRemoteControlCommandGetPost({ id: record.commandId }).then(_ => {
      setCurrentRow({ ...record });
      setOpType('edit');
      setVisible(true);
    });
  };
  const opColunms = [
    {
      title: '操作',
      width: 110,
      fixed: 'right' as const,
      render: (v: string, record: RowInfo) => (
        <Space size={12}>
          <Button type="link" disabled={!isCanEdit} style={{ padding: '4px 0' }} onClick={() => onEditBtn(record)}>
            编辑
          </Button>
          <PopConfirm
            placement="bottomRight"
            title={`你确定要删除${record.commandName ?? ''}指令吗?`}
            okText="确认"
            cancelText="取消"
            onConfirm={() => deleteBtn(record)}
          >
            <Button disabled={!isCanEdit} type="link" style={{ padding: '4px 0' }}>
              删除
            </Button>
          </PopConfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className={styles.button_box}>
        <Button icon={<PlusOutlined />} disabled={!isCanEdit} onClick={controlBtn} type="primary">
          添加控制指令
        </Button>
      </div>

      <div className={styles.table_box}>
        <Table loading={tableLoading} rowKey="commandId" dataSource={list} columns={[...columns, ...opColunms]} />
      </div>

      <Paging pagingInfo={pagingInfo} />

      {visible && (
        <Modal
          open
          maskClosable={false}
          title={`${modalTile}控制指令`}
          confirmLoading={submitLoading}
          onOk={confirmBtn}
          onCancel={() => setVisible(false)}
        >
          <div style={{ padding: '0 40px' }}>
            <Form form={form} labelCol={{ flex: 80 }} preserve={false}>
              <Row>
                <Col span={24}>
                  <Form.Item label="指令名称" name="commandName" rules={[{ required: true }]}>
                    <Input maxLength={30} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="按钮名称" name="buttonName" rules={[{ required: true }]}>
                    <Input maxLength={8} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="应用设备" name="deviceId" rules={[{ required: true }]}>
                    <Select
                      onChange={() => {
                        form.setFieldsValue({
                          dataPropertyId: undefined,
                          targetValue: undefined,
                        });
                      }}
                      showSearch
                      disabled={opType === 'edit'}
                      optionFilterProp="label"
                      options={deviceList?.map(item => ({
                        label: `${getLastTypeName(item.deviceTypeName)}${
                          item.physicalModelNo ? '-' + item.physicalModelNo + '-' : '-'
                        }${item.deviceName}`,
                        value: item.deviceId,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div style={{ paddingLeft: 135, color: 'rgba(255,255,255,0.65)', marginTop: -10, paddingBottom: 5 }}>
                    仅支持驱动类型为IEC104、MODBUS TCP或RTU的设备
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item label="目标数据属性" name="dataPropertyId" rules={[{ required: true }]}>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      disabled={!deviceId}
                      onChange={() => {
                        form.setFieldsValue({
                          targetValue: undefined,
                        });
                      }}
                      options={propertyList?.map(item => ({
                        label: item.dataPropertyName,
                        value: item.dataPropertyId,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {/* 枚举值为下拉框 */}
                  {dataPropertyType === DataPropertyType.YC ? (
                    <>
                      <Form.Item
                        label="目标值"
                        name="targetValue"
                        style={{ width: '70%', display: 'inline-flex' }}
                        rules={[
                          { required: true },
                          { pattern: /^(\-{0,1})[1-9][0-9]*([\.][0-9]{1,})?$/, message: '请输入数字' },
                        ]}
                      >
                        <Input disabled={!dataPropertyId} />
                      </Form.Item>
                      <Form.Item
                        name="allowCustom"
                        valuePropName="checked"
                        style={{ width: '30%', display: 'inline-flex' }}
                        initialValue={true}
                      >
                        <Checkbox>下发时可修改</Checkbox>
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item label="目标值" name="targetValue" rules={[{ required: true }]}>
                      <Select disabled={!dataPropertyId} options={targetValueList} />
                    </Form.Item>
                  )}
                </Col>

                <Col span={24}>
                  <SendValueDom sendValue={sendValue} dataPropertyType={dataPropertyType} k={k} b={b} />
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};
export default OrderTableList;
