import styles from './index.module.scss';
import {
  useBreadcrumbRoutes,
  Form,
  Modal,
  Wrapper,
  Button,
  ShowInput,
  BaseInfoContent,
  SubContent,
} from '@maxtropy/components';
import FormHeader from '../../../components/FormHeader';
import { Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import StrategyCalendar from '../../../components/StrategyCalendar';
import { fetchLocalDetail, launchStrategy, getTemplateDetail, TemplateDetail, StrategyVersion } from '@/api/strategy';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import FcsFormItemDetail from './FcsFormItemDetail';

interface LaunchStrategyProps {
  isCopy?: boolean;
}

const LaunchStrategy: React.FC<LaunchStrategyProps> = ({ isCopy }) => {
  const routes = [{ name: '策略投放' }];
  const routesContext = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id, bsaId, localId } = useParams<{ id: string; bsaId: string; localId: string }>();

  const [visible, setVisible] = useState(false);
  const [existingStrategyNumber, setExistingStrategyNumber] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [templateDetail, setTemplateDetail] = useState<TemplateDetail>();

  // const { data: templateDetail } = useQuery(['templateDetail', id], () => fetchStrategyTemplateDetail(+id));
  const [form] = Form.useForm();
  useEffect(() => {
    if (isCopy) {
      fetchLocalDetail(localId, bsaId).then(res => {
        form.setFieldsValue({
          ...res,
          version: res.sysVersionId,
        });
      });
    }
  }, [localId, bsaId, isCopy, form]);

  const onSubmit = () => {
    form.validateFields().then(_ => {
      setVisible(true);
    });
  };

  const onOk = () => {
    setVisible(false);
    setIsSubmitting(true);
    launchStrategy({
      id: +id,
      name: templateDetail?.name!,
      executiveTimes: values.times.map((i: string) => ({ day: i, version: values.version })),
    })
      .onError(err => {
        const { errorMessage } = err?.cause;
        Modal.error({
          title: errorMessage,
        });
        throw err;
      })
      .then(res => {
        if (!res) {
          return;
        }
        let ouName = templateDetail?.ouName ?? '--';
        let bsaName = templateDetail?.bsaName ?? '--';
        let version = templateDetail?.version ?? '--';
        let name = templateDetail?.name ?? '--'; // 策略名
        if (res.responseDto.result) {
          Modal.success({
            content: `向${ouName}的${bsaName}${isCopy ? '复制' : '投放'}${name}, 系统版本号${version}成功${
              res.responseDto.message ? `,由于命名冲突，策略命名为${res.responseDto.message}` : ''
            }`,
            okText: '确定',
            onOk: () => navigate(`/dmes/energy-storage/strategy`),
          });
        } else {
          Modal.error({
            content: `向${ouName}的${bsaName}${isCopy ? '复制' : '投放'}${name}, 系统版本号${version}失败，原因是在${
              res.responseDto.message
            }`,
          });
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const values = form.getFieldsValue();

  // 获取策略模板详情
  useEffect(() => {
    if (id) {
      getTemplateDetail(id).then(res => {
        setTemplateDetail(res);
      });
    }
  }, [id]);
  return (
    <>
      <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className="wrapper-padding">
        <FormHeader title={'策略投放'} onSubmit={onSubmit} isSubmitting={isSubmitting} submitButtonText={'投放'} />
        <Form isOrigin={true}>
          <SubContent>
            <BaseInfoContent colNum={3} layout="horizontal">
              <Form.Item label={`${isCopy ? '被复制' : '拟'}投放策略模板`}>
                <ShowInput value={templateDetail?.name} />
              </Form.Item>
              <Form.Item label="策略类型版本">
                <ShowInput value={templateDetail?.version} />
              </Form.Item>
              <Form.Item label="拟投放储能站">
                <ShowInput
                  value={templateDetail?.bsaName}
                  btn={
                    <Button
                      type="link"
                      onClick={() => window.open(`/energy-storage/basic/bsa/detail/${templateDetail?.bsaId}`, '_blank')}
                    >
                      查看储能站
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item label="所属全域能源拓扑">
                <ShowInput value={templateDetail?.uetName} />
              </Form.Item>
              <Form.Item label="所属运营单元">
                <ShowInput value={templateDetail?.ouName} />
              </Form.Item>
              {templateDetail?.sysVersionId === StrategyVersion.v2 && (
                <Form.Item label="装机容量">
                  <ShowInput value={templateDetail?.installedCapacity} />
                </Form.Item>
              )}
            </BaseInfoContent>
          </SubContent>
        </Form>
        {/* FCS信息显示 */}
        <FcsFormItemDetail strategyVersion={templateDetail?.sysVersionId} detail={templateDetail} />

        <SubContent>
          <Form form={form} isOrigin>
            <Row>
              <Col span={24}>
                <Form.Item name="times">
                  <StrategyCalendar
                    isForm
                    bsaId={+bsaId}
                    setExistingStrategyNumber={v => setExistingStrategyNumber(existingStrategyNumber + v)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SubContent>
      </Wrapper>
      <Modal
        open={visible}
        title="投放策略模板"
        onCancel={() => setVisible(false)}
        onOk={onOk}
        okText="确定投放"
        cancelText="我再检查一下"
      >
        {visible && (
          <>
            <p>
              即将向
              <span className={styles.blue}>FCS{templateDetail?.bsaName}</span>
              {isCopy ? '复制' : '投放'}
              <span className={styles.blue}>
                {templateDetail?.name}, 系统版本号{templateDetail?.version ?? '--'}
              </span>
              ,预计命名为
              <span className={styles.blue}>{templateDetail?.name}</span>
            </p>
            <p>
              该策略模板预计将在
              <span className={styles.blue}>
                {dayjs(values.times[0]).format('YYYY年MM月DD日')}-
                {dayjs(values.times[values.times.length - 1]).format('YYYY年MM月DD日')}
              </span>
              之间的共
              <span className={styles.blue}>{values.times.length}</span>天生效，其中
              <span className={styles.blue}>{existingStrategyNumber}</span>天将替换原来设计的策略
            </p>
          </>
        )}
      </Modal>
    </>
  );
};

export default LaunchStrategy;
