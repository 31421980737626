import { getProjectTotalIncome, ProjectTotalIncomeRes } from '@/api/EnergyAssetProjectIncome';
import { Empty } from '@maxtropy/components';
import { Spin } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { EnergyAssetIncome } from '../..';
import HeadTitle from '../HeadTitle/HeadTitle';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import { StationCountRes } from '@/api/energyAssetOverview';
import ResizeObserver from 'rc-resize-observer';
import { StatisticsPartition } from '../DateSwitch';

export interface IProjectTotalIncome {
  stationHasCount?: StationCountRes;
}

const ProjectTotalIncome: FC<IProjectTotalIncome> = props => {
  const { stationHasCount } = props;
  const energyAssetIncomeValue = useContext(EnergyAssetIncome);
  const [projectTotalIncomeData, setProjectTotalIncomeData] = useState<ProjectTotalIncomeRes[]>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [chartWidth, setChartWidth] = useState<number>();

  useEffect(() => {
    if (energyAssetIncomeValue) {
      setDataLoading(true);
      getProjectTotalIncome({ ...energyAssetIncomeValue }).then(res => {
        setProjectTotalIncomeData(res.list ?? []);
        setDataLoading(false);
      });
    }
  }, [energyAssetIncomeValue]);

  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: function (params: any) {
          let str =
            `<span style='display:inline-block;border-radius:10px;'>${
              energyAssetIncomeValue?.resolution === StatisticsPartition.DAY
                ? dayjs(params[0]?.data[0])?.format('YYYY-MM-DD')
                : dayjs(params[0]?.data[0])?.format('YYYY-MM')
            }</span>` +
            '<br>' +
            `总收益：<span style='display:inline-block;border-radius:10px;color:#00ADFF;'>${
              !isNil(params[0]?.data[3])
                ? Number(params[0]?.data[3])?.toLocaleString('zh', {
                    maximumFractionDigits: 2,
                  })
                : '--'
            }元</span>`;
          params.forEach((item: any) => {
            const { seriesName, marker } = item;
            // const value = !isNil(data[1]) ? `${data[1].toFixed(2)}` : '--';
            str += `<br> ${marker}${seriesName}: <span style='display:inline-block;border-radius:10px;width:148px'>${
              !isNil(item?.data[1])
                ? Number(item?.data[1])?.toLocaleString('zh', {
                    maximumFractionDigits: 2,
                  }) + '元'
                : '--'
            }</span>环比：${
              item.data[2] && item.data[2] > 0
                ? `<span style='display:inline-block;border-radius:10px;color:#FF4D4F'>${
                    !isNil(item?.data[2]) ? '+' + (Number(item.data[2]) * 100).toFixed(2) + '%' : '--'
                  }</span>`
                : `<span style='display:inline-block;border-radius:10px;color:#59D744'>${
                    !isNil(item?.data[2]) ? +(Number(item.data[2]) * 100).toFixed(2) + '%' : '--'
                  }</span>`
            }`;
          });
          return str;
        },
      },
      legend: {
        top: '2%',
        right: '3%',
        y: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 12, //字体大小
          color: 'rgba(255,255,255,0.65)', //字体颜色
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'time',
          minInterval:
            energyAssetIncomeValue?.resolution === StatisticsPartition.DAY
              ? 60 * 1000 * 60 * 24
              : 60 * 1000 * 60 * 24 * 30,
          axisLabel: {
            formatter: function (e: number) {
              if (energyAssetIncomeValue?.resolution === StatisticsPartition.DAY) {
                return dayjs(e, 'x').format('MM-DD');
              } else {
                return dayjs(e, 'x').format('YYYY-MM');
              }
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '元',
          nameTextStyle: {
            fontSize: 14,
            align: 'right',
          },
          nameGap: 30,
          splitLine: {
            lineStyle: {
              color: '#FFFFFF30',
            },
          },
        },
      ],
      series: [
        stationHasCount?.pvCount
          ? {
              name: '光伏站',
              type: 'bar',
              stack: 'Ad',
              color: '#16DD8E',
              emphasis: {
                focus: 'series',
              },
              data: projectTotalIncomeData?.map(i => [
                i.incomeDate,
                i.pvIncome.income,
                i.pvIncome.incomeRate,
                i.incomeCount,
              ]),
            }
          : undefined,
        stationHasCount?.bsaCount
          ? {
              name: '储能站',
              type: 'bar',
              stack: 'Ad',
              color: '#2D8DFF',
              emphasis: {
                focus: 'series',
              },
              data: projectTotalIncomeData?.map(i => [
                i.incomeDate,
                i.bsaIncome.income,
                i.bsaIncome.incomeRate,
                i.incomeCount,
              ]),
            }
          : undefined,
        stationHasCount?.evCount
          ? {
              name: '充电站',
              type: 'bar',
              stack: 'Ad',
              color: '#52E7FF',
              emphasis: {
                focus: 'series',
              },
              data: projectTotalIncomeData?.map(i => [
                i.incomeDate,
                i.evIncome.income,
                i.evIncome.incomeRate,
                i.incomeCount,
              ]),
            }
          : undefined,
      ],
    };
  };

  return (
    <div className={styles.ColSty}>
      <div className={styles.ColContent}>
        <HeadTitle title="项目总收益" />
        <ResizeObserver onResize={({ width }) => setChartWidth(width)}>
          <Spin spinning={dataLoading}>
            {!stationHasCount?.pvCount && !stationHasCount?.evCount && !stationHasCount?.bsaCount ? (
              <Empty style={{ paddingTop: '6%' }} description={'无光伏站，储能站，充电站'} />
            ) : (
              <ReactEcharts
                option={getOption()}
                notMerge
                lazyUpdate={false}
                style={{ height: '58vh', width: chartWidth }} // 样式的设置width与height
              />
            )}
          </Spin>
        </ResizeObserver>
      </div>
    </div>
  );
};

export default ProjectTotalIncome;
