import { isNil } from 'lodash-es';

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return data.toFixed(2);
  }
  return '--';
};

export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return data.toFixed(2);
  } else {
    return null;
  }
};

export const keepTwoOrLinePercent = (data?: number) => {
  if (!isNil(data)) {
    return data > 0 ? '+' + (data * 100).toFixed(2) + '%' : (data * 100).toFixed(2) + '%';
  }
  return '--';
};
