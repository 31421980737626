import React, { useEffect, useMemo, useState, useContext } from 'react';
import { App, Row, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { useQuery } from 'react-query';
import { getBsaBindingPcsList } from '@/api/bsa';
import {
  DeviceData,
  getDeviceDataInstantaneous,
  getDeviceDataInstantaneousFor1m,
  getDeviceDataInstantaneousForRaw,
  GrainChoice,
} from '@/api/device';
import { StorageContext } from '../StorageContext';
import { PcsDevice } from '@/api/entity';
import { timeGapMap } from './BatteryTemper';
import { isNil, isNumber } from 'lodash-es';
import { DatePicker, Empty, Radio, Select } from '@maxtropy/components';

const { RangePicker } = DatePicker;

export interface PcsTabProps {
  bsaId: number;
}

enum TimeChoice {
  TODAY,
  LAST_24_HOURS,
  CUSTOM,
}

const POWER_PROPERTY_ID = 10061;
const ALL_OPTION_VALUE = -1;

const PcsTabContent: React.FC<PcsTabProps> = props => {
  const { bsaId } = props;
  const StorageContextValue = useContext(StorageContext);
  const { message } = App.useApp();
  const disabledDate = (current: Dayjs) => {
    return current && current.valueOf() >= dayjs().endOf('day').valueOf();
  };
  const [selectedPcs, setSelectedPcs] = useState<number[]>([]);

  const [from, to] = useMemo(() => {
    if (StorageContextValue?.timeChoice === TimeChoice.TODAY) {
      return [dayjs().startOf('day').valueOf(), dayjs().endOf('day').valueOf()];
    } else if (StorageContextValue?.timeChoice === TimeChoice.LAST_24_HOURS) {
      return [dayjs().subtract(24, 'hours').valueOf(), dayjs().valueOf()];
    } else if (StorageContextValue?.customDateRange) {
      return [
        StorageContextValue?.customDateRange[0].startOf('day').valueOf(),
        StorageContextValue?.customDateRange[1].endOf('day').valueOf(),
      ];
    }
    return [undefined, undefined];
  }, [StorageContextValue?.customDateRange, StorageContextValue?.timeChoice]);

  const { data: pcsList } = useQuery(['pcsList', bsaId], () => getBsaBindingPcsList(bsaId), { enabled: !!bsaId });

  const requestApi = useMemo(() => {
    return StorageContextValue?.grainChoice === GrainChoice.ORIGINAL
      ? getDeviceDataInstantaneousForRaw
      : StorageContextValue?.grainChoice === GrainChoice.MINUTE_1
      ? getDeviceDataInstantaneousFor1m
      : getDeviceDataInstantaneous;
  }, [StorageContextValue?.grainChoice]);

  // 默认选中
  useEffect(() => {
    if (pcsList && pcsList.length !== 0) {
      setSelectedPcs(pcsList.map(item => item.deviceId).slice(0, 20));
    }
  }, [pcsList]);

  const { data: pcsPowerData, isLoading: isPowerLoading } = useQuery(
    ['power', selectedPcs, from, to, StorageContextValue?.grainChoice],
    () =>
      requestApi({
        deviceIds: selectedPcs!,
        propertyIds: [POWER_PROPERTY_ID],
        startTime: from!,
        endTime: to!,
      }),
    {
      enabled: !!from && !!to && !!selectedPcs.length,
    }
  );

  const isDataEmpty = useMemo(() => {
    return !pcsPowerData?.some(item => item.values.some(v => !isNil(v.value)));
  }, [pcsPowerData]);

  return (
    <div>
      <Row style={{ padding: '0 20px' }} gutter={[30, 12]} align={'middle'}>
        <Space size={20}>
          <Radio.Group
            buttonStyle={'solid'}
            value={StorageContextValue?.timeChoice}
            onChange={e => {
              StorageContextValue?.setTimeChoice(e.target.value);
            }}
          >
            <Radio.Button value={TimeChoice.TODAY}>当日</Radio.Button>
            <Radio.Button value={TimeChoice.LAST_24_HOURS}>最近24小时</Radio.Button>
            <Radio.Button value={TimeChoice.CUSTOM}>其他</Radio.Button>
          </Radio.Group>
          <Space>
            颗粒度:
            <Radio.Group
              buttonStyle={'solid'}
              value={StorageContextValue?.grainChoice}
              onChange={e => {
                StorageContextValue?.setGrainChoice(e.target.value);
              }}
            >
              <Radio.Button value={GrainChoice.ORIGINAL}>原始数据</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_1}>1分钟</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_15}>15分钟</Radio.Button>
            </Radio.Group>
          </Space>
          {StorageContextValue?.timeChoice === TimeChoice.CUSTOM && (
            <Space>
              选择日期:
              <RangePicker
                value={StorageContextValue?.customDateRange}
                disabledDate={disabledDate}
                onChange={e => {
                  const value = e as [dayjs.Dayjs, dayjs.Dayjs];
                  const timeGap = value[1].endOf('day').diff(value[0].startOf('day'), 'days', true);
                  if (timeGap > timeGapMap[StorageContextValue?.grainChoice]) {
                    return message.error(`最大跨度为${timeGapMap[StorageContextValue?.grainChoice]}天`);
                  }
                  StorageContextValue?.setCustomDateRange(value);
                }}
              />
            </Space>
          )}
          <Space>
            <div style={{ width: '75px' }}>数据范围:</div>
            <Select
              mode={'multiple'}
              allowClear
              placeholder={'请选择'}
              style={{ width: 300 }}
              maxTagCount={2}
              value={selectedPcs}
              onChange={values => {
                if (values.includes(ALL_OPTION_VALUE)) {
                  setSelectedPcs(pcsList!.map(item => item.deviceId).slice(0, 20));
                } else {
                  if (values.length > 20) {
                    return message.error('最多选择20个');
                  }
                  setSelectedPcs(values);
                }
              }}
            >
              {pcsList?.length && <Select.Option value={ALL_OPTION_VALUE}>全选 (前20个)</Select.Option>}
              {pcsList?.map(item => (
                <Select.Option key={item.deviceId} value={item.deviceId}>
                  {item.deviceName}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </Space>
      </Row>

      <Spin spinning={isPowerLoading}>
        <div style={{ marginTop: 30, height: 'calc(100vh - 303px)' }}>
          {isDataEmpty && !isPowerLoading ? (
            <Empty style={{ paddingTop: 120 }} />
          ) : (
            <ReactEcharts
              option={getOption(from!, to!, pcsPowerData, pcsList, StorageContextValue?.grainChoice)}
              theme={'dark'}
              style={{ height: '100%' }}
              notMerge
              lazyUpdate={false}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

const getOption = (
  from: number,
  to: number,
  pcsPowerData?: DeviceData[],
  pcsList?: PcsDevice[],
  grainChoice?: GrainChoice
) => {
  let showMin: boolean = false,
    showMax: boolean = false;
  let pcsPower: number = 0;
  const powerSeries =
    pcsPowerData?.map(item => {
      let pcsData = pcsList?.find(pcs => item.deviceId === pcs.deviceId);
      let currentPower = isNumber(Number(pcsData?.power)) ? Number(pcsData?.power) : 0;
      let kWPower = pcsData?.powerUnit === 'W' ? currentPower / 1000 : currentPower;
      pcsPower = kWPower > pcsPower ? kWPower : pcsPower;

      showMin = item.values.every(v => v.value > -pcsPower);
      showMax = item.values.every(v => v.value < pcsPower);

      let lengendName = pcsData?.deviceName;
      return {
        connectNulls: true,
        name: lengendName ?? 'PCS功率',
        type: 'line',
        data: item.values.map(v => [v.time, v.value]),
        smooth: true,
        yAxisIndex: 0,
      };
    }) || [];

  const powerYAxis = {
    name: pcsList?.some(item => isNil(item.power)) ? 'kW（PCS未配置额定功率）' : 'kW',
    type: 'value',
    nameTextStyle: { align: 'left', padding: [5, 10] },
    scale: true,
    min: showMin ? -pcsPower : null,
    max: showMax ? pcsPower! : null,
    splitLine: {
      lineStyle: {
        color: 'rgba(255,255,255,0.35)',
      },
    },
  };

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: { left: 80, right: 80 },
    legend: {
      show: true,
      right: 100,
      top: 0,
      icon: 'rect',
      textStyle: {
        color: '#AFBCC4',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any[]) => {
        return params.reduce(
          (acc, curr) =>
            acc + `<span >${curr.marker} ${curr.seriesName}：</span> ${curr.data[1]?.toFixed(1) || '--'}kW<br />`,
          `${params[0].axisValueLabel}<br />`
        );
      },
    },
    dataZoom: [
      {
        type: 'slider',
        start: grainChoice === GrainChoice.ORIGINAL ? 98 : grainChoice === GrainChoice.MINUTE_1 ? 80 : 0,
        end: 100,
        bottom: 15,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    xAxis: {
      // min: from,
      // max: to,
      minInterval: 60 * 1000,
      type: 'time',
      axisLabel: {
        color: 'rgba(255,255,255,0.6)',
        fontSize: 12,
        formatter: (val: number) => dayjs(val, 'x').format('MM-DD[\n]HH:mm'),
        // formatter: (val: number) =>
        //   dayjs(val).format('mm').includes('00') ? dayjs(val).format('H点') : dayjs(val).format('H点mm分'),
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: powerYAxis,
    series: [...powerSeries],
  };
};

export default PcsTabContent;
