import { Table, EllipsisSpan, Modal } from '@maxtropy/components';
import { DeviceProps, getDeviceListByOuId } from '../../../../api/bsa';
import React, { useContext, useEffect, useState } from 'react';
import { BSAStatus } from '../../../../api/type';
import { DeviceContext, DeviceType } from './index';

interface Iprops {
  visible: boolean;
  title?: string;
  deviceType?: DeviceType;
  opType: string;
  openModal?: (status: boolean) => void;
  exsitData?: DeviceProps[];
  onConfirm?: (data: DeviceProps[]) => void;
  children?: React.ReactNode;
}
interface ColumnsType {
  dataIndex: string;
  label: string;
}
const SelectModal: React.FC<Iprops> = ({ visible, deviceType, children, opType, openModal, exsitData, onConfirm }) => {
  const { status, ouId } = useContext(DeviceContext);
  const [itemList, setItemList] = useState<DeviceProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [slefVisible, setSelfVisible] = useState<boolean>(false);
  // 查询列表
  useEffect(() => {
    if (visible) {
      setLoading(true);
      getDeviceListByOuId({
        deviceType,
        ouId,
      })
        .then(res => {
          setItemList(res.list ?? []);
        })
        .finally(() => setLoading(false));
    }
  }, [visible, deviceType, ouId]);
  useEffect(() => {
    setSelfVisible(visible);
  }, [visible]);

  // 编辑时, 查询列表数据, 和已经选择的值比对, 一样时置灰
  let rowsData: DeviceProps[];
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DeviceProps[]) => {
      rowsData = selectedRows;
    },
    getCheckboxProps: (rows: DeviceProps) => ({
      disabled: exsitData?.some(v => v.deviceId === rows.deviceId),
      deviceId: rows.deviceId,
    }),
  };
  const columsData: ColumnsType[] = [
    { label: '设备名称', dataIndex: 'deviceName' },
    { label: '设备编号', dataIndex: 'deviceCode' },
  ];
  const propsColums = columsData.map(v => ({
    title: v.label,
    dataIndex: v.dataIndex,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  }));
  const columns = [
    {
      title: '序号',
      dataIndex: 'order',
      ellipsis: { showTitle: true },
      render: (v: string, rows: DeviceProps, index: number) => <EllipsisSpan value={index + 1} />,
    },
    ...propsColums,
  ];
  return (
    <>
      <Modal
        destroyOnClose
        title={children}
        maskClosable={false}
        open={slefVisible}
        onCancel={() => {
          openModal?.(false);
        }}
        onOk={() => {
          if (!rowsData || rowsData.length === 0) {
            Modal.warning({
              title: '请选择设备',
            });
            return;
          }
          if (opType === 'edit' && status === BSAStatus.ENABLE) {
            Modal.confirm({
              title: `更新设备信息`,
              content: `更新设备信息,会造成需要重新配置FCS相关配置并重新下发,确定要更新吗？`,
              onOk: () => {
                onConfirm && onConfirm(rowsData);
                openModal?.(false);
              },
            });
            return;
          }
          onConfirm && onConfirm(rowsData);
          openModal?.(false);
        }}
      >
        <Table
          loading={loading}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          rowKey="deviceId"
          columns={columns}
          dataSource={itemList}
        />
      </Modal>
    </>
  );
};
export default SelectModal;
