import { FormInstance } from 'antd';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import FormItemWrapper from './FormItemWrapper';
import EffectiveTimePicker, { EffectiveTimeMode } from './EffectiveTimePicker';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import EditableTable, { EditableTableRef, Item } from './EditableTable';
import { MonitoringIndicatorProps } from '../../api/circuit';
import { useQuery } from 'react-query';
import { queryDeviceListByCircuitId } from '../../api/device';
import { queryDataPropertyList } from '../../api/dataProperty';
import { Form, Select } from '@maxtropy/components';

export interface BatchBindFormRef {
  dataSource: Item[];
  validate?: () => Promise<any>;
}

interface BatchBindFormProps {
  selectedRows: Partial<MonitoringIndicatorProps>[];
  form: FormInstance;
  visible: boolean;
}

const BatchBindForm: React.ForwardRefRenderFunction<BatchBindFormRef, BatchBindFormProps> = (props, ref) => {
  const { form, selectedRows } = props;
  const [deviceId, setDeviceId] = useState<number>();
  const [dataSource, setDataSource] = useState<Item[]>([]);
  const tableRef = useRef<EditableTableRef | null>(null);

  const { data: devices } = useQuery(['deviceListByCircuit', selectedRows[0]?.circuitId], async () => {
    if (!selectedRows[0]?.circuitId) return [];
    const res = await queryDeviceListByCircuitId(selectedRows[0].circuitId);
    return (res.list ?? []).map(device => ({ label: device.name, value: device.id }));
  });

  const { data: dataProperties = [] } = useQuery(['dataPropertyList', deviceId], async () => {
    if (!deviceId) return [];
    const res = await queryDataPropertyList(deviceId);
    setDataSource(
      selectedRows.map((row, index) => ({
        id: index,
        circuitPhysicalQuantityId: row!.circuitPhysicalQuantityId,
        circuitPhysicalQuantityName: row!.circuitPhysicalQuantityName,
        dataPropertyId:
          deviceId === row.deviceId ? (row.dataPropertyId ? Number(row.dataPropertyId) : undefined) : undefined,
        deviceId: row.deviceId,
        checked: false,
      }))
    );
    return (res.list ?? []).map(dataProperty => ({ label: dataProperty.name, value: dataProperty.id }));
  });

  useEffect(() => {
    setDataSource(
      selectedRows.map((row, index) => ({
        id: index,
        circuitPhysicalQuantityId: row!.circuitPhysicalQuantityId,
        circuitPhysicalQuantityName: row!.circuitPhysicalQuantityName,
        dataPropertyId: undefined,
        checked: false,
      }))
    );

    return () => {
      setDataSource([]);
    };
  }, [selectedRows]);

  useImperativeHandle(ref, () => ({
    dataSource,
    validate: tableRef.current?.validate,
  }));

  return (
    <Form form={form}>
      <Form.Item label="设备选择" name="deviceId" rules={[{ required: true, message: '请选择设备' }]}>
        <FormItemWrapper extra="如果没有找到设备，请到该回路下绑定设备">
          <Select options={devices} placeholder="请输入/选择要绑定的设备" onChange={setDeviceId} />
        </FormItemWrapper>
      </Form.Item>
      <Form.Item
        label="选择数据生效时间"
        name="effectiveTime"
        required
        rules={[{ required: true, message: '请选择数据生效时间' }]}
      >
        <EffectiveTimePicker mode={EffectiveTimeMode.BIND} />
      </Form.Item>
      <EditableTable
        ref={tableRef}
        dataProperties={dataProperties}
        dataSource={dataSource}
        setDataSource={setDataSource}
      />
      <Form.Item {...{ wrapperCol: { span: 24 } }}>
        <span className={styles.prompt}>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
          <span>
            请注意绑定/变更数据存在一定的滞后性，任务处理期间不能编辑该页面，数据处理完成后将恢复正常，清空对应数据属性后不执行指标更改操作，保留原设置
          </span>
        </span>
      </Form.Item>
    </Form>
  );
};

export default React.forwardRef(BatchBindForm);
