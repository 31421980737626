import { PageRequest, PageResponse } from '@/api/page';
import { fetch } from '@maxtropy/components';

export const pvInverterKeys = {
  all: ['inverters'],
  list: (pvId: number) => [...pvInverterKeys.all, pvId],
  page: (filters: InverterPageQuery) => [...pvInverterKeys.all, { filters }],
};

export interface PVInverter {
  id: number;
  code: string;
  deviceCode: string;
  deviceId: number;
  deviceName: string;
  combinerBoxId: number;
  combinerBoxCode: string;
}

export interface InverterPayload {
  id?: number;
  code: string;
  deviceId?: number;
  combinerBoxId: number;
  pvId: number;
}

export interface InverterQuery {
  pvId: number;
  code?: string;
  combinerBoxId?: number;
}

export interface InverterImportError {
  pvCombinerBoxCode: string;
  deviceCode: string;
  pvInverterCode: string;
  errorMessage: string;
}

export type InverterPageQuery = InverterQuery & PageRequest;

export const getInverterPage = (params: InverterPageQuery) =>
  fetch<PageResponse<PVInverter>>(
    `/api/v2/pv-inverter/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const createInverter = (data: InverterPayload) =>
  fetch<void>(
    '/api/v2/pv-inverter/create',
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  );

export const updateInverter = (data: InverterPayload) =>
  fetch<void>(
    '/api/v2/pv-inverter/update',
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  );

export const deleteInverter = (id: number) =>
  fetch<void>(
    `/api/v2/pv-inverter/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const removeInverterDevice = (id: number) =>
  fetch<void>(
    '/api/v2/pv-inverter/remove-device',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const updateInverterDevice = (id: number, deviceId: number) =>
  fetch<void>(
    '/api/v2/pv-inverter/update-device',
    {
      method: 'POST',
      body: JSON.stringify({ id, deviceId }),
    },
    true
  );

export const deleteAllInverters = (id: number) =>
  fetch<void>(
    '/api/v2/pv-inverter/delete-all',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const exportInverters = (pvId: number) => window.open(`/api/v2/pv-inverter/output/${pvId}`, '__blank');

export const getInverterList = (id: number) =>
  fetch<{ list: PVInverter[] }>(
    '/api/v2/pv-inverter/list',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);

export const downloadInverterTemplate = () => window.open(`/api/v2/pv-inverter/download`, '__blank');

export const uploadInverterExcel = (pvId: number, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('pvId', pvId.toString());
  return fetch<{ list: InverterImportError[] }>(
    `/api/v2/pv-inverter/input`,
    {
      method: 'POST',
      body: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    true
  ).then(res => res.list ?? []);
};
