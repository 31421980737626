import { FC, useEffect, useMemo, useState } from 'react';

import {
  Breadcrumb,
  combineURL,
  EllipsisSpan,
  Table,
  useBreadcrumbRoutes,
  Select,
  Form,
  Modal,
  Button,
} from '@maxtropy/components';
import BorderWrapper from '../../../../components/BorderWrapper';

import BsaSteps from '../BsaSteps';
import BsaPageHeader from '../BsaPageHeader';

import { Row, Col, Spin, Space } from 'antd';

import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import { BsaComponentsProps, getNext, getPrevious } from '../../factory';
import { getModel, ModelResponse } from '../../../../api/model';
import { debounce } from 'lodash-es';
import {
  BsaDeviceTemplateRequest,
  createRackDevice,
  DeviceTreeProps,
  getDeviceTypeTree,
  rackStatusCheck,
  submitRackDevice,
} from '../../../../api/device';
import { BsaType } from '../../../../api/constans';
import classNames from 'classnames';
import { RackDevice } from '../../../../api/entity';
import { getDraft, getRackDeviceList, saveDraft } from '../../../../api/bsa';
import { BSAStatus, DeviceStatus, DeviceStatusDisplay, ProcessStatusEnum } from '../../../../api/type';
import { padStart } from '../../utils';

const columns = [
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '对应PCS',
    dataIndex: 'pcsName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电池堆编号',
    dataIndex: 'stackSequence',
    render: (v: number) => <EllipsisSpan value={padStart(v)} />,
  },
  {
    title: '电池簇编号',
    dataIndex: 'sequence',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={padStart(v)} />,
  },
  {
    title: '类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电池簇能量',
    dataIndex: 'energy',
    ellipsis: { showTitle: true },
    render: (v: string, record: RackDevice) => (
      <EllipsisSpan value={v ? `${v ?? ''} ${record.energyUnit ?? ''}` : undefined} />
    ),
  },
  {
    title: '额定电压',
    dataIndex: 'voltage',
    ellipsis: { showTitle: true },
    render: (v: string, record: RackDevice) => (
      <EllipsisSpan value={v ? `${v ?? ''} ${record.voltageUnit ?? ''}` : undefined} />
    ),
  },
  {
    title: '设备状态',
    dataIndex: 'deviceStatus',
    ellipsis: { showTitle: true },
    render: (value: DeviceStatus) => {
      return <EllipsisSpan value={DeviceStatusDisplay[value]} />;
    },
  },
  {
    title: '操作',
    width: 180,
    fixed: 'right' as const,
    render: (v: undefined, record: RackDevice) => (
      <a
        target="_blank"
        href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/${record.deviceId}/detail`)}
        rel="noreferrer"
      >
        查看详情
      </a>
    ),
  },
];

export const formatTreeData = (tree: DeviceTreeProps[]) => {
  let res: DeviceTreeProps[] = [];
  tree.forEach(i => {
    if (i.name === '储能电池组件') {
      res = res.concat(i.children ?? []);
    }
  });
  return res.filter(i => i.name.includes('电池簇'));
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

const routes = [{ name: `电池簇设置` }];

const BsaRack: FC<BsaComponentsProps> = ({
  typeList,
  status,
  disabled,
  foucsType,
  currentType,
  changeType,
  updateFn,
}) => {
  const routesContext = useBreadcrumbRoutes();

  const { id } = useParams<{ id: string }>();

  // const [updateState, update] = useUpdate();

  const [loading, setLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const isInit = useMemo(() => {
    return (typeList ?? []).includes(BsaType.RACK_DEVICE_CREATE);
  }, [typeList]);

  // const isDeviceCreated = useMemo(() => {
  //   return currentType === BsaType.RACK_DEVICE_CREATED
  // }, [currentType])

  const isComplete = useMemo(() => {
    return (typeList ?? []).includes(BsaType.RACK_DEVICE_COMPLETED);
  }, [typeList]);

  /** complete */

  const [racks, setRacks] = useState<RackDevice[]>([]);

  useEffect(() => {
    if (id) {
      if (isComplete || currentType === BsaType.RACK_DEVICE_CREATED) {
        setTableLoading(true);
        getRackDeviceList(id).then(res => {
          setRacks(res.list ?? []);
          setTableLoading(false);
        });
      }
    }
  }, [id, isComplete, currentType]);

  useEffect(() => {
    if (id) {
      if (currentType === BsaType.RACK_DEVICE_CREATE) {
        // 未完成，查看是否有草稿
        getDraft(id).then(draft => {
          const content: Partial<Omit<BsaDeviceTemplateRequest, 'bsaId'>> = draft.content
            ? JSON.parse(draft.content)
            : undefined;
          if (content) {
            form.setFieldsValue({
              deviceTypeId: content.deviceTypeId,
              modelName: content.modelName,
            });
            if (content.deviceTypeId) {
              setDeviceType(content.deviceTypeId);
            }
          }
        });
      }
    }
  }, [id, form, currentType]);

  /** complete */

  /** init form */

  const [deviceTypes, setDeviceTypes] = useState<DeviceTreeProps[]>();

  useEffect(() => {
    getDeviceTypeTree().then(res => {
      setDeviceTypes(formatTreeData(res));
    });
  }, []);

  const [deviceType, setDeviceType] = useState<number>();

  const [model, setModel] = useState<ModelResponse[]>([]);

  useEffect(() => {
    if (deviceType !== undefined) {
      getModel(deviceType).then(setModel);
    }
  }, [deviceType]);

  const options = useMemo(() => {
    return model.map(i => ({ label: i.name, value: i.name }));
  }, [model]);

  const [searchValue, setSearchValue] = useState<string>();

  const onSearch = debounce((value: string) => {
    setSearchValue(value);
  }, 10);

  const onBlur = () => {
    if (searchValue) {
      form.setFieldsValue({
        modelName: searchValue,
      });
    }
  };

  const onSubmit = (value: Omit<BsaDeviceTemplateRequest, 'bsaId'>) => {
    if (id) {
      setLoading(true);
      createRackDevice({
        ...value,
        bsaId: Number(id),
      }).then(() => {
        // 更新外部
        setLoading(false);
        updateFn?.();
      });
    }
  };

  const onRefresh = () => {
    if (id) {
      rackStatusCheck(Number(id)).then(res => {
        if (res.status !== ProcessStatusEnum.GENERATING) {
          updateFn?.();
        }
      });
    }
  };

  /** init form */

  const onPrevious = () => {
    if (Array.isArray(typeList) && foucsType) {
      const type = getPrevious(foucsType, typeList);
      if (type) {
        changeType?.(type);
      }
    }
  };

  const onNext = () => {
    const type = getNext(foucsType!, typeList ?? [], currentType);
    if (type) {
      changeType?.(type);
    }
  };

  const onSaveDraft = () => {
    const value: Partial<Omit<BsaDeviceTemplateRequest, 'bsaId'>> = form.getFieldsValue();
    saveDraft({
      bsaId: Number(id),
      content: JSON.stringify(value),
    }).then(() => {
      Modal.success({
        content: '保存成功',
      });
    });
  };

  const onFinish = () => {
    if (id) {
      if (disabled) {
        onNext();
      } else {
        if (isComplete) {
          onNext();
        } else {
          if (currentType === BsaType.RACK_DEVICE_COMPLETED) {
            submitRackDevice(Number(id)).then(() => {
              // 更新
              updateFn?.();
            });
          } else if (currentType === BsaType.RACK_DEVICE_CREATE) {
            Modal.warning({
              title: '请先生成电池簇',
            });
          } else {
            Modal.warning({
              title: '请先等待电池簇生成完毕',
            });
          }
        }
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className={classNames('page__content', styles.content)} style={{ minHeight: 'calc(100vh - 128px)' }}>
            <BsaPageHeader
              title="电池簇设置"
              extraContent={
                <>
                  <Button type="primary" onClick={onPrevious}>
                    上一步
                  </Button>
                  <Button type="primary" onClick={onFinish}>
                    下一步
                  </Button>
                  {!isInit && status !== BSAStatus.INVALID && (
                    <Button type="primary" onClick={onSaveDraft}>
                      存草稿
                    </Button>
                  )}
                </>
              }
            >
              <BsaSteps typeList={typeList} foucsType={foucsType} />
            </BsaPageHeader>
            {!isInit ? (
              <>
                {status !== BSAStatus.INVALID && (
                  <div>
                    <div className={styles.title}>电池簇型号</div>
                    <Form form={form} layout="vertical" {...formLayout} onFinish={onSubmit}>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            name="deviceTypeId"
                            label="所属2级类目"
                            rules={[{ required: true, message: '请选择所属类目' }]}
                          >
                            <Select
                              onChange={(e: number) => {
                                setDeviceType(e);
                              }}
                              placeholder="请选择"
                            >
                              {(deviceTypes ?? []).map(i => (
                                <Select.Option key={i.id} value={i.id}>
                                  {i.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name="modelName"
                            label="型号"
                            rules={[{ required: true, message: '请选择所属型号' }]}
                          >
                            <Select
                              disabled={deviceType === undefined}
                              style={{ width: '100%' }}
                              showSearch
                              placeholder="请输入"
                              onSearch={onSearch}
                              onBlur={onBlur}
                              optionFilterProp="label"
                              options={options}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label=" ">
                            <Button type="primary" htmlType="submit">
                              批量生成电池簇
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={styles.title}>电池簇列表</div>
                <Space direction="vertical">
                  {status !== BSAStatus.INVALID && (
                    <Space>
                      {/* TODO 临时方案 */}
                      <Button type="primary" onClick={onRefresh}>
                        刷新
                      </Button>
                      {currentType !== BsaType.RACK_DEVICE_CREATE ? null : (
                        <Button type="primary">
                          <a
                            href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device`)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            前往修改电池簇信息
                          </a>
                        </Button>
                      )}
                    </Space>
                  )}

                  <Table
                    loading={tableLoading}
                    columns={columns}
                    rowKey="deviceId"
                    dataSource={racks}
                    rowClassName={(record: RackDevice) => {
                      return record.deviceStatus === DeviceStatus.INVALID ? 'device-invalid' : '';
                    }}
                  />
                </Space>
              </>
            )}
          </div>
        </BorderWrapper>
      </div>
    </Spin>
  );
};

export default BsaRack;
