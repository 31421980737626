import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { ModbusPointAttributes } from './entity';
import { ADDR_OFFSET, BsaDeviceTypeEnum, CRC_ORDER, DriveType, ProcessStatus, TopologicalType } from './type';

export interface GlobalEdgeRequest {
  topologicalType: TopologicalType;
  driveType: DriveType;
  bsaId: number;
  // 首地址偏移
  addrOffset: ADDR_OFFSET;
  // 采集间隔（毫秒）
  samplingInterval: number;
  // 采集重试次数
  samplingRetry: number;
  // 采集超时（毫秒）
  samplingTimeout: number;
  // 单次最多可请求寄存器字节数
  groupWords: number;
}
// 根据储能阵列id和拓扑类型，查询全局数采
export function createGlobalEdgeConfig(request: GlobalEdgeRequest) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/config`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function getGlobalEdgeConfig(id: Key) {
  return fetch<GlobalEdgeRequest>(
    `/api/v2/edge-device/bsa/get-config`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface EdgeDeviceConfig {
  id: number;
  deviceId: number;
  sequence: number;
  ip: string;
  port: number;
  stationNum: number;
}
export interface BsaEdgeDeviceConfigPreview {
  pcs?: EdgeDeviceConfig[];
  stack?: EdgeDeviceConfig[];
}

export interface BsaEdgeDeviceConfigPreviewRequest {
  bsaId: Key;
  topologicalType: TopologicalType;
}

export function getBsaEdgeDeviceConfigPreview(query: BsaEdgeDeviceConfigPreviewRequest) {
  return fetch<BsaEdgeDeviceConfigPreview>(
    `/api/v2/edge-device/bsa/preview`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface CreateEdgeDevicetemplateRequest {
  name: string;
  remark?: string;
  bsaId: number;
  // crc字节序
  crcOrder: CRC_ORDER;
  type: BsaDeviceTypeEnum;
}
// 数采模板设置
export function createEdgeDevicetemplate(request: CreateEdgeDevicetemplateRequest) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/template`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface EdgeDevicetemplateRequest
  extends Omit<CreateEdgeDevicetemplateRequest, 'name' | 'remark' | 'crcOrder'> {}

export interface BsaEdgeDeviceTemplateResponse {
  id: number;
  bsaId: number;
  type: BsaDeviceTypeEnum.RACK | BsaDeviceTypeEnum.PACK;
  name: string;
  // crc字节序
  crcOrder: CRC_ORDER;
  remark?: string;
  deviceTypeId: number;
  deviceTypeName: string;
  deviceModelId: number;
  edgeDeviceTemplateId: number;
  edgeDeviceTemplateVersionId: number;
  modelName: string;
  driveType: DriveType;
  code?: string;
}
// 根据储能id和设备类型获取数采模板
export function getEdgeDevicetemplate(query: EdgeDevicetemplateRequest) {
  return fetch<BsaEdgeDeviceTemplateResponse>(
    `/api/v2/edge-device/bsa/get-template`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export function submitRackTemplete(id: Key) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/rack-template/created`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function submitPackTemplete(id: Key) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/pack-template/created`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
// 完成电池芯设备数采模板
export function submitCellTemplete(id: Key) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/cell-template/created`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
// 应用模板
export function applyEdgeDevicePointTemplate(query: EdgeDevicetemplateRequest) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/template/apply`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export function rackTemplateStatusCheck(id: Key) {
  return fetch<ProcessStatus>(
    `/api/v2/edge-device/bsa/template/rack/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function packTemplateStatusCheck(id: Key) {
  return fetch<ProcessStatus>(
    `/api/v2/edge-device/bsa/template/pack/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
// 电芯模板应用状态
export function cellTemplateStatusCheck(id: Key) {
  return fetch<ProcessStatus>(
    `/api/v2/edge-device/bsa/template/cell/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface EdgeDevicePointTemplateRequest {
  // 储能阵列id
  bsaId: number;
  // 设备类型
  type: BsaDeviceTypeEnum;
  templates: ModbusPointAttributes[];
}

export function rackPointComplete(id: Key) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/point/rack/complete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function packPointComplete(id: Key) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/point/pack/complete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function rackPointStatusCheck(id: Key) {
  return fetch<ProcessStatus>(
    `/api/v2/edge-device/bsa/point/rack/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function packPointStatusCheck(id: Key) {
  return fetch<ProcessStatus>(
    `/api/v2/edge-device/bsa/point/pack/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
// 电池芯采集点完成
export function cellPointComplete(id: Key) {
  return fetch<void>(
    `/api/v2/edge-device/bsa/point/cell/complete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
// 电芯采集点生成状态
export function cellPointStatusCheck(id: Key) {
  return fetch<ProcessStatus>(
    `/api/v2/edge-device/bsa/point/cell/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function pointStatusCheck(bsaId: Key, type: BsaDeviceTypeEnum) {
  return type === BsaDeviceTypeEnum.PACK
    ? packPointStatusCheck(bsaId)
    : type === BsaDeviceTypeEnum.CELL
    ? cellPointStatusCheck(bsaId)
    : rackPointStatusCheck(bsaId);
}
