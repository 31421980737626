import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { PageRequest, PageResponse } from './page';
import { CircuitProps } from './circuit';
import { Key } from 'react';
import { ProcessStatus } from './type';
import { PVType } from '@/components/SelectDeviceModal';

export interface DevicePageReq extends PageRequest {
  codeOrName?: string;
  tag?: string;
  typeIds?: number[];
  ouId: number;
}

export interface PVDevicePageQuery extends PageRequest {
  codeOrName?: string;
  deviceTag?: string;
  deviceTypes?: number[];
  ouId: number;
  pvId: number;
  pvType: PVType;
}

export interface DevicePageProps {
  code: string;
  customerFullName: string;
  customerMcid: string;
  customerName: string;
  id: number;
  name: string;
  rootMcid: string;
  sn: string;
  status: number;
  tags: string[];
  tenantName: string;
  typeId: number;
  typeName: string;
  updateTime: string;
  circuits: CircuitProps[];
}

export interface CircuitDevicesRes {
  deviceDto: DevicePageProps;
  deviceId: number;
  deviceType: number;
  host: boolean;
}

export const getPVDevicePage = (query: PVDevicePageQuery) =>
  fetch<PageResponse<DevicePageProps>>(`/api/combiner-box/device-page?${qs.stringify(query, { indices: false })}`);

export interface DeviceTreeProps {
  id: number;
  name: string;
  children?: DeviceTreeProps[];
}

export const getDeviceTypeTree = () =>
  fetch<{ list: DeviceTreeProps[] }>(
    `/api/v2/device-type/tree`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);

/** BSA */
export interface BsaDeviceTemplateRequest {
  bsaId: Key;
  deviceTypeId: number;
  modelName: string;
}

export function createRackDevice(params: BsaDeviceTemplateRequest) {
  return fetch<void>(
    `/api/v2/device/bsa/rack`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
// 生成电池芯设备
export function createCellDevice(params: BsaDeviceTemplateRequest) {
  return fetch<void>(
    `/api/v2/device/bsa/cell`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function createPackDevice(params: BsaDeviceTemplateRequest) {
  return fetch<void>(
    `/api/v2/device/bsa/pack`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function submitRackDevice(id: number) {
  return fetch<void>(
    `/api/v2/device/bsa/rack/created`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function submitPackDevice(id: Key) {
  return fetch<void>(
    `/api/v2/device/bsa/pack/created`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
// 电池芯创建
export function submitCellDevice(id: Key) {
  return fetch<void>(
    `/api/v2/device/bsa/cell/created`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function rackStatusCheck(id: number) {
  return fetch<ProcessStatus>(
    `/api/v2/device/bsa/rack/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function packStatusCheck(id: number) {
  return fetch<ProcessStatus>(
    `/api/v2/device/bsa/pack/status-check`,
    { method: 'POST', body: JSON.stringify({ id }) },
    true
  );
}
// 电池芯设备状态
export function cellStatusCheck(id: Key) {
  return fetch<ProcessStatus>(
    `/api/v2/device/bsa/cell/status-check`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

/** BSA */

export const queryDeviceListByCircuitId = (id: number) =>
  fetch<{ list: DevicePageProps[] }>(
    `/api/v2/device/listByCircuitId`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface DeviceDataQuery {
  deviceIds: number[];
  propertyIds: number[];
  startTime: number;
  endTime: number;
}

export interface PointValue {
  time: number;
  value: number;
  originalTime?: number;
}

export interface DeviceData {
  deviceId: number;
  propertyId: number;
  values: PointValue[];
}
export interface DeviceDataForRaw {
  year: number;
  deviceId: number;
  propertyId: number;
  time: number;
  value: number;
}
export enum GrainChoice {
  ORIGINAL = 'original',
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
}

export interface SocDataQuery {
  bsaId: number;
  startTime: number;
  resolution?: GrainChoice; // 15分钟 1分钟
  endTime: number;
}

export interface PcsPowerDataQuery extends SocDataQuery {}

// 15分钟
export const getDeviceDataInstantaneous = (params: DeviceDataQuery) =>
  fetch<{ list: DeviceData[] }>(
    `/api/v2/device/dataProperty/resampled/instantaneous`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  ).then(res => res.list ?? []);
// 1分钟
export const getDeviceDataInstantaneousFor1m = (params: DeviceDataQuery) =>
  fetch<{ list: DeviceData[] }>(
    `/api/v2/device/dataProperty/resampled/instantaneous1m`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  ).then(res => res.list ?? []);

// 原始值
export const getDeviceDataInstantaneousForRaw = (params: DeviceDataQuery): Promise<DeviceData[]> =>
  fetch<{ list: DeviceDataForRaw[] }>(
    `/api/v2/device/dataProperty/raw`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  ).then(res => {
    let ids = (res.list ?? []).map(item => item.propertyId + '$' + item.deviceId);
    let uniqueIds = [...new Set(ids)];
    let temps = uniqueIds.map(str => {
      let datas = (res.list ?? []).filter(i => i.propertyId + '$' + i.deviceId === str);
      let tempArr = str.split('$');
      return {
        deviceId: +tempArr[1],
        propertyId: +tempArr[0],
        values: datas,
      };
    });
    return temps;
  });

export interface DeviceDataRecentQuery {
  deviceIds: number[];
  dataPropertyIds: number[];
}

export enum LinkStatus {
  OFFLINE,
  NORMAL,
  PARTONLINE,
}
export interface DeviceDataRecentResponse {
  deviceId: number;
  propertyId: number;
  value: number;
  status?: LinkStatus;
  ts: string;
}
// 温度实时数据
export const getDeviceDataRecent = (params: DeviceDataRecentQuery) =>
  fetch<{ list: DeviceDataRecentResponse[] }>(
    `/api/v2/device/dataProperty/recent`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

// 瞬时量聚合值
export interface DeviceTimeAggrInstantaneousQuery {
  deviceIds: number[];
  propertyIds: number[];
  resolution: string;
  aggrType: string;
  startTime: number;
  endTime: number;
}

// 根据设备id、数据属性id和时间颗粒度查询瞬时量聚合值
export const getTimeAggrInstantaneous = (params: DeviceTimeAggrInstantaneousQuery) =>
  fetch<{ list: DeviceData[] }>(
    `/api/v2/device/dataProperty/time-aggr/instantaneous`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  ).then(res => res.list ?? []);

interface valueItem {
  time: number;
  value: number;
}
export interface cumulativeRes {
  deviceId: number;
  propertyId: number;
  values: valueItem[];
}

export interface cumulativeReq {
  deviceIds: number[];
  propertyIds: number[];
  resolution: string;
  startTime: number;
  endTime: number;
}
export const getCircuitCumulative = (query: cumulativeReq) => {
  return fetch<{ list: cumulativeRes[] }>(
    `/api/v2/device/dataProperty/time-aggr/cumulative`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  ).then(res => res.list ?? []);
};
// 查询设备下的ou
export function getOuIdsByDeviceId(id: number) {
  return fetch<{ list: { id: number }[] }>(
    `/api/v2/device/ou/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list);
}
