import { ChargeStationRes, getEnergyAssetThirtyDaysChargeStationInfo, OuList } from '@/api/energyAssetOverview';
import { Spin } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import HeadTitle from '../HeadTitle/HeadTitle';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { average } from '../../utils';
import { StationHasCount } from '../..';
import { Empty } from '@maxtropy/components';

export interface ICurrentThirtyDaysChargingStation {
  ouInfo: OuList;
  height: string | undefined;
}

const CurrentThirtyDaysChargingStation: FC<ICurrentThirtyDaysChargingStation> = props => {
  const { ouInfo, height } = props;
  const stationHasCount = useContext(StationHasCount);
  const [thirtyDaysBsaInfo, setThirtyDaysBsaInfo] = useState<ChargeStationRes[]>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 近 30 日充电站发电量
      getEnergyAssetThirtyDaysChargeStationInfo(ouInfo.id).then(res => {
        setThirtyDaysBsaInfo(res.list ?? []);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  const option = {
    grid: {
      left: 60,
      right: 30,
      top: 55,
      bottom: 20,
    },
    legend: {
      show: true,
      right: 20,
      top: 0,
      textStyle: {
        color: '#52E7FF',
      },
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: '#fff',
      },
      formatter(items: any[]) {
        const { axisValue } = items[0];
        // // 根据选择的颗粒度，展示不同的tooltip
        let str: string = dayjs(axisValue, 'x').format('YYYY-MM-DD');
        const avg = average(thirtyDaysBsaInfo?.map(i => i.electricityQuantity));
        const avgValue = isNaN(avg) ? '--' : avg.toFixed(2);
        str += `<br>平均充电量：<span style='display:inline-block;width:86px;text-align:right;color:#52E7FF'>${avgValue}kWh</span>`;

        items.forEach(item => {
          const { seriesName, data, marker } = item;
          const value = !isNil(data[1]) ? `${data[1].toFixed(2)}` : '--';
          str += `<br> <span style='display:inline-block;width:82px;text-align:right'>${marker}${seriesName}：</span> ${value}<br>
          <span style='display:inline-block;width:80px;text-align:right'>环比：</span>
          ${
            data[2] && data[2] > 0
              ? `<span style='display:inline-block;border-radius:10px;color:#FF4D4F;width:86px;'>${
                  !isNil(data[2]) ? '+' + (Number(data[2]) * 100).toFixed(2) + '%' : '--'
                }</span>`
              : `<span style='display:inline-block;border-radius:10px;color:#59D744;width:86px;'>${
                  !isNil(data[2]) ? (Number(data[2]) * 100).toFixed(2) + '%' : '--'
                }</span>`
          }`;
        });

        return str;
      },
    },
    xAxis: {
      type: 'time',
      minInterval: 60 * 1000 * 60 * 24,
      axisLabel: {
        hideOverlap: true,
        formatter: function (e: number) {
          return dayjs(e, 'x').format('MM-DD');
        },
      },
    },
    yAxis: {
      type: 'value',
      name: 'kWh',
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },

    series: [
      {
        type: 'bar',
        name: '充电量',
        color: '#52E7FF',
        data: thirtyDaysBsaInfo?.map(a => {
          return [a.datetime, a.electricityQuantity, a.electricityQuantityRate];
        }),
        markLine: {
          silent: true,
          tooltip: true,
          symbol: ['none', 'none'],
          lineStyle: {
            type: 'dashed',
            color: '#52E7FF',
          },
          data: [
            {
              label: { formatter: `平均充电量: {c}kWh`, position: 'insideEndTop', color: '#fff', show: false },
              yAxis: average(thirtyDaysBsaInfo?.map(i => i.electricityQuantity)).toFixed(2),
            },
          ],
        },
      },
    ],
  };

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
      }}
    >
      <HeadTitle title="近30日充电站-充电量" />
      <Spin spinning={dataLoading}>
        {stationHasCount?.evCount ? (
          <ReactEcharts
            option={option}
            notMerge
            lazyUpdate={false}
            style={{ height: height === 'unset' ? 240 : `calc(${height} - 50px)` }} // 样式的设置width与height
          />
        ) : (
          <Empty
            style={{ height: height === 'unset' ? 240 : `calc(${height} - 50px)`, paddingTop: '10%' }}
            description={'无充电站'}
          />
        )}
      </Spin>
    </div>
  );
};

export default CurrentThirtyDaysChargingStation;
