import { Form, InputNumber, Modal, ShowInput } from '@maxtropy/components';
import React, { FC, useEffect } from 'react';
import { ItemProps } from '..';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface IEditConfigModal {
  editData?: ItemProps;
  editConfigModalVisible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => Promise<void>;
}

const EditConfigModal: FC<IEditConfigModal> = props => {
  const { editData, editConfigModalVisible, onCancel, onSubmit } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (!editData) return;
    form.setFieldsValue(editData);
  }, [editData]);

  const onOK = async () => {
    const values = await form.validateFields();
    onSubmit({
      ...values,
    }).then(_ => {
      form.resetFields();
    });
  };

  // 自定义校验函数
  const validateSum = () => {
    const benefitRatioA = form.getFieldValue('benefitRatioA') || 0;
    const benefitRatioB = form.getFieldValue('benefitRatioB') || 0;
    return benefitRatioA + benefitRatioB === 100
      ? Promise.resolve()
      : Promise.reject(new Error('甲方、乙方节能效益占比之和必须等于 100！'));
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      size="normal"
      destroyOnClose
      open={editConfigModalVisible}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={onOK}
      okText="保存后重算"
      title={'编辑'}
    >
      <Form form={form} labelAlign="left">
        <Form.Item name="time" label="当前日期">
          <ShowInput />
        </Form.Item>
        <div className={styles.dash}></div>
        <div className={styles.info_box}>
          <InfoCircleOutlined className={styles.icon_sty} />
          <span style={{ color: 'rgba(var(--base-text-color),0.35)' }}>尖峰平谷时段取对应回路用电合同中配置</span>
        </div>
        <Form.Item label="尖电量" name="sharpPrice" rules={[{ required: true, message: '请输入尖电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="峰电量" name="peakPrice" rules={[{ required: true, message: '请输入峰电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="平电量" name="flatPrice" rules={[{ required: true, message: '请输入平电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="谷电量" name="valleyPrice" rules={[{ required: true, message: '请输入谷电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="节能率" name="energySavingRate" rules={[{ required: true, message: '请输入节能率' }]}>
          <InputNumber
            precision={2} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={100.0}
            style={{ width: '100%' }}
            addonAfter={'%'}
          />
        </Form.Item>

        <Form.Item
          label="甲方节能效益占比"
          name="benefitRatioA"
          dependencies={['benefitRatioB']} // 监听 benefitRatioB 的值
          rules={[{ required: true, message: '请输入甲方节能效益占比' }, { validator: validateSum }]}
        >
          <InputNumber
            precision={2} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={100.0}
            style={{ width: '100%' }}
            addonAfter={'%'}
          />
        </Form.Item>
        <Form.Item
          label="乙方节能效益占比"
          name="benefitRatioB"
          dependencies={['benefitRatioA']} // 监听 benefitRatioA 的值
          rules={[{ required: true, message: '请输入乙方节能效益占比' }, { validator: validateSum }]}
        >
          <InputNumber
            precision={2} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={100.0}
            style={{ width: '100%' }}
            addonAfter={'%'}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditConfigModal;
