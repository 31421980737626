import { MXTabsProps, Tabs, Tooltip } from '@maxtropy/components';
import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import pack from '../../imgs/pack.png';
import cellBlue from '../../imgs/cell-blue.png';
import cellRed from '../../imgs/cell-red.png';
import cellGreen from '../../imgs/cell-green.png';
import cellGrey from '../../imgs/cell-grey.png';
import { V2BsaBatteryGetBatteryChipDataPostResponse } from '@maxtropy/dmes-apis-v2';
import { formatData, getPeakData } from '../../utils';
import { isNil } from 'lodash';
import dayjs from 'dayjs';

export interface IndicatorsTabProps {
  onTabChange?: (tabTitle: string) => void;
  activeKey: string;
  data?: V2BsaBatteryGetBatteryChipDataPostResponse;
  clusterInfo?: any;
  isChannel: boolean;
}

export enum BsaType {
  OLD = 0,
  NEW = 1,
}

// 区分电压还是温度
export const IndicatorsType = {
  VOLTAGE: '1',
  TEMPERATURE: '2',
};

const IndicatorsTab: FC<IndicatorsTabProps> = props => {
  const { activeKey, onTabChange, data, isChannel, clusterInfo } = props;

  const items: MXTabsProps['items'] = [
    {
      key: IndicatorsType.VOLTAGE,
      label: '电压',
    },
    {
      key: IndicatorsType.TEMPERATURE,
      label: '温度',
    },
  ];

  const max = (
    voltage?: number,
    temperature?: number,
    activeKey?: string,
    bsaBatteryPackNode?: V2BsaBatteryGetBatteryChipDataPostResponse['bsaBatteryPackNode']
  ) => {
    if (activeKey === IndicatorsType.VOLTAGE) {
      const maxData = getPeakData('max', IndicatorsType.VOLTAGE, bsaBatteryPackNode);
      if (!isNil(maxData)) {
        return maxData === voltage;
      } else {
        return false;
      }
    } else {
      const maxData = getPeakData('max', IndicatorsType.TEMPERATURE, bsaBatteryPackNode);
      if (!isNil(maxData)) {
        return maxData === temperature;
      } else {
        return false;
      }
    }
  };

  const min = (
    voltage?: number,
    temperature?: number,
    activeKey?: string,
    bsaBatteryPackNode?: V2BsaBatteryGetBatteryChipDataPostResponse['bsaBatteryPackNode']
  ) => {
    if (activeKey === IndicatorsType.VOLTAGE) {
      const minData = getPeakData('min', IndicatorsType.VOLTAGE, bsaBatteryPackNode);
      if (!isNil(minData)) {
        return minData === voltage;
      } else {
        return false;
      }
    } else {
      const minData = getPeakData('min', IndicatorsType.TEMPERATURE, bsaBatteryPackNode);
      if (!isNil(minData)) {
        return minData === temperature;
      } else {
        return false;
      }
    }
  };

  const heighterThanLimit = useCallback(
    (data?: V2BsaBatteryGetBatteryChipDataPostResponse, voltage?: number, temperature?: number) => {
      if (activeKey === IndicatorsType.VOLTAGE) {
        if (!isNil(voltage)) {
          if (!isNil(data?.voltageUpperLimit) && data?.voltageUpperLimit! <= voltage) {
            return 'rgba(230,66,66,0.2)';
          } else if (!isNil(data?.voltageLowerLimit) && data?.voltageLowerLimit! >= voltage) {
            return 'rgba(82,196,26,0.2)';
          } else {
            return 'rgba(74,144,226,0.2)';
          }
        } else {
          return 'rgba(255,255,255,0.1)';
        }
      } else {
        if (!isNil(temperature)) {
          if (!isNil(data?.temperatureUpperLimit) && data?.temperatureUpperLimit! <= temperature) {
            return 'rgba(230,66,66,0.2)';
          } else if (!isNil(data?.temperatureLowerLimit) && data?.temperatureLowerLimit! >= temperature) {
            return 'rgba(82,196,26,0.2)';
          } else {
            return 'rgba(74,144,226,0.2)';
          }
        } else {
          return 'rgba(255,255,255,0.1)';
        }
      }
    },
    [activeKey]
  );

  const heighterThanLimitValueSty = useCallback(
    (data?: V2BsaBatteryGetBatteryChipDataPostResponse, voltage?: number, temperature?: number) => {
      if (activeKey === IndicatorsType.VOLTAGE) {
        if (!isNil(voltage)) {
          if (!isNil(data?.voltageUpperLimit) && data?.voltageUpperLimit! <= voltage) {
            return '#E64242';
          } else if (!isNil(data?.voltageLowerLimit) && data?.voltageLowerLimit! >= voltage) {
            return '#52C41A';
          } else {
            return 'rgba(255,255,255,0.85)';
          }
        } else {
          return 'rgba(255,255,255,0.85)';
        }
      } else {
        if (!isNil(temperature)) {
          if (!isNil(data?.temperatureUpperLimit) && data?.temperatureUpperLimit! <= temperature) {
            return '#E64242';
          } else if (!isNil(data?.temperatureLowerLimit) && data?.temperatureLowerLimit! >= temperature) {
            return '#52C41A';
          } else {
            return 'rgba(255,255,255,0.85)';
          }
        } else {
          return 'rgba(255,255,255,0.85)';
        }
      }
    },
    [activeKey]
  );

  const imgRender = useCallback(
    (data?: V2BsaBatteryGetBatteryChipDataPostResponse, voltage?: number, temperature?: number) => {
      if (activeKey === IndicatorsType.VOLTAGE) {
        if (!isNil(voltage)) {
          if (!isNil(data?.voltageUpperLimit) && data?.voltageUpperLimit! <= voltage) {
            return <img src={cellRed} className={styles.imgSty} title="电芯" alt="" />;
          } else if (!isNil(data?.voltageLowerLimit) && data?.voltageLowerLimit! >= voltage) {
            return <img src={cellGreen} className={styles.imgSty} title="电芯" alt="" />;
          } else {
            return <img src={cellBlue} className={styles.imgSty} title="电芯" alt="" />;
          }
        } else {
          return <img src={cellGrey} className={styles.imgSty} title="电芯" alt="" />;
        }
      } else {
        if (!isNil(temperature)) {
          if (!isNil(data?.temperatureUpperLimit) && data?.temperatureUpperLimit! <= temperature) {
            return <img src={cellRed} className={styles.imgSty} title="电芯" alt="" />;
          } else if (!isNil(data?.temperatureLowerLimit) && data?.temperatureLowerLimit! >= temperature) {
            return <img src={cellGreen} className={styles.imgSty} title="电芯" alt="" />;
          } else {
            return <img src={cellBlue} className={styles.imgSty} title="电芯" alt="" />;
          }
        } else {
          return <img src={cellGrey} className={styles.imgSty} title="电芯" alt="" />;
        }
      }
    },
    [activeKey]
  );

  const renderBorder = (
    voltage?: number,
    temperature?: number,
    activeKey?: string,
    bsaBatteryPackNode?: V2BsaBatteryGetBatteryChipDataPostResponse['bsaBatteryPackNode']
  ) => {
    if (activeKey === IndicatorsType.VOLTAGE) {
      const isMax = max(voltage, temperature, IndicatorsType.VOLTAGE, bsaBatteryPackNode);
      const isMin = min(voltage, temperature, IndicatorsType.VOLTAGE, bsaBatteryPackNode);
      if (isMax) {
        return '1px solid #E64242';
      } else if (isMin) {
        return '1px solid #52C41A';
      } else {
        return undefined;
      }
    } else {
      const isMax = max(voltage, temperature, IndicatorsType.TEMPERATURE, bsaBatteryPackNode);
      const isMin = min(voltage, temperature, IndicatorsType.TEMPERATURE, bsaBatteryPackNode);
      if (isMax) {
        return '1px solid #E64242';
      } else if (isMin) {
        return '1px solid #52C41A';
      } else {
        return undefined;
      }
    }
  };

  console.log(clusterInfo.bsaType);

  // 新旧储能渲染名称
  const renderNameSplitByBsaType = useCallback(
    (name?: any) => {
      if (clusterInfo.bsaType === BsaType.OLD) {
        console.log(222, name.substr(-2).slice(0, 1));
        return name?.substr(-3).slice(0, 2) ? Number(name.substr(-3).slice(0, 2)) + 1 : '--';
      }
      if (clusterInfo.bsaType === BsaType.NEW) {
        return name.substr(-2).slice(0, 2) ? Number(name.substr(-2).slice(0, 2)) + 1 : '--';
      }
      return '--';
    },
    [clusterInfo.bsaType]
  );

  const renderEachPack = (
    c: any,
    bsaBatteryPackNode: V2BsaBatteryGetBatteryChipDataPostResponse['bsaBatteryPackNode']
  ) => (
    <Tooltip
      key={c.id}
      title={
        <span>
          {c.name}
          <br />
          更新时间：
          {activeKey === IndicatorsType.VOLTAGE
            ? c.voltageTime
              ? dayjs(c.voltageTime).format('YYYY-MM-DD HH:mm:ss')
              : '--'
            : c.temperatureTime
            ? dayjs(c.temperatureTime).format('YYYY-MM-DD HH:mm:ss')
            : '--'}
        </span>
      }
    >
      <div
        onClick={() => {
          if (!isChannel) {
            window.open(`${window.IOTPLATFORMORIGIN}/device/manage/device/${c.deviceId}/detail`, '_blank');
          }
        }}
        className={styles.item_wrapper_eachContent}
        style={{
          border: renderBorder(c.voltage, c.temperature, activeKey, bsaBatteryPackNode),
          backgroundColor: heighterThanLimit(data, c.voltage, c.temperature),
        }}
      >
        <div className={styles.titleSty}>
          {imgRender(data, c.voltage, c.temperature)}

          <span className={styles.nameSty}>
            {c.name.slice(0, 3)}
            {renderNameSplitByBsaType(c.name)}
          </span>
        </div>
        <div
          className={styles.valueSty}
          style={{
            color: heighterThanLimitValueSty(data, c.voltage, c.temperature),
          }}
        >
          {activeKey === IndicatorsType.VOLTAGE
            ? formatData(activeKey, c.voltage, 'V')
            : formatData(activeKey, c.temperature, '℃')}
        </div>
        {max(c.voltage, c.temperature, activeKey, bsaBatteryPackNode) &&
        min(c.voltage, c.temperature, activeKey, bsaBatteryPackNode) ? (
          <div className={styles.maxIcon}>最高</div>
        ) : (
          <>
            {max(c.voltage, c.temperature, activeKey, bsaBatteryPackNode) && <div className={styles.maxIcon}>最高</div>}
            {min(c.voltage, c.temperature, activeKey, bsaBatteryPackNode) && <div className={styles.minIcon}>最低</div>}
          </>
        )}
      </div>
    </Tooltip>
  );

  return (
    <>
      <Tabs className={styles.tabSty} onChange={onTabChange} activeKey={activeKey} items={items} />
      {activeKey && (
        <div style={{ height: 'calc(100vh - 310px)', overflowY: 'auto' }}>
          {(data?.bsaBatteryPackNode ?? []).map(item => (
            <div key={item.id} style={{ marginBottom: 16 }}>
              <div className={styles.item_wrapper_title}>
                <img src={pack} className={styles.imgSty} title="电池组" alt="" />
                <span className={styles.nameSty}>{item.name}</span>
              </div>
              <div className={styles.item_wrapper_content}>
                {activeKey === IndicatorsType.VOLTAGE ? (
                  item.children?.filter(x => x.hasVoltagePoint).length === 0 ? (
                    <div className={styles.noDataSty}>暂无数据</div>
                  ) : (
                    item.children?.filter(x => x.hasVoltagePoint).map(c => renderEachPack(c, data?.bsaBatteryPackNode))
                  )
                ) : item.children?.filter(x => x.hasTemperaturePoint).length === 0 ? (
                  <div className={styles.noDataSty}>暂无数据</div>
                ) : (
                  item.children
                    ?.filter(x => x.hasTemperaturePoint)
                    .map(c => renderEachPack(c, data?.bsaBatteryPackNode))
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default IndicatorsTab;
