import BorderWrapper from '@/components/BorderWrapper';
import { DoubleRightOutlined, ToolOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  EllipsisSpan,
  Form,
  FormContent,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import {
  apiV2BsaRemoteControlBsaListPost,
  apiV2BsaRemoteControlCommandDeviceListPost,
  apiV2BsaRemoteControlCommandPagePost,
  apiV2BsaRemoteControlCommandSendPost,
  apiV2BsaRemoteControlLogPagePost,
  V2BsaRemoteControlCommandPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Row, Space } from 'antd';
import dayjs from 'dayjs';

import qs from 'qs';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SendStatus, sendStatusColor, sendStatusDisplay } from './const';
import styles from './index.module.scss';
import controlIcon from './imgs/telecontrol@2x.png';

import SendValueDom from '../StorageOrderConfig/components/SendValueDom';

const routes = [{ name: `远程控制` }];

export const getLastTypeName = (str?: string) => {
  if (!str) return;
  let arr = str.split('/');
  if (!arr.length) return;
  let last = arr[arr.length - 1];
  return last;
};
export const columns = [
  {
    title: '储能站',
    dataIndex: 'bsaName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '指令名称',
    dataIndex: 'commandName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标设备',
    dataIndex: 'deviceName',
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '指令内容',
    dataIndex: 'commandContent',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '发送人',
    dataIndex: 'customerUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '发送时间',
    dataIndex: 'sendTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '发送状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: SendStatus) => (
      <Space size={0}>
        <Tag border="solid" color={sendStatusColor[v]}>
          {sendStatusDisplay[v]}
        </Tag>
        {/* {v === SendStatus.FAILED && (
          <Tooltip placement="top" title={record.message} trigger="hover">
            <InfoCircleOutlined style={{ color: '#FAAD14' }} />
          </Tooltip>
        )} */}
      </Space>
    ),
  },
  {
    title: '就地响应时间',
    dataIndex: 'responseTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];
type ButtonsPropsItem = Exclude<V2BsaRemoteControlCommandPagePostResponse['list'], undefined>[number] & {
  loading?: boolean;
};
const StorageRemoteControl = () => {
  const routesContext = useBreadcrumbRoutes();
  const { bsaId } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;
  const [form] = Form.useForm();
  const [targetForm] = Form.useForm();
  const deviceId = Form.useWatch('deviceId', form);
  const formBsaId = Form.useWatch('bsaId', form);
  const navigate = useNavigate();

  const { data: bsaList } = useRequest(() => apiV2BsaRemoteControlBsaListPost().then(res => res.list));

  const bsaName = useMemo(() => {
    if (!formBsaId) return;
    let info = bsaList?.find(item => item.id === +formBsaId);
    return info?.name;
  }, [formBsaId, bsaList]);

  const { data: tableList, refresh: refreshTableList } = useRequest(
    () => apiV2BsaRemoteControlLogPagePost({ page: 1, size: 10, bsaId: formBsaId, deviceId }).then(res => res.list),
    {
      ready: !!formBsaId,
      pollingInterval: 10 * 1000,
      pollingWhenHidden: false,
      refreshDeps: [formBsaId, deviceId],
    }
  );

  const { data: deviceList } = useRequest(
    () => apiV2BsaRemoteControlCommandDeviceListPost({ id: formBsaId }).then(res => res.list),
    {
      refreshDeps: [formBsaId],
      ready: !!formBsaId,
    }
  );
  // 默认第一个设备
  // useEffect(() => {
  //   if (deviceList && deviceList.length) {
  //     form.setFieldsValue({
  //       deviceId: deviceList?.[0].id,
  //     });
  //   }
  // }, [deviceList]);

  // 根据设备id查询命令按钮
  const { data: buttonsList, mutate } = useRequest(
    () =>
      apiV2BsaRemoteControlCommandPagePost({
        page: 1,
        size: 500, // 产品要求不分页, 后续优化
        bsaId: formBsaId,
        deviceId: deviceId,
      }).then(res => res.list as ButtonsPropsItem[]),
    {
      refreshDeps: [formBsaId, deviceId],
      ready: !!formBsaId && !!deviceId,
    }
  );

  useEffect(() => {
    if (bsaId) {
      let exist = bsaList?.find(item => item.id === +bsaId);
      if (exist) {
        form.setFieldsValue({ bsaId: exist.id });
        return;
      }
    }
    form.setFieldsValue({ bsaId: bsaList?.[0]?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsaId, bsaList]);

  // 下发值 =（targetValue-b）/k
  const sendValue = (k: number | undefined, b: number | undefined, targetValue: number) => {
    if (k && !isNaN(targetValue)) {
      if (!targetValue) return null; // 排除空字符串
      return (targetValue - (b ?? 0)) / k;
    }
    return null;
  };
  const sendCommand = (info: ButtonsPropsItem) => {
    Modal.confirm({
      title: `${info.buttonName}`,
      content: (
        <div>
          <div style={{ marginBottom: 10 }}>
            <span>你确定要下发</span>
            <span style={{ color: 'red', padding: '0 5px' }}>{`${info.commandName}-${bsaName ?? ''}-${
              info.deviceName
            }`}</span>
            <span>吗?</span>
          </div>

          <Form form={targetForm} labelCol={{ style: { width: 70 } }}>
            {
              // @ts-ignore
              info.allowCustom && (
                <Form.Item
                  label="目标值"
                  name="targetValue"
                  rules={[
                    { required: true },
                    { pattern: /^(\-{0,1})[1-9][0-9]*([\.][0-9]{1,})?$/, message: '请输入数字' },
                  ]}
                  initialValue={info.targetValue}
                >
                  <Input />
                </Form.Item>
              )
            }

            <Form.Item noStyle dependencies={['targetValue']}>
              {({ getFieldValue }) => {
                const targetValue = getFieldValue('targetValue');
                return (
                  <SendValueDom
                    sendValue={sendValue(info.k, info.b, targetValue) ?? info.distributeValue}
                    dataPropertyType={info.dataPropertyType}
                    k={info.k}
                    b={info.b}
                  />
                );
              }}
            </Form.Item>
          </Form>
        </div>
      ),
      okText: '确定',
      onCancel: () => {
        targetForm.resetFields();
      },
      onOk: () => {
        info.loading = true;
        mutate(buttonsList);
        apiV2BsaRemoteControlCommandSendPost({
          commandId: info.commandId,
          customTargetValue: targetForm.getFieldValue('targetValue') ?? info.targetValue,
          customWriteValue: sendValue(info.k, info.b, targetForm.getFieldValue('targetValue')) ?? info.distributeValue,
        })
          .catch(err => console.log(err))
          .finally(() => {
            info.loading = false;
            mutate(buttonsList);
            refreshTableList();
            targetForm.resetFields();
          });
      },
    });
  };
  return (
    <>
      <div className={styles.page_box}>
        <div className="page">
          <div className="page__header">
            <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
          </div>
          <BorderWrapper>
            <div className="page__content">
              <FormContent style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }} title={`${''}远程控制`}>
                <Form form={form} labelCol={{ style: { width: 70 } }}>
                  <Row>
                    <Form.Item label="储能站" name="bsaId" rules={[{ required: true }]}>
                      <Select
                        style={{ width: 320 }}
                        showSearch
                        optionFilterProp="label"
                        options={bsaList?.map(item => ({
                          label: `${item.customerName ?? ''}${item.customerName ? '-' : ''}${item.name}`,
                          value: item.id,
                        }))}
                        onChange={() => {
                          form.setFieldsValue({
                            deviceId: undefined,
                          });
                          mutate([]);
                        }}
                      />
                    </Form.Item>
                  </Row>

                  <div className={styles.control_area}>
                    <div className={styles.control_area_inner}>
                      <div className={styles.control_title}>
                        <div className={styles.control_title_img}>
                          <img src={controlIcon} alt="png" />
                        </div>
                        <span>远程控制</span>
                      </div>
                      <div className={styles.control_line}></div>
                      <div className={styles.control_input}>
                        <Form.Item
                          style={{ marginBottom: 0 }}
                          labelCol={{ style: { width: 90 } }}
                          label="目标设备"
                          name="deviceId"
                        >
                          <Select
                            showSearch
                            optionFilterProp="label"
                            allowClear
                            onClear={() => {
                              mutate([]);
                            }}
                            style={{ width: 320 }}
                            options={deviceList?.map(item => ({
                              label: (
                                <span>
                                  <span style={{ color: '#4A90E2' }}>{item.name}</span> |{' '}
                                  {getLastTypeName(item.typeName)}{' '}
                                  {item.physicalModelNo ? '| ' + item.physicalModelNo : ''}
                                </span>
                              ),
                              value: item.id,
                            }))}
                          />
                        </Form.Item>
                      </div>
                      <div className={styles.control_line}></div>
                      <div className={styles.control_buttons}>
                        {buttonsList?.map(item => {
                          return (
                            <div className={styles.button_box} key={item.commandId}>
                              <Button loading={item.loading} onClick={() => sendCommand(item)} type="primary">
                                {item.buttonName}
                                {item.allowCustom && <ToolOutlined />}
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Form>

                <div className={styles.table_box}>
                  <div className={styles.table_title}>
                    <div className={styles.title_left}>操作记录</div>
                    <Button
                      type="link"
                      onClick={() => navigate('/energy-storage/remote/record?bsaId=' + (formBsaId ?? ''))}
                    >
                      查看更多记录
                      <DoubleRightOutlined />
                    </Button>
                  </div>
                  <Table rowKey="id" columns={columns} dataSource={tableList} />
                </div>
              </FormContent>
            </div>
          </BorderWrapper>
        </div>
      </div>
    </>
  );
};

export default StorageRemoteControl;
