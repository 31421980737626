import { getPvInfo, modifyStage, PvListItem } from '@/api/pv';
import BorderWrapper from '@/components/BorderWrapper';
import { Breadcrumb, Button, useBreadcrumbRoutes } from '@maxtropy/components';
import qs from 'qs';
import GridPointInfo from '../GridPointInfo';
import DmesPageHeader from '../PageHeader';
import { createContext, useEffect, useRef, useState } from 'react';
import CombinerBox from '../CombinerBox';
import { useNavigate } from 'react-router-dom';
import PvSteps from '../pvSteps';
import styles from './index.module.scss';
import Inverter from '@/pages/PvStation/Bsa/Inverter';
import String from '@/pages/PvStation/Bsa/String';
import PVComp from '@/pages/PvStation/Bsa/Comp';
import { Spin } from 'antd';

const routes = [{ name: '阵列信息' }];

enum PvBindDevices {
  GRID = 0, // 并网点
  COMBINER, // 汇流箱
  INVERT, // 逆变器
  STRING, // 组串
  COMP, // 组件
}

export const PvStepContext = createContext<{
  hasComponentBox?: boolean; // 是否有组件
}>({});
const ArrayInfo: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const routesContext = useBreadcrumbRoutes();
  const { id } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const debounce = useRef(false);
  const [lastStep, setLastStep] = useState(PvBindDevices.COMP);

  const [currentStep, setCurrentStep] = useState<number>(0);

  const navigate = useNavigate();
  const changeStep = (type: string) => {
    if (debounce.current) return;
    debounce.current = true;
    if (currentStep === lastStep && type === 'add') {
      // 完成
      backTo();
      return;
    }
    let tempStep = type === 'add' ? currentStep + 1 : currentStep - 1;

    if (isEdit) {
      modifyStage(id, tempStep)
        .then(res => {
          if (res.result) {
            setCurrentStep(tempStep);
            sessionStorage.setItem('arrayStep', tempStep.toString());
          }
        })
        .finally(() => (debounce.current = false));
    } else {
      setCurrentStep(tempStep);
      debounce.current = false;
    }
  };
  const backTo = () => {
    navigate('/pv-storage/basic/manage', { replace: true });
  };
  const renderElement = (step: number) => {
    switch (step) {
      case PvBindDevices.GRID:
        return <GridPointInfo />;
      case PvBindDevices.COMBINER:
        return <CombinerBox isEdit={isEdit} />;
      case PvBindDevices.INVERT:
        return <Inverter isEdit={isEdit} />;
      case PvBindDevices.STRING:
        return <String isEdit={isEdit} />;
      case PvBindDevices.COMP:
        return <PVComp isEdit={isEdit} />;
      default:
        return (
          <Spin spinning>
            <div style={{ height: 'calc(100vh - 280px)', marginTop: 15, backgroundColor: '#232324' }}></div>
          </Spin>
        );
    }
  };
  const { id: pvId } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [pvInfo, setPvInfo] = useState<PvListItem>();
  useEffect(() => {
    if (!pvId) return;
    getPvInfo(pvId as string).then(setPvInfo);
  }, [pvId]);

  useEffect(() => {
    // 存在组件
    if (!!pvInfo?.existComponents) {
      setLastStep(PvBindDevices.COMP);
      return;
    }
    setLastStep(PvBindDevices.STRING);
  }, [pvInfo]);

  useEffect(() => {
    if (isEdit) {
      const stage = sessionStorage.getItem('arrayStep');
      if (Number(stage) > lastStep) {
        setCurrentStep(lastStep);
        return;
      }
      setCurrentStep(Number(stage) ?? 0);
    }
  }, [isEdit, lastStep]);
  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          {isEdit ? (
            <DmesPageHeader
              onOk={() => changeStep('add')}
              okText={currentStep === lastStep ? '完成' : '下一步'}
              showCancel={false}
              title="阵列信息"
            >
              {currentStep > 0 && (
                <Button type="primary" onClick={() => changeStep('reduce')}>
                  返回上一步
                </Button>
              )}
            </DmesPageHeader>
          ) : (
            <DmesPageHeader
              onOk={() => changeStep('add')}
              showOk={currentStep !== lastStep}
              okText="下一步"
              showCancel={currentStep > 0}
              onCancel={backTo}
              cancelText="返回"
              title="阵列信息"
            >
              {currentStep > 0 && (
                <Button type="primary" onClick={() => changeStep('reduce')}>
                  上一步
                </Button>
              )}
            </DmesPageHeader>
          )}
          <PvStepContext.Provider value={{ hasComponentBox: !!pvInfo?.existComponents }}>
            <div className={styles.step_box}>
              <PvSteps step={currentStep} />
            </div>
            {renderElement(currentStep)}
          </PvStepContext.Provider>
        </BorderWrapper>
      </div>
    </>
  );
};

export default ArrayInfo;
