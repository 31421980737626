import { FC, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Divider, Form, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { InputNumber, message, Radio } from '@maxtropy/components';
import {
  apiV2NeoBsaDataPointConfigPost,
  apiV2NeoBsaDataPointGetConfigPost,
  apiV2NeoBsaDataPointPreviewPost,
  apiV2NeoBsaDeviceGetCreateStatusPost,
  V2BsaSecondEditionInfoBsaSecondEditionPostResponse,
  V2NeoBsaDataPointPreviewPostResponse,
} from '@maxtropy/dmes-apis-v2';
import HeadStep from '../components/HeadStep';
import { BsaStatus, ConfirmType, Step } from '../const';
import TopConfig from '../components/TopConfig';
import { ADDR_OFFSET, AddrOffsetLabels, CRC_ORDER, CrcOrderLabels, TopologicalType } from '@/api/type';
import IpAddress from '@/components/IpAddress';
import { padStart } from '@/pages/Bsa/utils';
import styles from './index.module.scss';
import { CreateStatus, CreateStatusEnum } from '../DataPointConfig';

interface Props {
  bsaData: V2BsaSecondEditionInfoBsaSecondEditionPostResponse | undefined;
}

const inputWidth = 464;

const GlobalConfig: FC<Props> = props => {
  const { bsaData } = props;
  const [, setSearchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [bsaEdgeDeviceConfigPreview, setBsaEdgeDeviceConfigPreview] = useState<V2NeoBsaDataPointPreviewPostResponse>();
  const [rackCreatStatus, setRackCreatStatus] = useState<CreateStatus>();

  useEffect(() => {
    apiV2NeoBsaDeviceGetCreateStatusPost({ neoBsaId: +id }).then(res => {
      setRackCreatStatus(res.rackDataPointCreateStatus as CreateStatus);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!bsaData) return;
    if (bsaData.actualStatus! >= BsaStatus.COMPLETE_ARRAY_STRUCT_CONFIG) {
      apiV2NeoBsaDataPointGetConfigPost({ id: bsaData.id! }).then(res => {
        form.setFieldsValue(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsaData]);

  // 电池簇配置完成后就不能编辑了
  const disabled = useMemo(() => {
    if (window.location.pathname.includes('/view')) return true;
    if (rackCreatStatus === undefined) return true;
    return rackCreatStatus !== CreateStatusEnum.NOT;
  }, [rackCreatStatus]);

  const topologicalType = Form.useWatch('topologicalType', form);
  useEffect(() => {
    if (!topologicalType) return;
    apiV2NeoBsaDataPointPreviewPost({
      neoBsaId: +id,
      topologicalType: topologicalType as TopologicalType,
    }).then(res => {
      setBsaEdgeDeviceConfigPreview(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topologicalType]);

  const onNext = () => {
    if (disabled) {
      setSearchParams({ step: String(Step.RACK) });
      return;
    }
    form.validateFields().then(async () => {
      setLoading(true);
      apiV2NeoBsaDataPointConfigPost({
        ...form.getFieldsValue(),
        action: ConfirmType.NEXT,
        neoBsaId: +bsaData?.id!,
      })
        .then(() => {
          setSearchParams({ step: String(Step.RACK) });
        })
        .finally(() => setLoading(false));
    });
  };

  const onDraft = () => {
    form.validateFields().then(() => {
      setLoading(true);
      apiV2NeoBsaDataPointConfigPost({
        ...form.getFieldsValue(),
        action: ConfirmType.DRAFT,
        neoBsaId: +bsaData?.id!,
      })
        .then(() => {
          message.success('保存成功');
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <Spin spinning={loading}>
      <HeadStep
        bsaData={bsaData}
        title={bsaData?.name + '储能站阵列数采配置'}
        current={Step.GLOBAL}
        onDraft={disabled ? false : onDraft}
        onNext={onNext}
      ></HeadStep>

      <Form form={form} labelCol={{ flex: '140px' }} labelWrap labelAlign="left" style={{ paddingLeft: 40 }}>
        <TopConfig disabled={disabled}></TopConfig>

        <div className={styles.subTitle}>全局数采配置</div>

        <Form.Item
          label="单次最多可请求寄存器字节数"
          name="groupWords"
          rules={[{ required: true, message: '请输入' }]}
          initialValue={60}
        >
          <InputNumber style={{ width: inputWidth }} min={1} max={60} precision={0} disabled={disabled} />
        </Form.Item>
        <Form.Item
          label="采集间隔(毫秒)"
          name="samplingInterval"
          rules={[{ required: true, message: '请输入' }]}
          initialValue={25}
        >
          <InputNumber
            style={{ width: inputWidth }}
            min={1}
            max={99999999}
            precision={0}
            addonAfter="ms"
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          label="采集超时(毫秒)"
          name="samplingTimeout"
          rules={[{ required: true, message: '请输入' }]}
          initialValue={1000}
        >
          <InputNumber
            style={{ width: inputWidth }}
            min={1}
            max={25000}
            precision={0}
            addonAfter="ms"
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          label="采集重试次数"
          name="samplingRetry"
          rules={[{ required: true, message: '请输入' }]}
          initialValue={3}
        >
          <InputNumber style={{ width: inputWidth }} min={1} max={9} precision={0} disabled={disabled} />
        </Form.Item>
        <Form.Item
          label="首地址偏移"
          name="addrOffset"
          rules={[{ required: true, message: '请选择' }]}
          initialValue={ADDR_OFFSET.ZERO}
        >
          <Radio.Group buttonStyle="solid" disabled={disabled}>
            <Radio.Button value={ADDR_OFFSET.ZERO}>{AddrOffsetLabels[ADDR_OFFSET.ZERO]}</Radio.Button>
            <Radio.Button value={ADDR_OFFSET.ONE}>{AddrOffsetLabels[ADDR_OFFSET.ONE]}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="crcOrder" label="CRC字节序" rules={[{ required: true, message: '请选择CRC字节序' }]}>
          <Select placeholder="请选择" style={{ width: inputWidth }} disabled={disabled}>
            <Select.Option value={CRC_ORDER.SMALL_END}>{CrcOrderLabels[CRC_ORDER.SMALL_END]}</Select.Option>
            <Select.Option value={CRC_ORDER.BIG_END}>{CrcOrderLabels[CRC_ORDER.BIG_END]}</Select.Option>
          </Select>
        </Form.Item>
      </Form>

      <div style={{ marginLeft: 40 }}>
        <div className={styles.subTitle}>对接颗粒度批量配置项</div>

        {topologicalType === TopologicalType.PCS_Penetrate_BMS &&
          (bsaEdgeDeviceConfigPreview?.pcs ?? []).map((i, index) => (
            <div key={index}>
              <div className={styles.edgeDeviceConfigPreviewTitle}>PCS{padStart(i.sequence! + 1)}数采相关配置</div>
              <Form labelAlign="left" labelWrap labelCol={{ flex: '200px' }}>
                <Form.Item label="Ip地址">
                  <IpAddress value={i.ip} disabled />
                </Form.Item>
                <Form.Item label="端口">
                  <InputNumber value={i.port} disabled />
                </Form.Item>
                <Form.Item label="单元标识符">
                  <InputNumber value={i.stationNum} disabled />
                </Form.Item>
              </Form>
              <Divider dashed />
            </div>
          ))}

        {topologicalType === TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS &&
          (bsaEdgeDeviceConfigPreview?.stack ?? []).map((i, index) => (
            <div key={index}>
              <div className={styles.edgeDeviceConfigPreviewTitle}>
                电池堆{padStart(i.sequence! + 1)}的下级电池簇/组/芯的采集网络地址信息
              </div>
              <Form labelAlign="left" labelWrap labelCol={{ flex: '200px' }}>
                <Form.Item label="Ip地址">
                  <IpAddress value={i.ip} disabled />
                </Form.Item>
                <Form.Item label="端口">
                  <InputNumber value={i.port} disabled />
                </Form.Item>
                <Form.Item label="单元标识符">
                  <InputNumber value={i.stationNum} disabled />
                </Form.Item>
              </Form>
              <Divider dashed />
            </div>
          ))}
      </div>
    </Spin>
  );
};

export default GlobalConfig;
