import {
  Button,
  EllipsisSpan,
  FormTitle,
  PopConfirm,
  Table,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import React, { useState } from 'react';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import {
  apiV2EnergyConservationCircuitEnergyAddPost,
  apiV2EnergyConservationCircuitEnergyAverageRatePost,
  apiV2EnergyConservationCircuitEnergyDeletePost,
  apiV2EnergyConservationCircuitEnergyPagePost,
  V2EnergyConservationCircuitEnergyPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import SelectCircuitModal from './components/SelectCircuitModal';
import { keepTwoOrLinePercent } from './utils';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';

const columns = [
  {
    title: '回路名称',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作人',
    dataIndex: 'operator',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];

export type ItemProps = Exclude<V2EnergyConservationCircuitEnergyPagePostResponse['list'], undefined>[number];

const EnergySavingBatchConfig = () => {
  const routesContext = useBreadcrumbRoutes();
  const [updateState, updateFn] = useUpdate();
  const [selectCircuitModalVisible, setSelectCircuitModalVisible] = useState<boolean>(false);
  const hasMofyBtn = useHasPermission(PermissionsType.B_MOFYLOG);

  // 回路列表
  const { data: circuitList, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationCircuitEnergyPagePost({});
      return res.list ?? [];
    },
    {
      refreshDeps: [updateState],
    }
  );

  // 获取平均节能率
  const { data: averageRate } = useRequest(async () => {
    return await apiV2EnergyConservationCircuitEnergyAverageRatePost({});
  });

  // 删除
  const onDelete = (id?: number) => {
    apiV2EnergyConservationCircuitEnergyDeletePost({ id }).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (record: ItemProps) => (
        <Space size={16}>
          <Button type="link">
            <Link to={`/energySaving/batch/configList/config/${record.id}`}>配置</Link>
          </Button>
          {hasMofyBtn && (
            <Button type="link">
              <Link to={`/energySaving/batch/configList/modifyLog/${record.id}`}>修改日志</Link>
            </Button>
          )}
          <PopConfirm title="是否删除该回路的节能展示？删除后无法恢复。" onConfirm={() => onDelete(record.id)}>
            <Button type="link" danger>
              删除
            </Button>
          </PopConfirm>
        </Space>
      ),
    },
  ];

  const onSubmit = async (values: { idList: number[] }) => {
    await apiV2EnergyConservationCircuitEnergyAddPost({
      ...values,
    });
    setSelectCircuitModalVisible(false);
    updateFn();
  };

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <FormTitle title={'批量配置'} />
      <div className={styles.btn_show_box}>
        <Button type="primary" onClick={() => setSelectCircuitModalVisible(true)}>
          选择回路
        </Button>
        <div className={styles.show_value_box}>
          <span className={styles.label}>整体节能率：</span>
          <span className={styles.value}>{keepTwoOrLinePercent(averageRate?.value)}</span>
        </div>
      </div>
      <div className={styles.table_wrapper}>
        <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={circuitList} />
      </div>
      <SelectCircuitModal
        selectCircuitModalVisible={selectCircuitModalVisible}
        onCancel={() => setSelectCircuitModalVisible(false)}
        onSubmit={onSubmit}
      />
    </Wrapper>
  );
};

export default EnergySavingBatchConfig;
