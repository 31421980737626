import { Checkbox, DatePicker, Form, InputNumber, Key, Modal } from '@maxtropy/components';
import { apiV2EnergyConservationCircuitEnergyConfigListPost } from '@maxtropy/dmes-apis-v2';
import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';

const monthOptions = [
  { label: '1月', value: 1 },
  { label: '2月', value: 2 },
  { label: '3月', value: 3 },
  { label: '4月', value: 4 },
  { label: '5月', value: 5 },
  { label: '6月', value: 6 },
  { label: '7月', value: 7 },
  { label: '8月', value: 8 },
  { label: '9月', value: 9 },
  { label: '10月', value: 10 },
  { label: '11月', value: 11 },
  { label: '12月', value: 12 },
];

export interface IBatchEditModal {
  isModify: boolean;
  id?: Key;
  batchEditModalVisible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => Promise<void>;
}

const BatchEditModal: FC<IBatchEditModal> = props => {
  const { isModify, batchEditModalVisible, onCancel, onSubmit, id } = props;
  const [form] = Form.useForm();
  const time = useWatch('time', form);

  // 查看配置日期列表（编辑与新建的时候禁用）
  const { data: configTimeList } = useRequest(
    async () => {
      const res = await apiV2EnergyConservationCircuitEnergyConfigListPost({
        id: id,
      });
      return res?.list;
    },
    {
      ready: !!id && !!batchEditModalVisible,
      refreshDeps: [id, batchEditModalVisible],
    }
  );

  const monthOptionsList = useMemo(() => {
    if (time && configTimeList) {
      return monthOptions.map(item => ({
        label: item.label,
        value: item.value,
        disabled: isModify
          ? !configTimeList
              ?.filter(i => i.year === Number(dayjs(time).format('YYYY')))?.[0]
              ?.month?.includes(item.value)
          : configTimeList
              ?.filter(i => i.year === Number(dayjs(time).format('YYYY')))?.[0]
              ?.month?.includes(item.value),
      }));
    } else {
      return monthOptions;
    }
  }, [time, configTimeList, isModify]);

  const onOK = async () => {
    const values = await form.validateFields();
    await onSubmit({
      ...values,
    });
    form.resetFields();
  };

  // 自定义校验函数
  const validateSum = () => {
    const benefitRatioA = form.getFieldValue('benefitRatioA') || 0;
    const benefitRatioB = form.getFieldValue('benefitRatioB') || 0;
    return benefitRatioA + benefitRatioB === 100
      ? Promise.resolve()
      : Promise.reject(new Error('甲方、乙方节能效益占比之和必须等于 100！'));
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      size="normal"
      destroyOnClose
      open={batchEditModalVisible}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={onOK}
      okText="保存后重算"
      title={isModify ? '批量修改' : '新增配置'}
    >
      <Form form={form} labelAlign="left">
        <Form.Item name="time" label="选择日期" rules={[{ required: true, message: '请选择日期' }]}>
          <DatePicker
            style={{ width: '100%' }}
            picker="year"
            onChange={value => {
              form.resetFields();
              form.setFieldValue('time', value);
            }}
          />
        </Form.Item>
        <Form.Item name="month" className={styles.noLabel_box} rules={[{ required: true, message: '请选择月份' }]}>
          <Checkbox.Group style={{ width: '100%' }} options={monthOptionsList} />
        </Form.Item>
        <div className={styles.dash}></div>
        <div className={styles.info_box}>
          <InfoCircleOutlined className={styles.icon_sty} />
          <span style={{ color: 'rgba(var(--base-text-color),0.35)' }}>尖峰平谷时段取对应回路用电合同中配置</span>
        </div>
        <Form.Item label="尖电量" name="sharpPrice" rules={[{ required: true, message: '请输入尖电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="峰电量" name="peakPrice" rules={[{ required: true, message: '请输入峰电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="平电量" name="flatPrice" rules={[{ required: true, message: '请输入平电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="谷电量" name="valleyPrice" rules={[{ required: true, message: '请输入谷电量' }]}>
          <InputNumber
            precision={4} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={9999.9999}
            style={{ width: '100%' }}
            addonAfter={'元/kWh'}
          />
        </Form.Item>
        <Form.Item label="节能率" name="energySavingRate" rules={[{ required: true, message: '请输入节能率' }]}>
          <InputNumber
            precision={2} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={100.0}
            style={{ width: '100%' }}
            addonAfter={'%'}
          />
        </Form.Item>
        <Form.Item
          label="甲方节能效益占比"
          name="benefitRatioA"
          dependencies={['benefitRatioB']} // 监听 benefitRatioB 的值
          rules={[{ required: true, message: '请输入甲方节能效益占比' }, { validator: validateSum }]}
        >
          <InputNumber
            precision={2} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={100.0}
            style={{ width: '100%' }}
            addonAfter={'%'}
          />
        </Form.Item>
        <Form.Item
          label="乙方节能效益占比"
          name="benefitRatioB"
          dependencies={['benefitRatioA']} // 监听 benefitRatioA 的值
          rules={[{ required: true, message: '请输入乙方节能效益占比' }, { validator: validateSum }]}
        >
          <InputNumber
            precision={2} // 4位小数
            step={1} // 每次递增/递减 1
            min={0}
            max={100.0}
            style={{ width: '100%' }}
            addonAfter={'%'}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BatchEditModal;
