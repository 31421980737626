import React, { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Button,
  Breadcrumb,
  useBreadcrumbRoutes,
  Modal,
  message,
} from '@maxtropy/components';
import BorderWrapper from '@/components/BorderWrapper';
import ShowInput from '@/components/ShowInput';
import Uet from './Uet';
import EnergyListItem from './EnergyListItem';

import BsaSteps from '../BsaSteps';
import BsaPageHeader from '../BsaPageHeader';
import DividerContent from '../DividerContent';

import { BsaComponentsProps, getNext } from '../../factory';

import {
  Bsa,
  BsaDraft,
  DeviceProps,
  getBsa,
  getDraft,
  getUetByBsa,
  multiPcsHardWare,
  saveDraft,
  UETResponse,
  updateBsa,
  UpdateBsaRequest,
} from '../../../../api/bsa';
import { useParams } from 'react-router-dom';
import { BsaType } from '@/api/constans';
import { BSAStatus, bsaStatusFormat } from '@/api/type';
import HealthFormItem, { LayerNumberPropsType } from './HealthFormItem';
import AddressCascader from '@/pages/PvStation/AddressCascader';
import dayjs from 'dayjs';

const formLayout = {
  labelCol: { flex: '140px' },
  wrapperCol: { flex: '340px' },
};

// 设备类型
export enum DeviceType {
  AIR = 5014, // 空调设备
  FIRE = 5015, // 消防设备
  TEMPERATURE = 5027, // 温湿度计
  SMOKE = 5030, // 烟感
}

// 储能柜健康参数设定类型
export enum HealthParamsType {
  HASAMBIENTTEMPERATURECONFIG = 1, // 环境温度
  HASMONOMERTEMPERATURECONFIG = 2, // 单体电芯温度
  HASMONOMERVOLTAGECONFIG = 3, // 单体电压
  HASVOLTAGEUNIFORMCONFIG = 4, // 电压一致性
  HASTEMPERATUREUNIFORMCONFIG = 5, // 温度一致性
}

export enum PCSType {
  SIMPLE = 1,
  MORE = 2,
}

const fomatePCStype = {
  [PCSType.SIMPLE]: '单pcs',
  [PCSType.MORE]: '多pcs',
};
export const HealthParamsTypeProps = {
  [HealthParamsType.HASAMBIENTTEMPERATURECONFIG]: '环境温度', // 环境温度
  [HealthParamsType.HASMONOMERTEMPERATURECONFIG]: '单体电芯温度', // 单体电芯温度
  [HealthParamsType.HASMONOMERVOLTAGECONFIG]: '单体电压', // 单体电压
  [HealthParamsType.HASVOLTAGEUNIFORMCONFIG]: '电压一致性', // 电压一致性
  [HealthParamsType.HASTEMPERATUREUNIFORMCONFIG]: '温度一致性', // 温度一致性
};

export interface DeviceContextData {
  ouId?: number;
  status?: BSAStatus;
}

export interface ChildDataRef {
  deviceData: DeviceProps[];
}

// 表单收集的数据结构（为了提交数据的时候做变化）
export interface FormParams extends Omit<UpdateBsaRequest, 'id'> {
  selectHealth: HealthParamsType[];
}

const FORMATTIME = 'YYYY-MM-DD';

export const DeviceContext = createContext<DeviceContextData>({});
const BsaBaseInfo: FC<BsaComponentsProps> = ({
  disabled = false,
  typeList,
  foucsType,
  currentType,
  updateFn,
  status,
  changeType,
}) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const routesContext = useBreadcrumbRoutes();

  const routes = [{ name: `储能阵列基础信息` }];

  const isComplete = useMemo(() => {
    return (typeList ?? []).includes(BsaType.ARRAY_INFO_INIT);
  }, [typeList]);
  const [uet, setUet] = useState<UETResponse>();

  const exportsInfo = useRef<{ lngLats: string; adcode: string }>({ lngLats: '', adcode: '' });

  const getLngLat = (lngLats: string, adcode: string) => {
    exportsInfo.current = {
      lngLats,
      adcode,
    };
  };

  // 模拟数据

  useEffect(() => {
    if (id) {
      getUetByBsa(id).then(setUet);
    }
  }, [id]);

  useEffect(() => {
    if (uet) {
      form.setFieldsValue({
        fcsName: uet.fcsName,
      });
    }
  }, [uet, form]);

  const [data, setData] = useState<Bsa>();
  const [draft, setDraft] = useState<BsaDraft>();
  const [draftDeviceListShown, setDraftDeviceListShown] = useState(false);

  useEffect(() => {
    if (id) {
      getBsa(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (id && !isComplete) {
      getDraft(id).then(setDraft);
    }
  }, [id, isComplete]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        customerName: data.customerName,
        ouName: data.ouName,
        serialNumber: data.serialNumber,
        status: bsaStatusFormat[data.status],
        createStationTime: data?.createStationTime && dayjs(data?.createStationTime),
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (isComplete) {
      if (data) {
        form.setFieldsValue({
          name: data.name,
          rackCountInStack: data.rackCountInStack,
          hasRack: data.hasRack,
          packCountInRack: data.packCountInRack,
          cellCountInPack: data.cellCountInPack,
          hasPack: data.hasPack,
          hasCell: data.hasCell,
          eol: data.eol,
          multiplyingPowerUpperLimit: data.multiplyingPowerUpperLimit,
          cycles: data.cycles,
          hardwareVersion: data.hardwareVersion,
          hardwareName: data.hardwareName,
          softwareName: data.softwareName,

          pcsStruct: data.pcsStruct,
          fcsSoftwareList: data.fcsSoftwareList,
          fcsSoftware: data.fcsSoftware,
          location: data.location ? JSON.parse(data.location) : undefined,
          address: data.address,
          createStationTime: data?.createStationTime && dayjs(data?.createStationTime),

          // hasAmbientTemperatureConfig: data.hasAmbientTemperatureConfig,
          ambientTemperatureLayerNumber: data.ambientTemperatureLayerNumber,
          ambientTemperatureUpperOne: data.ambientTemperatureUpperOne,
          ambientTemperatureUpperTwo: data.ambientTemperatureUpperTwo,
          ambientTemperatureLowerOne: data.ambientTemperatureLowerOne,
          ambientTemperatureLowerTwo: data.ambientTemperatureLowerTwo,
          // hasMonomerTemperatureConfig: data.hasMonomerTemperatureConfig,
          monomerTemperatureLayerNumber: data.monomerTemperatureLayerNumber,
          monomerTemperatureUpperOne: data.monomerTemperatureUpperOne,
          monomerTemperatureUpperTwo: data.monomerTemperatureUpperTwo,
          monomerTemperatureLowerOne: data.monomerTemperatureLowerOne,
          monomerTemperatureLowerTwo: data.monomerTemperatureLowerTwo,
          // hasMonomerVoltageConfig: data.hasMonomerVoltageConfig,
          monomerVoltageLayerNumber: data.monomerVoltageLayerNumber,
          monomerVoltageUpperOne: data.monomerVoltageUpperOne,
          monomerVoltageUpperTwo: data.monomerVoltageUpperTwo,
          monomerVoltageLowerOne: data.monomerVoltageLowerOne,
          monomerVoltageLowerTwo: data.monomerVoltageLowerTwo,
          // hasVoltageUniformConfig: data.hasVoltageUniformConfig,
          voltageUniformDeviation: data.voltageUniformDeviation,
          chargeVoltageUpper: data.chargeVoltageUpper,
          // hasTemperatureUniformConfig: data.hasTemperatureUniformConfig,
          temperatureUniformDeviation: data.temperatureUniformDeviation,
        });
        exportsInfo.current = {
          lngLats: data.longitude && data.latitude ? data.longitude + ',' + data.latitude : '',
          adcode: data.adcode ?? '',
        };
      }
    } else {
      if (data && draft) {
        const content: UpdateBsaRequest = draft.content ? JSON.parse(draft.content) : undefined;
        form.setFieldsValue({
          name: content?.name ?? data.name,
          rackCountInStack: content?.rackCountInStack ?? data.rackCountInStack,
          hasRack: content?.hasRack ?? data.hasRack,
          packCountInRack: content?.packCountInRack ?? data.packCountInRack,
          cellCountInPack: content?.cellCountInPack ?? data.cellCountInPack,
          hasPack: content?.hasPack ?? data.hasPack,
          hasCell: content?.hasCell ?? data.hasCell,
          hardwareVersion: content?.hardwareVersion ?? data.hardwareVersion,
          softwareName: content?.softwareName ?? data.softwareName,
          hardwareName: content?.hardwareName ?? data.hardwareName,
          pcsStruct: content?.pcsStruct ?? data.pcsStruct,
          fcsSoftwareList: content?.fcsSoftwareList ?? data.fcsSoftwareList,
          fcsSoftware: content?.fcsSoftware ?? data.fcsSoftware,
          eol: content?.eol ?? data.eol,
          multiplyingPowerUpperLimit: content?.multiplyingPowerUpperLimit ?? data.multiplyingPowerUpperLimit,
          cycles: content?.cycles ?? data.cycles,
          location: content?.location ?? (data.location ? JSON.parse(data.location) : undefined),
          address: content?.address ?? data.address,
          createStationTime:
            (content?.createStationTime && dayjs(content?.createStationTime)) ??
            (data?.createStationTime && dayjs(data?.createStationTime)),

          ambientTemperatureLayerNumber: content?.ambientTemperatureLayerNumber ?? data.ambientTemperatureLayerNumber,
          ambientTemperatureUpperOne: content?.ambientTemperatureUpperOne ?? data.ambientTemperatureUpperOne,
          ambientTemperatureUpperTwo: content?.ambientTemperatureUpperTwo ?? data.ambientTemperatureUpperTwo,
          ambientTemperatureLowerOne: content?.ambientTemperatureLowerOne ?? data.ambientTemperatureLowerOne,
          ambientTemperatureLowerTwo: content?.ambientTemperatureLowerTwo ?? data.ambientTemperatureLowerTwo,

          // hasMonomerTemperatureConfig: data.hasMonomerTemperatureConfig,
          monomerTemperatureLayerNumber: content?.monomerTemperatureLayerNumber ?? data.monomerTemperatureLayerNumber,
          monomerTemperatureUpperOne: content?.monomerTemperatureUpperOne ?? data.monomerTemperatureUpperOne,
          monomerTemperatureUpperTwo: content?.monomerTemperatureUpperTwo ?? data.monomerTemperatureUpperTwo,
          monomerTemperatureLowerOne: content?.monomerTemperatureLowerOne ?? data.monomerTemperatureLowerOne,
          monomerTemperatureLowerTwo: content?.monomerTemperatureLowerTwo ?? data.monomerTemperatureLowerTwo,

          // hasMonomerVoltageConfig: data.hasMonomerVoltageConfig,
          monomerVoltageLayerNumber: content?.monomerVoltageLayerNumber ?? data.monomerVoltageLayerNumber,
          monomerVoltageUpperOne: content?.monomerVoltageUpperOne ?? data.monomerVoltageUpperOne,
          monomerVoltageUpperTwo: content?.monomerVoltageUpperTwo ?? data.monomerVoltageUpperTwo,
          monomerVoltageLowerOne: content?.monomerVoltageLowerOne ?? data.monomerVoltageLowerOne,
          monomerVoltageLowerTwo: content?.monomerVoltageLowerTwo ?? data.monomerVoltageLowerTwo,

          // hasVoltageUniformConfig: data.hasVoltageUniformConfig,
          voltageUniformDeviation: content?.voltageUniformDeviation ?? data.voltageUniformDeviation,
          chargeVoltageUpper: content?.chargeVoltageUpper ?? data.chargeVoltageUpper,
          // hasTemperatureUniformConfig: data.hasTemperatureUniformConfig,
          temperatureUniformDeviation: content?.temperatureUniformDeviation ?? data.temperatureUniformDeviation,
        });

        exportsInfo.current = {
          lngLats:
            content?.longitude && content?.latitude
              ? content?.longitude + ',' + content?.latitude
              : data.longitude && data.latitude
              ? data.longitude + ',' + data.latitude
              : '',
          adcode: content?.adcode ?? data.adcode ?? '',
        };
      }
    }
  }, [data, draft, isComplete, form]);

  // 储能柜健康参数回显
  useEffect(() => {
    if (isComplete) {
      if (data) {
        let selectHealth = [];
        data.hasAmbientTemperatureConfig && selectHealth.push(HealthParamsType.HASAMBIENTTEMPERATURECONFIG);
        data.hasTemperatureUniformConfig && selectHealth.push(HealthParamsType.HASTEMPERATUREUNIFORMCONFIG);
        data.hasMonomerTemperatureConfig && selectHealth.push(HealthParamsType.HASMONOMERTEMPERATURECONFIG);
        data.hasMonomerVoltageConfig && selectHealth.push(HealthParamsType.HASMONOMERVOLTAGECONFIG);
        data.hasVoltageUniformConfig && selectHealth.push(HealthParamsType.HASVOLTAGEUNIFORMCONFIG);
        form.setFieldsValue({
          selectHealth,
        });
        setHealthParamsSelect(selectHealth);
      }
    } else {
      if (data && draft) {
        const content: UpdateBsaRequest = draft.content ? JSON.parse(draft.content) : undefined;
        let selectHealth = [];
        let isAmbientTemperatureConfig = content?.hasAmbientTemperatureConfig ?? data.hasAmbientTemperatureConfig ?? [];
        let isTemperatureUniformConfig = content?.hasTemperatureUniformConfig ?? data.hasTemperatureUniformConfig ?? [];
        let isMonomerTemperatureConfig = content?.hasMonomerTemperatureConfig ?? data.hasMonomerTemperatureConfig ?? [];
        let isMonomerVoltageConfig = content?.hasMonomerVoltageConfig ?? data.hasMonomerVoltageConfig ?? [];
        let isVoltageUniformConfig = content?.hasVoltageUniformConfig ?? data.hasVoltageUniformConfig ?? [];
        isAmbientTemperatureConfig && selectHealth.push(HealthParamsType.HASAMBIENTTEMPERATURECONFIG);
        isTemperatureUniformConfig && selectHealth.push(HealthParamsType.HASTEMPERATUREUNIFORMCONFIG);
        isMonomerTemperatureConfig && selectHealth.push(HealthParamsType.HASMONOMERTEMPERATURECONFIG);
        isMonomerVoltageConfig && selectHealth.push(HealthParamsType.HASMONOMERVOLTAGECONFIG);
        isVoltageUniformConfig && selectHealth.push(HealthParamsType.HASVOLTAGEUNIFORMCONFIG);
        form.setFieldsValue({
          selectHealth,
        });
        setHealthParamsSelect(selectHealth);
      }
    }
  }, [data, form, isComplete, draft]);

  const onNext = () => {
    const type = getNext(foucsType!, typeList ?? [], currentType);
    if (type) {
      changeType?.(type);
    }
  };
  const [currentSelect, setCurrentSelect] = useState<any[]>([]);
  const [healthParamsSelect, setHealthParamsSelect] = useState<HealthParamsType[]>([]);
  const airRef = useRef<ChildDataRef>(null);
  const fireRef = useRef<ChildDataRef>(null);
  const temperatureRef = useRef<ChildDataRef>(null);
  const smokeRef = useRef<ChildDataRef>(null);
  const onCheckBoxChange = useCallback((value: any[]) => {
    setCurrentSelect(value);
  }, []);

  // 储能柜健康参数改变引起下面动态表单的变化
  const onCheckBoxHealthChange = (value: HealthParamsType[] | any[]) => {
    setHealthParamsSelect(value as HealthParamsType[]);
  };

  // 获取子组件中的值
  const getChildValuesForDevice = () => {
    return {
      airConditionerList: airRef?.current?.deviceData ?? [],
      fireEquipmentList: fireRef?.current?.deviceData ?? [],
      temperatureAndHumidityMeterList: temperatureRef?.current?.deviceData ?? [],
      smokeDetectorList: smokeRef?.current?.deviceData ?? [],
    };
  };
  // 储能配套设备校验
  const selectDiviceValid = (): boolean => {
    let isPassAir = false;
    let isPassFire = false;
    let isPassTemp = false;
    let isPassSmoke = false;
    if (currentSelect.length === 0) {
      return true;
    }
    let {
      airConditionerList: airList,
      fireEquipmentList: fireList,
      temperatureAndHumidityMeterList: temperatureList,
      smokeDetectorList,
    } = getChildValuesForDevice();

    if (currentSelect.includes(DeviceType.AIR)) {
      if (airList.length > 0) {
        isPassAir = true;
      } else {
        message.warning('请添加已选择的储能配套设备');
        return false;
      }
    }

    if (currentSelect.includes(DeviceType.FIRE)) {
      if (fireList.length > 0) {
        isPassFire = true;
      } else {
        message.warning('请添加已选择的储能配套设备');
        return false;
      }
    }

    if (currentSelect.includes(DeviceType.TEMPERATURE)) {
      if (temperatureList.length > 0) {
        isPassTemp = true;
      } else {
        message.warning('请添加已选择的储能配套设备');
        return false;
      }
    }

    if (currentSelect.includes(DeviceType.SMOKE)) {
      if (smokeDetectorList.length > 0) {
        isPassSmoke = true;
      } else {
        message.warning('请添加已选择的储能配套设备');
        return false;
      }
    }

    if (isPassAir || isPassFire || isPassTemp || isPassSmoke) {
      return true;
    }
    return true;
  };

  // 环境温度，单体温度，单体电压等检验
  const inputTemperatureValid = (): boolean => {
    if (healthParamsSelect.length === 0) {
      return true;
    }
    // 环境温度
    if (healthParamsSelect.includes(HealthParamsType.HASAMBIENTTEMPERATURECONFIG)) {
      if (form.getFieldValue('ambientTemperatureLayerNumber') === LayerNumberPropsType.Two) {
        if (form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureLowerOne')) {
          message.warning('环境温度下限1必须低于上限1');
          return false;
        }
      }
      if (form.getFieldValue('ambientTemperatureLayerNumber') === LayerNumberPropsType.Four) {
        if (
          form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureLowerOne') ||
          form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureLowerTwo') ||
          form.getFieldValue('ambientTemperatureUpperTwo') <= form.getFieldValue('ambientTemperatureLowerOne') ||
          form.getFieldValue('ambientTemperatureUpperTwo') <= form.getFieldValue('ambientTemperatureLowerTwo')
        ) {
          message.warning('环境温度下限必须低于上限');
          return false;
        }
        if (form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureUpperTwo')) {
          message.warning('环境温度上限1必须大于上限2');
          return false;
        }
        if (form.getFieldValue('ambientTemperatureLowerOne') >= form.getFieldValue('ambientTemperatureLowerTwo')) {
          message.warning('环境温度下限1必须小于下限2');
          return false;
        }
      }
    }

    // 单体温度
    if (healthParamsSelect.includes(HealthParamsType.HASMONOMERTEMPERATURECONFIG)) {
      if (form.getFieldValue('monomerTemperatureLayerNumber') === LayerNumberPropsType.Two) {
        if (form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureLowerOne')) {
          message.warning('单体温度下限1必须低于上限1');
          return false;
        }
      }
      if (form.getFieldValue('monomerTemperatureLayerNumber') === LayerNumberPropsType.Four) {
        if (form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureUpperTwo')) {
          message.warning('单体温度上限1必须大于上限2');
          return false;
        }
        if (form.getFieldValue('monomerTemperatureLowerOne') >= form.getFieldValue('monomerTemperatureLowerTwo')) {
          message.warning('单体温度下限1必须小于下限2');
          return false;
        }
        if (
          form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureLowerOne') ||
          form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureLowerTwo') ||
          form.getFieldValue('monomerTemperatureUpperTwo') <= form.getFieldValue('monomerTemperatureLowerOne') ||
          form.getFieldValue('monomerTemperatureUpperTwo') <= form.getFieldValue('monomerTemperatureLowerTwo')
        ) {
          message.warning('单体温度下限必须低于上限');
          return false;
        }
      }
    }

    // 单体电压
    if (healthParamsSelect.includes(HealthParamsType.HASMONOMERVOLTAGECONFIG)) {
      if (form.getFieldValue('monomerVoltageLayerNumber') === LayerNumberPropsType.Two) {
        if (form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageLowerOne')) {
          message.warning('单体电压下限1必须低于上限1');
          return false;
        }
      }
      if (form.getFieldValue('monomerVoltageLayerNumber') === LayerNumberPropsType.Four) {
        if (form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageUpperTwo')) {
          message.warning('单体电压上限1必须大于上限2');
          return false;
        }

        if (form.getFieldValue('monomerVoltageLowerOne') >= form.getFieldValue('monomerVoltageLowerTwo')) {
          message.warning('单体电压下限1必须小于下限2');
          return false;
        }
        if (
          form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageLowerOne') ||
          form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageLowerTwo') ||
          form.getFieldValue('monomerVoltageUpperTwo') <= form.getFieldValue('monomerVoltageLowerOne') ||
          form.getFieldValue('monomerVoltageUpperTwo') <= form.getFieldValue('monomerVoltageLowerTwo')
        ) {
          message.warning('单体电压下限必须低于上限');
          return false;
        }
      }
    }

    return true;
  };

  // 储能配套设备数据回显
  const returnDeviceData = useCallback(
    (
      airList: DeviceProps[],
      fireList: DeviceProps[],
      temperatureAndHumidityMeterList: DeviceProps[],
      smokeDetectorList: DeviceProps[]
    ) => {
      let tempDevice: number[] = [];

      if (fireList.length > 0) {
        tempDevice.push(DeviceType.FIRE);
      }
      if (airList.length > 0) {
        tempDevice.push(DeviceType.AIR);
      }
      if (temperatureAndHumidityMeterList.length > 0) {
        tempDevice.push(DeviceType.TEMPERATURE);
      }
      if (smokeDetectorList.length > 0) {
        tempDevice.push(DeviceType.SMOKE);
      }

      form.setFieldsValue({
        device: tempDevice,
      });
      setCurrentSelect(tempDevice);
    },
    [form]
  );
  useEffect(() => {
    if (isComplete) {
      if (data) {
        let airList = data.airConditionerList ?? [];
        let fireList = data.fireEquipmentList ?? [];
        let temperatureList = data.temperatureAndHumidityMeterList ?? [];
        let smokeList = data.smokeDetectorList ?? [];
        returnDeviceData(airList, fireList, temperatureList, smokeList);
      }
    } else {
      if (data && draft) {
        const content: UpdateBsaRequest = draft.content ? JSON.parse(draft.content) : undefined;
        let airList = content?.airConditionerList ?? data.airConditionerList ?? [];
        let fireList = content?.fireEquipmentList ?? data.fireEquipmentList ?? [];
        let temperatureList = content?.temperatureAndHumidityMeterList ?? data.temperatureAndHumidityMeterList ?? [];
        let smokeList = content?.smokeDetectorList ?? data.smokeDetectorList ?? [];
        returnDeviceData(airList, fireList, temperatureList, smokeList);

        // 加一个draftDeviceListShown变量，防止改data之后重复触发effect
        if (!draftDeviceListShown) {
          setData({
            ...data,
            airConditionerList: airList,
            fireEquipmentList: fireList,
            temperatureAndHumidityMeterList: temperatureList,
            smokeDetectorList: smokeList,
          });
          setDraftDeviceListShown(true);
        }
      }
    }
  }, [data, form, isComplete, draft, returnDeviceData, draftDeviceListShown]);

  const formatFormValues = (val: FormParams): Omit<UpdateBsaRequest, 'id'> => {
    let params = { ...val };
    if (params.selectHealth && params.selectHealth.length !== 0) {
      params.selectHealth.includes(HealthParamsType.HASAMBIENTTEMPERATURECONFIG) &&
        (params.hasAmbientTemperatureConfig = true);
      params.selectHealth.includes(HealthParamsType.HASMONOMERTEMPERATURECONFIG) &&
        (params.hasMonomerTemperatureConfig = true);
      params.selectHealth.includes(HealthParamsType.HASMONOMERVOLTAGECONFIG) && (params.hasMonomerVoltageConfig = true);
      params.selectHealth.includes(HealthParamsType.HASTEMPERATUREUNIFORMCONFIG) &&
        (params.hasTemperatureUniformConfig = true);
      params.selectHealth.includes(HealthParamsType.HASVOLTAGEUNIFORMCONFIG) && (params.hasVoltageUniformConfig = true);
    }
    return params;
  };

  const onFinish = () => {
    if (id) {
      let isPassTemperature = inputTemperatureValid();
      let isPassDevice = selectDiviceValid();
      if (disabled && isPassTemperature && isPassDevice) {
        onNext();
      } else {
        if (!isPassDevice || !isPassTemperature) return;
        form.validateFields().then((res: FormParams) => {
          if (isPassTemperature) {
            // 对提交的数据进行处理
            let newRes = formatFormValues(res);

            updateBsa({
              id: Number(id),
              ...newRes,
              location: JSON.stringify(newRes.location),
              adcode: exportsInfo.current.adcode,
              createStationTime: dayjs(newRes.createStationTime).format(FORMATTIME),
              longitude: exportsInfo.current.lngLats ? Number(exportsInfo.current.lngLats.split(',')[0]) : null,
              latitude: exportsInfo.current.lngLats ? Number(exportsInfo.current.lngLats.split(',')[1]) : null,
              ...getChildValuesForDevice(),
            }).then(() => {
              if (isComplete) {
                updateFn?.(true, getNext(foucsType!, typeList ?? [], currentType));
              } else {
                updateFn?.();
              }
            });
          }
        });
      }
    }
  };

  const onSaveDraft = () => {
    const value: Partial<UpdateBsaRequest> = form.getFieldsValue();
    saveDraft({
      bsaId: Number(id),
      content: JSON.stringify({
        ...value,
        adcode: exportsInfo.current.adcode,
        hasAmbientTemperatureConfig: value.selectHealth?.includes(HealthParamsType.HASAMBIENTTEMPERATURECONFIG),
        hasMonomerTemperatureConfig: value.selectHealth?.includes(HealthParamsType.HASMONOMERTEMPERATURECONFIG),
        hasMonomerVoltageConfig: value.selectHealth?.includes(HealthParamsType.HASMONOMERVOLTAGECONFIG),
        hasVoltageUniformConfig: value.selectHealth?.includes(HealthParamsType.HASVOLTAGEUNIFORMCONFIG),
        hasTemperatureUniformConfig: value.selectHealth?.includes(HealthParamsType.HASTEMPERATUREUNIFORMCONFIG),
        longitude: exportsInfo.current.lngLats ? Number(exportsInfo.current.lngLats.split(',')[0]) : null,
        latitude: exportsInfo.current.lngLats ? Number(exportsInfo.current.lngLats.split(',')[1]) : null,
        ...getChildValuesForDevice(),
      }),
    }).then(() => {
      Modal.success({ content: '保存成功' });
    });
  };

  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content">
            <BsaPageHeader
              title="储能阵列表单"
              extraContent={
                <>
                  <Button type="primary" onClick={onFinish}>
                    下一步
                  </Button>
                  {!isComplete && status !== BSAStatus.INVALID && (
                    <Button type="primary" onClick={onSaveDraft}>
                      存草稿
                    </Button>
                  )}
                </>
              }
            >
              <BsaSteps typeList={typeList} foucsType={foucsType} />
            </BsaPageHeader>
            <Form labelAlign="left" labelWrap form={form} {...formLayout}>
              <DividerContent title="基础资料">
                <Form.Item
                  label="阵列名称"
                  name="name"
                  rules={[
                    { required: true, message: '请输入' },
                    { max: 10, message: '最多10个字' },
                    {
                      pattern: /^[A-Za-z0-9\u4e00-\u9fa5-]+$/g,
                      message: '只支持汉字、字母、数字、-',
                    },
                  ]}
                >
                  <Input placeholder="请输入" disabled={disabled} />
                </Form.Item>
                <AddressCascader form={form} disabled={disabled} getLngLat={getLngLat} />
                <Form.Item label="组织名称" name="customerName">
                  <ShowInput />
                </Form.Item>
                <Form.Item label="运营单元" name="ouName">
                  <ShowInput />
                </Form.Item>
                <Form.Item label="阵列结构类型" noStyle dependencies={['pcsStruct']}>
                  {({ getFieldValue }) => {
                    let value = getFieldValue('pcsStruct') as PCSType;
                    let label = value ? fomatePCStype[value] : '--';
                    return (
                      <Form.Item label="阵列结构类型">
                        <ShowInput value={label} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    let hardwareVersion = getFieldValue('hardwareVersion');
                    let hardwareName = getFieldValue('hardwareName');
                    return (
                      <Form.Item label="硬件版本">
                        <ShowInput value={`${hardwareVersion ?? ''}-${hardwareName ?? ''}`} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item label="阵列编号" name="serialNumber">
                  <ShowInput />
                </Form.Item>
                <Form.Item label="状态" name="status">
                  <ShowInput />
                </Form.Item>
              </DividerContent>
              <DividerContent title="上游阵列关系">
                <Uet value={uet} />
              </DividerContent>
              <DividerContent title="储能配套设备">
                <Form.Item label="请选择储能配套设备" name="device">
                  <Checkbox.Group onChange={onCheckBoxChange} disabled={disabled}>
                    <Checkbox value={DeviceType.AIR}>空调</Checkbox>
                    <Checkbox value={DeviceType.FIRE}>消防</Checkbox>
                    <Checkbox value={DeviceType.TEMPERATURE}>温湿度计</Checkbox>
                    <Checkbox value={DeviceType.SMOKE}>烟感</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <DeviceContext.Provider value={{ ouId: data?.ouId, status: data?.status }}>
                  <EnergyListItem
                    disabled={disabled}
                    ref={airRef}
                    show={currentSelect.includes(DeviceType.AIR)}
                    deviceType={DeviceType.AIR}
                    colLabelFirst="空调名称"
                    colLabelSecond="空调设备编号"
                    list={data?.airConditionerList}
                  />
                  <EnergyListItem
                    ref={fireRef}
                    show={currentSelect.includes(DeviceType.FIRE)}
                    deviceType={DeviceType.FIRE}
                    colLabelFirst="消防设备名称"
                    colLabelSecond="消防设备编号"
                    list={data?.fireEquipmentList}
                  />
                  <EnergyListItem
                    disabled={disabled}
                    ref={temperatureRef}
                    show={currentSelect.includes(DeviceType.TEMPERATURE)}
                    deviceType={DeviceType.TEMPERATURE}
                    colLabelFirst="温湿度计名称"
                    colLabelSecond="温湿度计设备编号"
                    list={data?.temperatureAndHumidityMeterList}
                  />
                  <EnergyListItem
                    disabled={disabled}
                    ref={smokeRef}
                    show={currentSelect.includes(DeviceType.SMOKE)}
                    deviceType={DeviceType.SMOKE}
                    colLabelFirst="烟感设备名称"
                    colLabelSecond="烟感设备编号"
                    list={data?.smokeDetectorList}
                  />
                </DeviceContext.Provider>
              </DividerContent>
              <DividerContent title="电池整体阵列结构">
                <Form.Item noStyle dependencies={['hardwareVersion']}>
                  {({ getFieldValue }) => {
                    let hardware = getFieldValue('hardwareVersion');
                    let isMulit = multiPcsHardWare.includes(hardware);
                    return (
                      <Form.Item label={isMulit ? '主控FCS' : '关联FCS'} name="fcsName">
                        <ShowInput />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item noStyle dependencies={['fcsSoftware']}>
                  {({ getFieldValue }) => {
                    let fcsSoftware = getFieldValue('fcsSoftware');
                    let softwareName = getFieldValue('softwareName');
                    return (
                      <Form.Item label="FCS软件版本">
                        <ShowInput value={`${fcsSoftware ?? ''}-${softwareName ?? '-'}`} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  label="储能柜个数"
                  name="PcsCount"
                  rules={[{ required: true, message: '请输入' }]}
                  initialValue={1}
                >
                  <InputNumber disabled />
                </Form.Item>
                <Form.Item
                  label="柜内阵列整体设定-PCS"
                  name="hasPcs"
                  rules={[{ required: true, message: '请选择' }]}
                  initialValue={true}
                >
                  <Radio.Group buttonStyle="solid" disabled>
                    <Radio.Button value={true}>阵列内包含PCS</Radio.Button>
                    <Radio.Button value={false}>阵列内不包含PCS</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="电池堆阵列设定"
                  name="hasStack"
                  rules={[{ required: true, message: '请选择' }]}
                  initialValue={true}
                >
                  <Radio.Group buttonStyle="solid" disabled>
                    <Radio.Button value={true}>阵列内包含电池堆</Radio.Button>
                    <Radio.Button value={false}>阵列内不包含电池堆</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item noStyle dependencies={['hasStack']}>
                  {({ getFieldValue }) => {
                    let hasStack = getFieldValue('hasStack');
                    if (!hasStack) return <></>;
                    return (
                      <Form.Item label="电池簇阵列设定" name="hasRack" rules={[{ required: true, message: '请选择' }]}>
                        <Radio.Group
                          buttonStyle="solid"
                          disabled={
                            disabled || (typeList ?? []).includes(BsaType.RACK_DEVICE_CREATED) || currentType === null
                          }
                        >
                          <Radio.Button value={true}>阵列内包含电池簇</Radio.Button>
                          <Radio.Button value={false}>阵列内不包含电池簇</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                <Form.Item noStyle dependencies={['hasStack', 'hasRack']}>
                  {({ getFieldValue }) =>
                    getFieldValue('hasRack') &&
                    getFieldValue('hasStack') && (
                      <>
                        <Form.Item
                          label="堆内最大簇数"
                          name="rackCountInStack"
                          rules={[{ required: true, message: '请输入' }]}
                        >
                          <InputNumber
                            placeholder="请输入"
                            min={1}
                            max={99}
                            precision={0}
                            disabled={
                              disabled || (typeList ?? []).includes(BsaType.RACK_DEVICE_CREATED) || currentType === null
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="电池组阵列设定"
                          name="hasPack"
                          rules={[{ required: true, message: '请选择' }]}
                        >
                          <Radio.Group
                            buttonStyle="solid"
                            disabled={
                              disabled || (typeList ?? []).includes(BsaType.PACK_DEVICE_CREATED) || currentType === null
                            }
                          >
                            <Radio.Button value={true}>阵列内包含电池组</Radio.Button>
                            <Radio.Button value={false}>阵列内不包含电池组</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>
                <Form.Item noStyle dependencies={['hasStack', 'hasRack', 'hasPack']}>
                  {({ getFieldValue }) =>
                    getFieldValue('hasStack') &&
                    getFieldValue('hasRack') &&
                    getFieldValue('hasPack') && (
                      <Form.Item
                        label="簇内电池组数"
                        name="packCountInRack"
                        rules={[{ required: true, message: '请输入' }]}
                      >
                        <InputNumber
                          placeholder="请输入"
                          min={1}
                          max={99}
                          precision={0}
                          disabled={
                            disabled || (typeList ?? []).includes(BsaType.PACK_DEVICE_CREATED) || currentType === null
                          }
                        />
                      </Form.Item>
                    )
                  }
                </Form.Item>
                <Form.Item noStyle dependencies={['hasStack', 'hasRack', 'hasPack']}>
                  {({ getFieldValue }) =>
                    getFieldValue('hasStack') &&
                    getFieldValue('hasRack') &&
                    getFieldValue('hasPack') && (
                      <>
                        <Form.Item label="电芯阵列设定" name="hasCell" rules={[{ required: true, message: '请选择' }]}>
                          <Radio.Group
                            buttonStyle="solid"
                            disabled={
                              disabled || (typeList ?? []).includes(BsaType.CELL_DEVICE_CREATED) || currentType === null
                            }
                          >
                            <Radio.Button value={true}>阵列内包含电芯</Radio.Button>
                            <Radio.Button value={false}>阵列内不包含电芯</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>
                <Form.Item noStyle dependencies={['hasStack', 'hasRack', 'hasPack', 'hasCell']}>
                  {({ getFieldValue }) =>
                    getFieldValue('hasStack') &&
                    getFieldValue('hasRack') &&
                    getFieldValue('hasPack') &&
                    getFieldValue('hasCell') && (
                      <Form.Item
                        label="组内电芯个数"
                        name="cellCountInPack"
                        rules={[{ required: true, message: '请输入' }]}
                      >
                        <InputNumber
                          placeholder="请输入"
                          min={1}
                          max={99}
                          precision={0}
                          disabled={
                            disabled || (typeList ?? []).includes(BsaType.CELL_DEVICE_CREATED) || currentType === null
                          }
                        />
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </DividerContent>
              <DividerContent title="储能柜额定参数">
                <Form.Item label="EOL" name="eol" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber
                    placeholder="请输入"
                    addonAfter="%"
                    min={0}
                    max={100}
                    precision={2}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  label="倍率上限"
                  name="multiplyingPowerUpperLimit"
                  rules={[{ required: true, message: '请输入' }]}
                >
                  <InputNumber
                    placeholder="请输入"
                    addonAfter="C"
                    min={0}
                    precision={2}
                    max={99.99}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item label="循环次数" name="cycles" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber
                    placeholder="请输入"
                    addonAfter="次"
                    min={1}
                    precision={0}
                    max={99999}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  label="储能柜健康参数设定"
                  name="selectHealth"
                  wrapperCol={{ flex: '540px' }}
                  labelCol={{ flex: '120px' }}
                >
                  <Checkbox.Group onChange={onCheckBoxHealthChange} disabled={disabled}>
                    <Checkbox value={HealthParamsType.HASAMBIENTTEMPERATURECONFIG}>
                      {HealthParamsTypeProps[HealthParamsType.HASAMBIENTTEMPERATURECONFIG]}
                    </Checkbox>
                    <Checkbox value={HealthParamsType.HASMONOMERTEMPERATURECONFIG}>
                      {HealthParamsTypeProps[HealthParamsType.HASMONOMERTEMPERATURECONFIG]}
                    </Checkbox>
                    <Checkbox value={HealthParamsType.HASMONOMERVOLTAGECONFIG}>
                      {HealthParamsTypeProps[HealthParamsType.HASMONOMERVOLTAGECONFIG]}
                    </Checkbox>
                    <Checkbox value={HealthParamsType.HASVOLTAGEUNIFORMCONFIG}>
                      {HealthParamsTypeProps[HealthParamsType.HASVOLTAGEUNIFORMCONFIG]}
                    </Checkbox>
                    <Checkbox value={HealthParamsType.HASTEMPERATUREUNIFORMCONFIG}>
                      {HealthParamsTypeProps[HealthParamsType.HASTEMPERATUREUNIFORMCONFIG]}
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </DividerContent>
              {healthParamsSelect.length !== 0 ? (
                healthParamsSelect.map((item: HealthParamsType, index) => (
                  <DividerContent key={index} title={HealthParamsTypeProps[item] + '参数'}>
                    <HealthFormItem itemHealthSelect={item} disabled={disabled} />
                  </DividerContent>
                ))
              ) : (
                <></>
              )}
            </Form>
          </div>
        </BorderWrapper>
      </div>
    </>
  );
};

export default BsaBaseInfo;
