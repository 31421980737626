import React, { FC, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { Button, Checkbox, DatePicker, EllipsisSpan, Radio, ShowInput, Table, Tooltip } from '@maxtropy/components';
import { Space, Typography, Table as AntdTable, DatePickerProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import styles from './index.module.scss';
import { v4 } from 'uuid';
import { getTableSummary } from '../getTableSummary';
import qs from 'qs';
import { useRequest } from 'ahooks';
import { apiV2ReportListPost } from '@maxtropy/dmes-apis-v2';
import { getDataColumns } from '../dataColumns';
import { ResolutionEnum } from '../..';
import dayjs, { Dayjs } from 'dayjs';
import DateRangePicker from '../DateRangePicker';

export interface IElecStatistics {
  timeRange: [Dayjs, Dayjs];
  setTimeRange: (timeRange: [Dayjs, Dayjs]) => void;
  pvId?: number;
}

export interface ElecStatisticsRef {
  timeResolution: ResolutionEnum;
}

const ElecStatistics: ForwardRefRenderFunction<ElecStatisticsRef, IElecStatistics> = (props, ref) => {
  const { pvId, timeRange, setTimeRange } = props;
  const [timeResolution, setTimeResolution] = useState<ResolutionEnum>(ResolutionEnum.DAY);
  const [monthRange, setMonthRange] = useState<[Dayjs, Dayjs]>([dayjs().subtract(12, 'months'), dayjs()]);

  const [showIncome, setShowIncome] = useState(true);
  const [showTimePeriod, setShowTimePeriod] = useState(true);

  useImperativeHandle(ref, () => ({
    timeResolution,
  }));

  const columns: ColumnsType<any> = getDataColumns(timeResolution, showIncome, showTimePeriod);

  // 请求数据
  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2ReportListPost({
        pvId: pvId,
        resolution: timeResolution,
        from: timeResolution === ResolutionEnum.DAY ? timeRange![0]!.valueOf() : monthRange![0]!.valueOf(),
        to:
          timeResolution === ResolutionEnum.DAY
            ? timeRange![1]!.endOf('day').valueOf()
            : monthRange![1]!.endOf('day').valueOf(),
        showIncome: showIncome,
        showTimePeriod: showTimePeriod,
      });
      return res;
    },
    {
      ready: !!pvId && !!timeRange && !!monthRange,
      refreshDeps: [pvId, timeRange, monthRange, timeResolution, showIncome, showTimePeriod],
    }
  );

  const onExport = () => {
    window.open(
      '/api/v2/report/excel?' +
        qs.stringify(
          {
            pvId: pvId,
            resolution: timeResolution,
            from: timeResolution === ResolutionEnum.DAY ? timeRange![0]!.valueOf() : monthRange![0]!.valueOf(),
            to: timeResolution === ResolutionEnum.DAY ? timeRange![1]!.valueOf() : monthRange![1]!.valueOf(),
            showIncome: showIncome,
            showTimePeriod: showTimePeriod,
          },
          { indices: false }
        )
    );
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <div className={styles.ctrlWrap}>
          <Radio.Group buttonStyle="solid" value={timeResolution} onChange={e => setTimeResolution(e.target.value)}>
            <Radio.Button value={ResolutionEnum.DAY}>按日</Radio.Button>
            <Radio.Button value={ResolutionEnum.MONTH}>按月</Radio.Button>
          </Radio.Group>
          {timeResolution === 'D' ? (
            <DateRangePicker
              type="date"
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              maxRange={31}
            ></DateRangePicker>
          ) : (
            <DateRangePicker
              type="month"
              timeRange={monthRange}
              setTimeRange={setMonthRange}
              maxRange={24}
            ></DateRangePicker>
          )}
          <Checkbox
            checked={showIncome}
            onChange={e => {
              setShowIncome(e.target.checked);
            }}
          >
            显示收益
          </Checkbox>
          <Checkbox
            checked={showTimePeriod}
            onChange={e => {
              setShowTimePeriod(e.target.checked);
            }}
          >
            显示峰谷时段
          </Checkbox>
        </div>
        <Button
          type="primary"
          wrapStyle={{
            marginLeft: 'auto',
          }}
          onClick={onExport}
        >
          导出
        </Button>
      </div>

      <Table
        bordered
        rowKey={() => v4()}
        columns={columns}
        dataSource={data?.pvStationDataList}
        loading={loading}
        scroll={{
          x: 'max-content',
          y: 'calc(100vh - 410px)',
        }}
        summary={() => getTableSummary(data?.total, showIncome, showTimePeriod)}
      />
    </div>
  );
};

export default forwardRef(ElecStatistics);
