import { V2EnergyConservationEnergySavingQuantityPostResponse } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import { keepTwoNull } from '../../utils';

export const getChartOption = (chartData?: V2EnergyConservationEnergySavingQuantityPostResponse) => {
  // 每日节能量
  let dailyEnergySavingsData = (chartData?.list ?? []).map(item => [dayjs(item.ts).valueOf(), keepTwoNull(item.value)]);

  return {
    color: ['#16DD8E'],
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data, marker } = item;
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;">${marker}</span>${seriesName} : &nbsp;${
            data[1] || data[1] === 0 ? data[1] : '-'
          }${'kWh'}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 20,
      right: 10,
      top: 50,
      bottom: 40,
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 10,
        left: 25,
        right: 15,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
        labelFormatter: (value: string) => {
          return dayjs(value).format('YYYY-MM-DD');
        },
      },
    ],
    legend: [
      {
        right: 10,
        itemGap: 18,
        itemWidth: 12,
        itemHeight: 12,
        textStyle: {
          color: '#ffffff85',
        },
        inactiveColor: '#ffffff45',
        data: [
          {
            name: '节电量',
            icon: 'rect',
          },
        ],
      },
    ],
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          formatter: '{MM}-{dd}',
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '节电量',
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: [
      {
        name: '节电量',
        type: 'bar',
        data: dailyEnergySavingsData,
      },
    ],
  };
};
