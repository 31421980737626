import DividerContent from '@/components/DividerContent';
import { App, Popconfirm, Row } from 'antd';
import { Table, usePaging, Paging, EllipsisSpan, useUpdate, Button } from '@maxtropy/components';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AreaModal from './AreaModal/index';
import { forwardRef, useImperativeHandle } from 'react';
import { AreaRefProps, PvInfoContext } from '../index';
import { deletePvArea, getPvAreaList, PvAreaInfo } from '@/api/pv';
import { PVStatus } from '@/api/type';
const columns = [
  {
    title: '片区编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '片区名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '片区地址',
    dataIndex: 'address',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
const Area = forwardRef<AreaRefProps>((props, childRef) => {
  const pagingInfo = usePaging();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [opType, setOpType] = useState('add');
  const currentIndex = useRef<number>(-1); // 当前操作行
  const { pvId, pvStatus, isView } = useContext(PvInfoContext);
  const [listData, setListData] = useState<PvAreaInfo[]>([]);
  const [rand, forceUpdate] = useUpdate();
  const { message } = App.useApp();
  // 草稿状态显示添加/删除片区按钮
  const showRelativeBtn = useMemo(() => pvStatus === PVStatus.DRAFT, [pvStatus]);
  // 删除片区
  const remove = (areaId?: number) => {
    deletePvArea(pvId, areaId).then(res => {
      if (!res.result) {
        return;
      }
      forceUpdate();
    });
  };
  // 编辑/添加片区
  const areaOp = (type: string, index: number) => {
    setVisible(true);
    setOpType(type);
    if (index > -1) {
      currentIndex.current = index;
    }
  };
  useEffect(() => {
    if (!pvId) return;
    setIsLoading(true);
    getPvAreaList(pvId)
      .then(res => {
        setListData(res);
      })
      .finally(() => setIsLoading(false));
  }, [pvId, rand]);
  // 片区保存确认按钮
  const onConfirm = () => {
    setVisible(false);
    forceUpdate();
  };
  const actionColumn = {
    title: '操作',
    width: 220,
    fixed: 'right' as const,
    render: (_: any, row: PvAreaInfo, index: number) => (
      <div className="action-buttons">
        {showRelativeBtn && (
          <Popconfirm title="确认要删除该片区吗?" onConfirm={() => remove(row.id)} okText="确定" cancelText="取消">
            <Button type="link">删除</Button>
          </Popconfirm>
        )}
        <Button type="link" onClick={() => areaOp('edit', index)}>
          编辑
        </Button>
        <CopyToClipboard onCopy={() => message.success('复制成功')} text={row.code!}>
          <Button type="link">复制编号</Button>
        </CopyToClipboard>
      </div>
    ),
  };
  const tableColumns = isView ? columns : [...columns, actionColumn];
  useImperativeHandle(childRef, () => ({
    listData: listData,
  }));
  return (
    <>
      <DividerContent title="片区信息">
        <Row>
          {showRelativeBtn && !isView && (
            <Button type="primary" onClick={() => areaOp('add', -1)}>
              添加片区
            </Button>
          )}
        </Row>
        <div style={{ height: '10px' }}></div>
        <Table
          sticky
          rowKey="code"
          scroll={{ x: 1500 }}
          columns={tableColumns}
          dataSource={listData}
          loading={isLoading}
        />
        <Paging pagingInfo={pagingInfo} />
      </DividerContent>
      <AreaModal
        onCancel={() => setVisible(false)}
        onConfirm={onConfirm}
        listData={listData}
        row={listData[currentIndex.current]}
        opType={opType}
        visible={visible}
      />
    </>
  );
});

export default Area;
