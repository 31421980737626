import { V2EnergyConservationEnergySavingPiePostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoOrLine } from '../../utils';
import circleImg from '../../imgs/circle@2x.png';
import circleBgImg from '../../imgs/circlebg-big@2x.png';

export const getPieChartOption = (chartData?: V2EnergyConservationEnergySavingPiePostResponse) => {
  const finalChartData = [
    {
      name: '尖-节能量',
      value: chartData?.energySavingSharpPower,
    },
    {
      name: '峰-节能量',
      value: chartData?.energySavingPeakPower,
    },
    {
      name: '平-节能量',
      value: chartData?.energySavingFlatPower,
    },
    {
      name: '谷-节能量',
      value: chartData?.energySavingValleyPower,
    },
  ];
  return {
    // color: ['#FFCB47', '#16DD8E', '#2D8DFF', '#556583'],
    legend: {
      left: '50%',
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemGap: 18,
      itemHeight: 7,
      itemWidth: 7,
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        rich: {
          name: {
            fontSize: 14,
            lineHeight: 20,
            width: 100,
          },
          value: {
            fontSize: 14,
            width: 120,
          },
        },
      },
      formatter: (name: string) => {
        const item = finalChartData?.find(item => item.name === name);
        return `{name| ${name}}{value|${keepTwoOrLine(item?.value) + 'kWh'}}`;
      },
    },
    graphic: {
      elements: [
        {
          type: 'image',
          style: {
            image: circleImg,
            x: 106,
            y: 66,
            width: 112,
            height: 112,
          },
        },
        {
          type: 'image',
          style: {
            image: circleBgImg,
            x: 86,
            y: 46,
            width: 152,
            height: 152,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        name: '节能量(kWh)',
        percentPrecision: 5,
        minAngle: 2,
        padAngle: 1,
        radius: [58, 72],
        left: 100,
        top: 60,
        width: 124,
        height: 124,
        label: {
          show: false,
        },
        data: (finalChartData ?? []).map(item => ({
          name: item.name,
          value: keepTwoOrLine(item.value),
        })),
        showEmptyCircle: false,
      },
      {
        showEmptyCircle: false,
        data: (finalChartData ?? [])?.map(item => ({
          name: item.name,
          value: keepTwoOrLine(item.value),
        })),
        type: 'pie',
        percentPrecision: 5,
        padAngle: 1,
        minAngle: 2,
        left: 100,
        top: 60,
        name: '节能量(kWh)',
        radius: [66, 86],
        width: 124,
        height: 124,
        label: {
          show: false,
        },
        itemStyle: {
          opacity: 0.2,
        },
      },
    ],
  };
};
