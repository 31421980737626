import React, { FC, useContext, useEffect } from 'react';
import styles from './index.module.scss';
import { Progress, Spin } from 'antd';
import { SitePerforAnalysisYearContext } from '../..';
import { useRequest } from 'ahooks';
import { apiV2PvPerformanceAnalyseIndexPost } from '@maxtropy/dmes-apis-v2';
import { isYearOrMonth, keepTwoOrLine } from '../../utils';
import { Tooltip } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';

export interface ITopCard {
  getInstalledCapacity?: (value?: number) => void;
}

const TopCard: FC<ITopCard> = props => {
  const { getInstalledCapacity } = props;
  const { pvId, resolution, date } = useContext(SitePerforAnalysisYearContext);

  // 顶部数据
  const { data, loading } = useRequest(
    () => {
      return apiV2PvPerformanceAnalyseIndexPost({
        id: pvId,
        type: resolution,
        time: date?.valueOf(),
      });
    },
    {
      ready: !!pvId && !!date && !!resolution,
      refreshDeps: [pvId, date, resolution],
    }
  );

  useEffect(() => {
    if (!isNil(data?.installedCapacity)) {
      getInstalledCapacity?.(data?.installedCapacity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {loading ? (
        <Spin spinning={true} />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.left_card}>
            <div className={styles.top_left_box}>
              <div className={styles.label}>{isYearOrMonth(resolution)}度发电量 (逆变器)</div>
              <div className={styles.value}>{keepTwoOrLine(data?.inverterPower)}kWh</div>
            </div>
            <div className={styles.top_right_box}>
              <div className={styles.label}>{isYearOrMonth(resolution)}度发电量 (并网点)</div>
              <div className={styles.value}>{keepTwoOrLine(data?.gridConnectedPower)}kWh</div>
            </div>
            <div className={styles.bottom_left_box}>
              <div className={styles.label}>{isYearOrMonth(resolution)}度辐射量</div>
              <div className={styles.value}>{keepTwoOrLine(data?.cumulativeTiltTotalRadiation)}MJ/m²</div>
            </div>
            <div className={styles.bottom_right_box}>
              <div className={styles.label}>{isYearOrMonth(resolution)}度等效利用小时数</div>
              <div className={styles.value_box}>
                <div className={styles.value}>{keepTwoOrLine(data?.equivalentHours)}h</div>
              </div>
            </div>
          </div>
          <div className={styles.right_card}>
            <div className={styles.left_card_box}>
              <div className={styles.label}>{isYearOrMonth(resolution)}度理论发电量</div>
              <div className={styles.value}>{keepTwoOrLine(data?.theoreticalPowerGeneration)}kWh</div>
              <div className={styles.label}>
                系统效率 (PR)
                <Tooltip title="系统效率：（并网点总发电量-站用电电量）/理论发电量">
                  <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: '6px', fontSize: '16px' }} />
                </Tooltip>
              </div>

              <div className={styles.progress_box}>
                <Progress
                  percent={data?.systemEfficiency ? Number((data?.systemEfficiency * 100).toFixed(2)) : 0}
                  strokeColor={{ '0%': '#51DBFF', '100%': '#51A1FF' }}
                  trailColor="#214172"
                  // format={percent => (percent === 100 ? '100%' : `${percent}%`)}
                  format={percent => `${percent}%`}
                />
              </div>
            </div>
            <div className={styles.right_card_box}>
              <div className={styles.label}>{isYearOrMonth(resolution)}度计划发电</div>
              <div className={styles.value}>{keepTwoOrLine(data?.planPowerGeneration)}kWh</div>
              <div className={styles.label}>完成率</div>
              <div className={styles.progress_box}>
                <Progress
                  percent={data?.accomplishmentRatio ? Number((data?.accomplishmentRatio * 100).toFixed(2)) : 0}
                  strokeColor={{ '0%': '#51DBFF', '100%': '#51A1FF' }}
                  trailColor="#214172"
                  format={percent => `${percent}%`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopCard;
